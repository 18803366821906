export const returnError = (message) => {
  if (message) {
    if (message?.toLowerCase()?.includes("uniqueness violation")) {
      return "You are trying to add data which already exist in the system!";
    } else if (message?.toLowerCase()?.includes("not-null violation")) {
      return "You are trying to save or delete item which violates dependency constraint!";
    } else if (message?.toLowerCase()?.includes("foreign key violation")) {
      return "You are trying to save or delete item which violates dependency constraint!";
    } else if (message?.toLowerCase()?.includes("invalid input syntax")) {
      return "You are trying to save data with invalid syntax!";
    } else {
      return "Some issue occured! Please contact administrator!";
    }
  }
};
