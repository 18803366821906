import * as React from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";

const FavoriteProductTitle = ({ record }) => {
  return (
    <span>
      {/* FavoriteProduct {record ? `"${record.firstname} ${record.lastname}"` : ""} */}
      FavoriteProduct
      {/* {record ? `"${record.product_id}"` : ""} */}
    </span>
  );
};

const FavoriteProductFilter = (props) => (
  <Filter {...props}>
    {/* <SearchInput source="user.name@_ilike" alwaysOn /> */}
        <ReferenceInput
      perPage={500}
      resettable
      source="user_id"
      reference="user"
      label="Select User"
      // sort={{ field: "name", order: "ASC" }}
      filterToQuery={(searchText) => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      perPage={500}
      resettable
      source="product_id"
      reference="product"
      label="Select Product"
      // sort={{ field: "name", order: "ASC" }}
      filterToQuery={(searchText1) => ({ name: searchText1 })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const FavoriteProductList = (props) => {
  return (
    <List exporter={false} filters={<FavoriteProductFilter />} {...props}>
      <Datagrid>
        <ReferenceField
          link={false}
          source="user_id"
          reference="user"
          label="Selected User"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          link={false}
          source="product_id"
          reference="product"
          label="Selected Product"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const FavoriteProductEdit = (props) => (
  <Edit title={<FavoriteProductTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        perPage={500}
        source="user_id"
        reference="user"
        label="Select User"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        perPage={500}
        source="product_id"
        reference="product"
        label="Select Product"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const FavoriteProductCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <TextInput
        source="id"
        defaultValue={React.useMemo(() => uuid(), [])}
        disabled
      /> */}
      <ReferenceInput
        perPage={500}
        source="user_id"
        reference="user"
        label="Select User"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        perPage={500}
        source="product_id"
        reference="product"
        label="Select Product"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
