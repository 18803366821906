import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import * as React from "react";
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  DeleteWithConfirmButton,
  Toolbar,
  ListButton,
} from "react-admin";
import uuid from "uuid";
import { INSERT_BANNER_ONE, UPDATE_BANNER_ONE } from "./GraphQL/mutation";
import { getSignedURL } from "./rest/api";
import { returnError } from "./enum/error";
import { ChevronLeft } from "@material-ui/icons";

const userRole = localStorage.getItem("ssp_admin_role");

const BannerTitle = ({ record }) => {
  return <span>Banner {record ? `"${record.name} "` : ""}</span>;
};

const BannerFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="banner_description@_ilike" alwaysOn />
    {userRole === "admin" && (
      <ReferenceInput
        perPage={1500}
        resettable
        source="org_id"
        reference="organization"
        label="Organization"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export const BannerList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Schemes List</h3>
    <List
      bulkActionButtons={false}
      exporter={false}
      undoable={false}
      filters={<BannerFilter />}
      {...props}
    >
      <Datagrid>
        <ImageField
          className="bannerImg"
          source="banner_image_url"
          label="Scheme Image"
          sortable={false}
        />
        <TextField
          required
          source="banner_description"
          label="Scheme Description"
        />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {userRole === "orgadmin" && <EditButton />}
        {userRole === "orgadmin" && (
          <DeleteWithConfirmButton
            confirmTitle="Confirm"
            onSuccess={() => {
              notify("Schemes deleted successfully!");
              window.location.reload();
            }}
            onFailure={(error) => {
              return notify(returnError(error.message), "warning");
            }}
          />
        )}
      </Datagrid>
    </List>
    </div>
  );
};

export const BannerEdit = (props) => {
  const [UPDATE_BANNER, { data: bannerAdded }] = useMutation(UPDATE_BANNER_ONE);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [banner_image_url, setbanner_image_url] = React.useState(null);
  const [banner_description, setbanner_description] = React.useState(null);

  React.useEffect(() => {
    dataProvider
      .getOne("banners", { id: props.id })
      .then(({ data }) => {
        setbanner_description(data?.banner_description);
        setbanner_image_url(data?.banner_image_url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dataProvider]);

  const handleEdit = async () => {
    try {
      let filename;
      let ext;
      if (!banner_description.trim()) {
        notify("Please enter scheme description", "warning");
        return;
      }
      if (banner_image_url && typeof banner_image_url === "object") {
        filename = uuid();
        ext = banner_image_url?.type?.split("/").pop();
        const signedURL = await getSignedURL(ext, filename);
        var options = {
          headers: {
            "Content-Type": banner_image_url?.type,
          },
        };
        await axios
          .put(signedURL.data, banner_image_url, options)
          .catch((e) => {
            console.log("error", e);
          });
      }
      UPDATE_BANNER({
        variables: {
          id: props.id,
          banner_description,
          banner_image_url: banner_image_url
            ? typeof banner_image_url === "object"
              ? `https://assets.supplyspring.in/${filename}.${ext}`
              : banner_image_url
            : null,
        },
      })
        .then((r) => {
          notify("Schemes details updated successfully!");
          redirect("/banners");
          return;
        })
        .catch((err) => {
          notify(returnError(err.message), "warning");
        });
    } catch (error) {
      notify(returnError(error.message), "warning");
    }
  };
  return (
    <Edit
      title={<BannerTitle />}
      undoable={false}
      {...props}
      onSuccess={() => {
        notify("banners updated successfully!");
        redirect("/banners");
      }}
      onFailure={(error) => {
        return notify(returnError(error.message), "warning");
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <Button
              disabled={!banner_description || !banner_image_url}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleEdit}
              {...props}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <ImageField
          source="banner_image_url"
          label="Scheme Image"
          className="bannerImg"
        />
        <TextInput
          onChange={(e) => setbanner_description(e.target.value.trim())}
          source="banner_description"
          label="Scheme Description"
        />
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*
        </small>
        <input
          required
          type="file"
          source="banner_image_url"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setbanner_image_url(e.target.files[0]);
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

const CreateBanner = ({ banner_image_url, banner_description }) => {
  const [INSERT_BANNER, { data: bannerAdded }] = useMutation(INSERT_BANNER_ONE);
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<SaveIcon />}
      disabled={!banner_description || !banner_image_url}
      onClick={async () => {
        try {
          let filename;
          let ext;
          if (!banner_description.trim()) {
            notify("Please enter scheme description", "warning");
            return;
          }
          if (banner_image_url) {
            filename = uuid();
            ext = banner_image_url.type.split("/").pop();
            const signedURL = await getSignedURL(ext, filename);
            var options = {
              headers: {
                "Content-Type": banner_image_url.type,
              },
            };
            await axios
              .put(signedURL.data, banner_image_url, options)
              .catch((e) => {
                console.log("error", e);
              });
          }

          INSERT_BANNER({
            variables: {
              banner_description,
              banner_image_url: banner_image_url
                ? `https://assets.supplyspring.in/${filename}.${ext}`
                : null,
            },
          })
            .then((r) => {
              notify("Schemes details added successfully!");
              redirect("/banners");
              return;
            })
            .catch((err) => {
              notify(returnError(err.message), "warning");
            });
        } catch (error) {
          notify(returnError(error.message), "warning");
        }
      }}
    >
      Save
    </Button>
  );
};

export const BannerCreate = (props) => {
  const [banner_image_url, setbanner_image_url] = React.useState(null);
  const [banner_description, setbanner_description] = React.useState(null);
  const redirect = useRedirect();
  return (
    <Create undoable={false} {...props} onSuccess={() => redirect("/banners")}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <CreateBanner
              banner_image_url={banner_image_url}
              banner_description={banner_description}
            />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          onChange={(e) => setbanner_description(e.target.value.trim())}
          source="banner_description"
          label="Scheme Description"
        />
        <br />
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*
        </small>
        <input
          required
          type="file"
          source="banner_image_url"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setbanner_image_url(e.target.files[0]);
          }}
        />
      </SimpleForm>
    </Create>
  );
};
