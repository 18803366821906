import { Button } from "@mui/material";
import * as React from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  List,
  minLength,
  minValue,
  number,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import { returnError } from "./enum/error";

const validateDiscount = [number(), minValue(0)];
const validateName = [required(), minLength(1)];

const ClassTitle = ({ record }) => {
  return <span>Class {record ? `"${record.name} "` : ""}</span>;
};

const ClassFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_ilike" alwaysOn />
    {userRole === "admin" && (
      <ReferenceInput
        perPage={1500}
        resettable
        source="org_id"
        reference="organization"
        label="Organization"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);
const userRole = localStorage.getItem("ssp_admin_role");
export const ClassList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Discount Type List</h3>
    <List
      bulkActionButtons={false}
      exporter={false}
      filters={<ClassFilter />}
      {...props}
    >
      <Datagrid>
        <TextField source="name" />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <NumberField source="discount" defaultValue={0} />
        {userRole === "orgadmin" && <EditButton />}
        {userRole === "orgadmin" && (
          <DeleteWithConfirmButton
            confirmTitle="Confirm"
            onSuccess={() => {
              notify("Discount deleted successfully!");
              window.location.reload();
            }}
            onFailure={(error) => {
              return notify(returnError(error.message), "warning");
            }}
          />
        )}
      </Datagrid>
    </List>
    </div>
  );
};

export const ClassEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const requiredName =
    (message = "Required") =>
    (value) => {
      const values = value ? value.trim() : null;
      return values ? undefined : message;
    };
  return (
    <Edit
      title={<ClassTitle />}
      {...props}
      onSuccess={() => {
        notify("Discount details updated successfully!");
        redirect("/class");
      }}
      onFailure={(error) => {
        notify(returnError(error.message), "warning");
      }}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput source="name" validate={[requiredName(), minLength(1)]} />
        <NumberInput
          defaultValue={0}
          min="0"
          source="discount"
          validate={validateDiscount}
        />
      </SimpleForm>
    </Edit>
  );
};

export const ClassCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const requiredName =
    (message = "Required") =>
    (value) => {
      const values = value ? value.trim() : null;
      return values ? undefined : message;
    };
  const transform = (data) => {
    return {
      ...data,
      name: data.name.trim(),
    };
  };
  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Discount details added successfully!");
        redirect("/class");
      }}
      onFailure={(error) => {
        return notify(returnError(error.message), "warning");
      }}
      transform={transform}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput source="name" validate={[requiredName(), minLength(1)]} />
        <NumberInput min="0" source="discount" validate={validateDiscount} />
      </SimpleForm>
    </Create>
  );
};
