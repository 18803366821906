import { Button } from "@mui/material";
import * as React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  minLength,
  minValue,
  number,
  NumberField,
  NumberInput,
  ReferenceField,
  required,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRedirect,
  useNotify,
  DeleteWithConfirmButton,
} from "react-admin";
import { returnError } from "./enum/error";

const userRole = localStorage.getItem("ssp_admin_role");

const SectionTitle = ({ record }) => {
  return <span>Section {record ? record.name : ""}</span>;
};

const SectionFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_ilike" alwaysOn />
  </Filter>
);

const requiredName =
  (message = "Required") =>
  (value) => {
    const values = value ? value.trim() : null;
    return values ? undefined : message;
  };

export const SectionList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Section List</h3>
    <List
      bulkActionButtons={false}
      exporter={false}
      filters={<SectionFilter />}
      {...props}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid>
        <TextField source="name" />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <NumberField source="order" />
        <BooleanField source="is_display" />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle="Confirm"
          onSuccess={() => {
            notify("Section deleted successfully!");
            window.location.reload();
          }}
          onFailure={(error) => {
            return notify(returnError(error.message), "warning");
          }}
        />
      </Datagrid>
    </List>
    </div>
  );
};

export const SectionEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const transform = (data) => {
    return data;
  };

  return (
    <Edit
      transform={transform}
      title={<SectionTitle />}
      {...props}
      onSuccess={() => {
        notify("Section details updated successfully!");
        redirect("/section");
      }}
      onFailure={(error) => {
        notify(returnError(error.message), "warning");
      }}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          required
          source="name"
          validate={[requiredName(), minLength(1)]}
        />
        <NumberInput
          source="order"
          validate={[required(), number(), minValue(0)]}
          label="Sort Order"
        />
        <BooleanInput source="is_display" />
      </SimpleForm>
    </Edit>
  );
};

export const SectionCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Create
      undoable={false}
      {...props}
      onSuccess={() => {
        notify("Section details added successfully!");
        redirect("/section");
      }}
      onFailure={(error) => {
        return notify(returnError(error.message), "warning");
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          required
          source="name"
          validate={[requiredName(), minLength(1)]}
        />
        <NumberInput
          source="order"
          max="9"
          label="Sort Order"
          validate={[required(), number(), minValue(0)]}
        />
        <BooleanInput source="is_display" />
      </SimpleForm>
    </Create>
  );
};
