import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import * as React from "react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  ImageField,
  List,
  minLength,
  ReferenceField,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import uuid from "uuid";
import { ImportFileBrand } from "./Components/Imports/ImportFileBrand";
import { EditableImage } from "./EditableImage";
import { returnError } from "./enum/error";
import { EDIT_BRANDS } from "./GraphQL/mutation";
import { GET_BRAND_BY_ID } from "./GraphQL/queries";
import { getSignedURL } from "./rest/api";
const userRole = localStorage.getItem("ssp_admin_role");

const requiredName =
  (message = "Required") =>
  (value) => {
    const values = value ? value.trim() : null;
    return values ? undefined : message;
  };

const validateText = [requiredName(), minLength(1)];

const BrandTitle = ({ record }) => {
  return <span>Brand {record ? `"${record.name}"` : ""}</span>;
};

const BrandFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_ilike" alwaysOn />
  </Filter>
);
const ListActions = ({ props, basePath }) => (
  <TopToolbar {...props}>
    {userRole === "admin" ? <></> : <CreateButton basePath={basePath} />}
    <ImportFileBrand type={basePath} />
    <ExportButton />
  </TopToolbar>
);
export const BrandList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Brands List</h3>
    <List
      filters={<BrandFilter />}
      actions={<ListActions />}
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" label="Brand Name" />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <ImageField source="logo" {...props} sortable={false} />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle="Confirm"
          onSuccess={() => {
            notify("Brand deleted successfully!");
            window.location.reload();
          }}
          onFailure={(error) => {
            return notify(returnError(error.message), "warning");
          }}
        />
      </Datagrid>
    </List>
    </div>
  );
};

export const BrandEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [UPDATE_BRAND, { data: brandUpdate }] = useMutation(EDIT_BRANDS);

  const { data: brandData } = useQuery(
    GET_BRAND_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );

  const [name, setName] = React.useState(null);
  const [logo, setLogo] = React.useState(null);

  React.useEffect(() => {
    if (brandData) {
      setLogo(brandData.brand_by_pk.logo);
      setName(brandData.brand_by_pk.name);
    }
  }, [brandData]);

  const handleBrandEdit = async () => {
    try {
      let filename;
      let ext;
      if (name) {
        if (logo && typeof logo === "object") {
          filename = uuid();
          ext = logo.type.split("/").pop();
          const signedURL = await getSignedURL(ext, filename);
          var options = {
            headers: {
              "Content-Type": logo.type,
            },
          };
          await axios.put(signedURL.data, logo, options).catch((e) => {
            console.log("error", e);
          });
        }

        UPDATE_BRAND({
          variables: {
            name: name,
            logo: logo
              ? typeof logo === "object"
                ? `https://assets.supplyspring.in/${filename}.${ext}`
                : logo
              : '',
            id: props.id,
          },
        })
          .then((r) => {
            notify("Brand details updated successfully!");
            redirect("/brand");
          })
          .catch((errors) => {
            notify(returnError(errors.message), "warning");
          });
      } else {
        notify("Name is Required");
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  return (
    <Edit undoable={false} title={<BrandTitle />} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleBrandEdit}
              disabled={!name}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          onChange={(e) => setName(e.target.value.trim())}
          validate={validateText}
          source="name"
          label="Brand Name"
        />
        {logo && typeof logo === "string" && <img src={logo} alt="logo" />}
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)
        </small>
        <input
          type="file"
          source="logo"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setLogo(e.target.files[0]);
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export const BrandCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const transform = (data) => {
    return {
      ...data,
      name: data.name.trim(),
    };
  };
  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Brand details added successfully!");
        redirect("/brand");
      }}
      onFailure={(error) => notify(returnError(error.message), "warning")}
      transform={transform}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          validate={[requiredName(), minLength(1)]}
          source="name"
          label="Brand Name"
        />
        <EditableImage
          source="logo"
          {...props}
          accept="image/png, image/gif, image/jpeg"
          label="Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)"
        />
      </SimpleForm>
    </Create>
  );
};
