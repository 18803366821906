import AddBox from "@material-ui/icons/AddBox";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, Button, Input, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDataProvider } from "react-admin";
import { INSERT_BRAND, INSERT_PRODUCT } from "../../GraphQL/mutation";
import { GET_BRAND_LIST } from "../../GraphQL/queries";
import { ExcelRenderer } from "react-excel-renderer";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 5,
  p: 4,
};

export const ImportFileBrand = ({ type }) => {
  const [open, setOpen] = React.useState(false);
  const [fileData, setFileData] = React.useState(null);
  const [filePath, setFilePath] = React.useState(null);
  const handleClose = () => {
    setInsertBrand(null);
    setErrorArr(null);
    setFileData(null);
    setOpen(false);
  };
  const dataProvider = useDataProvider();
  const [organizationId, setOrganizationId] = React.useState(null);
  const [orgCode, setOrgCode] = React.useState(null);
  const [insertBrandData, setInsertBrand] = React.useState(null);
  const [errorArr, setErrorArr] = React.useState(null);

  const totalUserLimit = React.useCallback(async () => {
    const { data: userLimit } = await dataProvider.getList("organization", {});
    setOrganizationId(userLimit[0].id);
    setOrgCode(userLimit[0].org_code);
  }, [dataProvider]);
  React.useEffect(() => {}, [filePath, insertBrandData, errorArr, fileData]);
  const { data: brand_list } = useQuery(
    GET_BRAND_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  React.useEffect(() => {
    if (!organizationId) {
      totalUserLimit();
    }
  }, [organizationId, brand_list]);

  React.useEffect(() => {
    const { REACT_APP_NODE_ENV, REACT_APP_ENDPOINT } = process.env;
    if (organizationId) {
      const REACT_API_URL = REACT_APP_ENDPOINT;
      axios({
        url: `${REACT_API_URL}/rest/downloadFileBrand`, //your url
        withCredentials: false,
        data: {},
        method: "POST",
        responseType: "blob",
      })
        .then((response) => {
          var data = [response.data];
          var blob = new Blob(data, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          setFilePath(url);
        })
        .catch((response) => {
          // setReload(true)
          // const link = doc
        });
    }
  }, [organizationId]);

  const [INSERT_BRAND_MUTATION, { data: brandData }] =
    useMutation(INSERT_BRAND);

  const handleCapture = ({ target }) => {
    setFileData(null);
    setInsertBrand(null);
    setErrorArr(null);
    setFileData(target.files[0]);
    uploadPackData(target.files[0]);
  };

  const downloadTemplate = (url) => {
    const link = document.createElement("a");
    link.href = filePath;
    const fileName = `Brand_${new Date().getTime()}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  const validateImportFile = (fields) => {
    if (
      fields.length &&
      fields[0].toString().trim().toLowerCase() === "brand name"
    ) {
      return true;
    }
    return false;
  };

  const uploadFile = async () => {
    try {
      const isInsertSuccessful = await INSERT_BRAND_MUTATION({
        variables: {
          objects: insertBrandData,
        },
      });
      if (!isInsertSuccessful.errors) {
        alert("Brands Data Saved");
        window.location.reload();
      } else {
        alert(
          "We couldn't save the brand details. Please verify the data. If issue persists, contact support!"
        );
      }
    } catch (error) {
      alert(
        "We couldn't save the brand details. Please verify the data. If issue persists, contact support!"
      );
    }
  };

  const uploadPackData = async (fileData) => {
    if (!fileData) {
      alert("Please select file to upload!");
      return;
    }
    const file = fileData;
    let insertBrands = [];
    let errorArr = [];
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const isValid = await validateImportFile(resp?.rows[1] || []);
        if (!isValid) {
          alert("Invalid file selected to import pack data!");
          return "";
        }
      }
      resp.rows.map((data, index) => {
        if (index > 3 && data.length) {
          if (!data[0]) {
            const errorObj = {};
            errorObj.description = "Brand name is mandatory!";
            errorObj.index = index + 1;
            errorObj.field = "Brand";
            errorArr.push(errorObj);
            return errorArr;
          }
          const obj = {};
          obj.name = data[0].toString();
          obj.logo = "";
          const prodIndex = insertBrands.findIndex(
            (itm) =>
              data[0] &&
              itm.name &&
              itm.name?.toLowerCase() === data[0]?.toLowerCase()
          );
          const prodIndexExist = brand_list?.brand.findIndex(
            (itm) =>
              obj.name &&
              itm.name &&
              itm.name?.toLowerCase() === obj.name?.toLowerCase()
          );
          if (prodIndex >= 0 || prodIndexExist >= 0) {
            const errorObj = {};
            errorObj.description = "Brand already exist!";
            errorObj.index = index + 1;
            errorObj.field = "Duplicate Record";
            errorArr.push(errorObj);
          }
          insertBrands.push(obj);
        }
      });
      try {
        if (errorArr.length > 0) {
          setErrorArr(errorArr);
          // setShowSuccessModal(true)
          return "";
        }
        if (errorArr.length === 0 && insertBrands.length === 0) {
          alert(
            "No valid data exist in the file, Please enter data and try again!"
          );
          return "";
        }
        setInsertBrand(insertBrands);
      } catch (error) {
        alert(
          "We couldn't save the brand details. Please verify the data. If issue persists, contact support!"
        );
      }
    });
  };

  return (
    <div>
      {filePath ? (
        <div>
          <Button
            startIcon={<AddBox />}
            onClick={() => setOpen(true)}
            variant="text"
            color="primary"
            className="importText"
          >
            Import
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="closePopup">
                <CancelIcon className="" onClick={handleClose} />
              </div>
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                className="importbody"
              >
                <div className="steplist">
                  <h3>STEP 1</h3>
                  <div className="stepnotes">
                    Download this excelsheet and add your back data in that
                    sheet.
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    onClick={downloadTemplate}
                    className="btnwidth"
                  >
                    Download Template
                  </Button>
                </div>
                <label
                  htmlFor="contained-button-file"
                  onChange={handleCapture}
                  className="mt-0 w-100"
                >
                  <Input
                    accept=".xls, .xlsx"
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                  />
                  <div className="steplist">
                    <h3>STEP 2</h3>
                    <div className="stepnotes">
                      Once you have updated sample sheet with your data, choose
                      that file to upload
                    </div>
                    <Button
                      color="success"
                      variant="contained"
                      component="span"
                      className="btnwidth"
                    >
                      Select File
                    </Button>
                  </div>
                  <div className="filename">{fileData?.name}</div>
                </label>
                {errorArr?.length ? (
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Row #</TableCell>
                            <TableCell align="right">Field</TableCell>
                            <TableCell align="right">Description</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {errorArr.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.index}
                              </TableCell>
                              <TableCell align="right">{row.field}</TableCell>
                              <TableCell align="right">
                                {row.description}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <></>
                )}
                <div className="steplist">
                  <h3>STEP 3</h3>
                  <div className="stepnotes">
                    Once you have selected apprioriate file, if there is no
                    error in the sheet, this button will be enabled and you will
                    be able to upload your data.
                  </div>
                  <Button
                    className="btnwidth"
                    color="secondary"
                    variant="contained"
                    component="span"
                    onClick={uploadFile}
                    disabled={insertBrandData ? false : true}
                  >
                    Submit
                  </Button>
                </div>
              </Stack>
            </Box>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
