import { useMutation, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  List,
  minLength,
  minValue,
  number,
  NumberInput,
  ReferenceField,
  required,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import uuid from "uuid";
import { returnError } from "./enum/error";
import { ADD_CATEGORY, EDIT_CATEGORY } from "./GraphQL/mutation";
import {
  CATEGORY_BY_ID,
  IS_CATEGORY_EXIST,
  GET_ORG_ID,
} from "./GraphQL/queries";
import { getSignedURL } from "./rest/api";

const userRole = localStorage.getItem("ssp_admin_role");

const CategoryTitle = ({ record }) => {
  return <span>Category {record ? `"${record.name} "` : ""}</span>;
};

const validateNumber = [
  required("Order by must be atleast 0"),
  number(),
  minValue(0),
];
const validateText = [required(), minLength(1)];

const CategoryFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_ilike,type@_ilike" alwaysOn />
  </Filter>
);

export const CategoryList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Category List</h3>
    <List
      bulkActionButtons={false}
      filters={<CategoryFilter />}
      {...props}
      sort={{ field: "name", order: "ASC" }}
      exporter={false}
    >
      <Datagrid>
        <ImageField source="logo" sortable={false} />
        <TextField source="name" />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="order_by" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle="Confirm"
          onSuccess={() => {
            notify("Category deleted successfully!");
            window.location.reload();
          }}
          onFailure={(error) => {
            return notify(returnError(error.message), "warning");
          }}
        />
      </Datagrid>
    </List>
    </div>
  );
};

export const CategoryEdit = (props) => {
  const [name, setName] = useState(null);
  const [type, setType] = useState("first");
  const [orderBy, setOrderBy] = useState(null);
  const [logo, setLogo] = useState(null);
  const { data: categoryData } = useQuery(
    CATEGORY_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );

  const { data: categoryExist } = useQuery(
    IS_CATEGORY_EXIST,
    {
      variables: { name: name, order_by: orderBy },
    },
    { fetchPolicy: "network-only" }
  );
  useEffect(() => {}, [name, orderBy]);

  const [EDIT_CATEGORY_MUTATION, { data: editData }] =
    useMutation(EDIT_CATEGORY);

  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    if (categoryData) {
      setName(categoryData.category_by_pk.name);
      setLogo(categoryData.category_by_pk.logo);
      setType(categoryData.category_by_pk.type);
      setOrderBy(categoryData.category_by_pk.order_by);
    }
  }, [categoryData]);

  // useEffect(() => {
  //   if (editData) {
  //     notify("Category updated successfully!");
  //     redirect("/category");
  //   }
  // }, [editData]);

  const handleEdit = async (data) => {
    if (!name || !orderBy) {
      notify("Please enter manadtory fields!");
      return;
    }
    if (orderBy < 0) {
      notify("Order by must be atleast 0!");
      return;
    }
    try {
      let filename;
      let ext;
      if (categoryExist?.category?.length > 1) {
        notify("Category already exist in system!", "warning");
        return;
      }
      if (logo && typeof logo === "object") {
        filename = uuid();
        ext = logo.type.split("/").pop();
        const signedURL = await getSignedURL(ext, filename);
        var options = {
          headers: {
            "Content-Type": logo.type,
          },
        };
        await axios.put(signedURL.data, logo, options).catch((e) => {
          console.log("error", e);
        });
      }
      EDIT_CATEGORY_MUTATION({
        variables: {
          name: name !== null ? name : data?.name,
          type: type !== null ? type : data?.type,
          logo: logo
            ? typeof logo === "object"
              ? `https://assets.supplyspring.in/${filename}.${ext}`
              : logo
            : null,
          order_by: orderBy !== null ? orderBy : data?.order_by,
          id: props.id,
        },
      })
        .then((r) => {
          notify("Category details updated successfully!");
          redirect("/category");
          return;
        })
        .catch((errors) => {
          notify(returnError(errors.message), "warning");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Edit undoable={true} title={<CategoryTitle />} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <Button
              disabled={!name || !orderBy}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleEdit}
              {...props}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          validate={validateText}
          onChange={(e) => setName(e.target.value.trim())}
          source="name"
        />
        <NumberInput
          onChange={(e) => setOrderBy(e.target.value.trim())}
          validate={[required(), number(), minValue(0)]}
          source="order_by"
        />
        <ImageField source="logo" />
        <br />
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)
        </small>
        <input
          type="file"
          source="logo"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setLogo(e.target.files[0]);
          }}
          placeholder="Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*"
        />

        <SelectInput
          source="type"
          label="Category Type"
          onChange={(e) => setType(e.target.value.trim())}
          required
          choices={[
            { id: "first", name: "Primary" },
            { id: "second", name: "Secondary" },
          ]}
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Edit>
  );
};
export const CategoryCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState(null);
  const [type, setType] = useState("first");
  const [orderBy, setOrderBy] = useState(null);
  let { data: orgCode } = useQuery(GET_ORG_ID, {
    fetchPolicy: "network-only",
  });
  const [ADD_CATEGORY_MUTATION, { data: addData }] = useMutation(ADD_CATEGORY);
  const { data: categoryExist } = useQuery(
    IS_CATEGORY_EXIST,
    {
      variables: { name: name, order_by: orderBy },
    },
    { fetchPolicy: "network-only" }
  );
  useEffect(() => {
    if (orgCode) {
      console.log(orgCode.organization[0].id);
    }
  }, [name, orderBy, orgCode]);

  const handleCreate = async (data) => {
    if (!name || !orderBy) {
      notify("Please enter manadtory fields!");
      return;
    }
    if (orderBy < 0) {
      notify("Order by must be atleast 0!");
      return;
    }
    try {
      let filename;
      let ext;
      if (categoryExist?.category?.length) {
        notify("Category already exist in system!", "warning");
        return;
      }
      if (logo && typeof logo === "object") {
        filename = uuid();
        ext = logo.type.split("/").pop();
        const signedURL = await getSignedURL(ext, filename);
        var options = {
          headers: {
            "Content-Type": logo.type,
          },
        };
        await axios.put(signedURL.data, logo, options).catch((e) => {
          console.log("error", e);
        });
      }
      ADD_CATEGORY_MUTATION({
        variables: {
          name: name !== null ? name : data?.name,
          type: type !== null ? type : data?.type,
          logo: logo
            ? typeof logo === "object"
              ? `https://assets.supplyspring.in/${filename}.${ext}`
              : logo
            : null,
          order_by: orderBy !== null ? orderBy : data?.order_by,
          org_id: orgCode.organization[0].id,
        },
      })
        .then((r) => {
          notify("Category details saved successfully!");
          redirect("/category");
          return;
        })
        .catch((errors) => {
          notify(returnError(errors.message), "warning");
        });
    } catch (error) {}
  };
  return (
    <Create {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleCreate}
              disabled={!name || !orderBy}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          validate={validateText}
          onChange={(e) => setName(e.target.value.trim())}
          source="name"
        />
        <NumberInput
          validate={validateNumber}
          onChange={(e) => setOrderBy(e.target.value.trim())}
          source="order_by"
        />
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)
        </small>
        <input
          type="file"
          source="logo"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setLogo(e.target.files[0]);
          }}
          placeholder="Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*"
        />
        <SelectInput
          source="type"
          label="Category Type"
          required
          onChange={(e) => setType(e.target.value.trim())}
          choices={[
            { id: "first", name: "Primary" },
            { id: "second", name: "Secondary" },
          ]}
          defaultValue="first"
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Create>
  );
};
