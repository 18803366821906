import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import * as React from "react";
import {
  Edit,
  maxLength,
  minLength,
  number,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  required,
} from "react-admin";
import {
  INSERT_SHIPPING_ADDRESS,
  UPDATE_RETAILER_BY_ID,
  UPDATE_RETAILER_PHONE,
  UPDATE_SHIPPING_BY_ID,
} from "../../GraphQL/mutation";
import { GET_RETAILER_DETAILS_BY_ID } from "../../GraphQL/queries";

const phoneValidate = [number(), minLength(10), maxLength(10)];

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.name}"` : ""}</span>;
};

export const EditRetailer = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [header, setHeader] = React.useState(undefined);
  const [name, setname] = React.useState(null);
  const [userRole, setUserRole] = React.useState(null);
  const [phone, setphone] = React.useState(null);
  const [address, setaddress] = React.useState(null);
  const [city, setcity] = React.useState(null);
  const [state, setstate] = React.useState(null);
  const [country, setcountry] = React.useState(null);
  const [pincode, setpincode] = React.useState(null);
  const [class_id, setclass_id] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPhoneUpdated, setIsPhoneUpdated] = React.useState(false);
  const [orgUser, setOrgUser] = React.useState([]);

  const [UPDATE_RETAILER_DATA, { data: retailerEditData }] = useMutation(
    UPDATE_RETAILER_BY_ID
  );
  const [UPDATE_SHIPPING_DATA, { data: shippingEditData }] = useMutation(
    UPDATE_SHIPPING_BY_ID
  );
  const [UPDATE_RETAILER_PHONE_DATA, { data: updatePhoneData }] = useMutation(
    UPDATE_RETAILER_PHONE
  );
  const [INSERT_SHIPPING_ADDRESS_DATA, { data: shippingData }] = useMutation(
    INSERT_SHIPPING_ADDRESS
  );

  const { data: retailerData, loading: retailerDataLoading } = useQuery(
    GET_RETAILER_DETAILS_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );

  React.useEffect(() => {
    dataProvider
      .getList("user", { filter: { is_deleted: false } })
      .then(({ data: users }) => {
        setOrgUser(users);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dataProvider]);

  React.useEffect(() => {
    if (userRole === "billing") {
      if (retailerEditData && updatePhoneData) {
        notify("Co-admin updated successfully");
        redirect("/organization");
      }
    } else if (retailerEditData && updatePhoneData) {
      notify("Retailer updated successfully");
      redirect("/user");
    }
  }, [retailerEditData, updatePhoneData]);

  React.useEffect(() => {
    if (retailerData && !retailerDataLoading) {
      setIsLoading(true);
      setUserRole(retailerData?.user_by_pk?.role);
      setHeader(retailerData?.user_by_pk?.name);
      setname(retailerData?.user_by_pk?.name);
      setphone(retailerData?.user_by_pk?.phone);
      setaddress(retailerData?.user_by_pk?.shipping_addresses[0]?.address);
      setcity(retailerData?.user_by_pk?.city);
      setstate(retailerData?.user_by_pk?.state);
      setcountry(retailerData?.user_by_pk?.shipping_addresses[0]?.country);
      setpincode(retailerData?.user_by_pk?.pincode);
      setclass_id(retailerData?.user_by_pk?.class_id);
      setIsLoading(false);
    }
  }, [retailerData, retailerDataLoading]);

  if (retailerDataLoading || isLoading) {
    return <span>Loading...</span>;
  }

  const handleEdit = async () => {
    const userPhone = orgUser.findIndex((u) => u.phone === Number(phone));
    if (
      !name ||
      !city ||
      !state ||
      !pincode ||
      !phone ||
      ((!class_id || !address || !country) && userRole === "user")
    ) {
      notify("Please enter mandatory field");
      return;
    }
    if (phone?.toString()?.length !== 10 || isNaN(phone)) {
      notify("Enter valid phone number");
      return;
    }
    if (pincode?.toString()?.length > 10 || pincode?.toString()?.length < 5) {
      notify("Enter valid pincode number");
      return;
    }
    if (isPhoneUpdated && userPhone > -1) {
      notify("Phone number already exists");
      return;
    } else {
      try {
        UPDATE_RETAILER_DATA({
          variables: {
            id: props.id,
            city,
            class_id,
            state,
            phone,
            name,
            pincode,
          },
        }).then(async (response) => {
          if (!response.error && userRole === "user") {
            if (
              response?.data?.update_user_by_pk?.shipping_addresses.length > 0
            ) {
              await UPDATE_SHIPPING_DATA({
                variables: {
                  id: response?.data?.update_user_by_pk?.shipping_addresses[0]
                    .id,
                  name,
                  phone,
                  city,
                  state,
                  pincode,
                  country,
                  address,
                },
              });
            } else {
              await INSERT_SHIPPING_ADDRESS_DATA({
                variables: {
                  name,
                  phone,
                  city,
                  state,
                  pincode,
                  country,
                  address,
                  created_by: response?.data?.update_user_by_pk?.id,
                },
              });
            }
          }
        });
        UPDATE_RETAILER_PHONE_DATA({
          variables: {
            user_id: props.id,
            phone,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <h3>{header || "Edit User"}</h3>
      <Edit undoable={false} title={<UserTitle />} {...props}>
        <SimpleForm
          toolbar={
            <Toolbar>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => handleEdit()}
              >
                Save
              </Button>
              <Button
                className="back-button"
                variant="outlined"
                color="info"
                onClick={() =>
                  redirect(userRole === "user" ? "/user" : "/organization")
                }
              >
                Back
              </Button>
            </Toolbar>
          }
        >
          <TextInput
            source="name"
            validate={[minLength(2), maxLength(255)]}
            required
            placeholder="Retailer Name"
            label="Retailer Name"
            onChange={(e) => setname(e.target.value.trim())}
          />
          <TextInput
            source="phone"
            validate={phoneValidate}
            required
            placeholder="Retailer Phone"
            label="Retailer Phone"
            onChange={(e) => {
              if (
                retailerData?.user_by_pk?.phone ===
                Number(e.target.value.trim())
              ) {
                setIsPhoneUpdated(false);
              } else {
                setIsPhoneUpdated(true);
              }
              setphone(Number(e.target.value.trim()));
            }}
          />
          {userRole === "user" && (
            <TextInput
              source="address"
              defaultValue={address}
              validate={[minLength(2), maxLength(255)]}
              required
              placeholder="Shipping Address"
              label="Shipping Address"
              onChange={(e) => setaddress(e.target.value.trim())}
            />
          )}
          <TextInput
            source="city"
            defaultValue={city}
            validate={[minLength(2), maxLength(255)]}
            required
            placeholder="City"
            label="City"
            onChange={(e) => setcity(e.target.value.trim())}
          />
          <TextInput
            source="state"
            defaultValue={state}
            validate={[minLength(2), maxLength(255)]}
            required
            placeholder="State"
            label="State"
            onChange={(e) => setstate(e.target.value.trim())}
          />
          {userRole === "user" && (
            <TextInput
              source="country"
              defaultValue={country}
              validate={[minLength(2), maxLength(255)]}
              required
              placeholder="Country"
              label="Country"
              onChange={(e) => setcountry(e.target.value.trim())}
            />
          )}
          <TextInput
            source="pincode"
            validate={[minLength(5), maxLength(10)]}
            required
            placeholder="Pincode"
            label="Pincode"
            onChange={(e) => setpincode(e.target.value.trim())}
          />
          {userRole !== "billing" && (
            <ReferenceInput
              validate={[required("Select Discount"), minLength(1)]}
              required
              perPage={500}
              source="class_id"
              reference="class"
              label="Select Discount Type"
              defaultValue={class_id}
              onChange={(e) => setclass_id(e.target.value.trim())}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
        </SimpleForm>
      </Edit>
    </>
  );
};
