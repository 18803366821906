import { useLazyQuery, useQuery } from "@apollo/client";
import { Card, Grid, Paper, Button } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { CustIcon } from "../Assets/Images/Svg/Svgs";
import {
  GET_ORGANIZATION_DATA,
  GET_ORGANIZATION_DATA_BY_ID,
  GET_TOTAL_ORDER_LIST,
  GET_ORGADMIN_STATE,
  GET_ORGANIZATIONS_DATA_BY_ID
} from "../GraphQL/queries";
import QuantityDashboardChart from "../quantityDashboardChart";
import RevenueDashboardChart from "../revenueDashboardChart";
import loader from "../Assets/Images/loader.svg";
import { useDataProvider } from "react-admin";
import jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

const Dashboard = () => {
  const dataProvider = useDataProvider();

  const [supplierCount, setSupplierCount] = React.useState(0);
  const [userCount, setUserCount] = React.useState(0);
  const [orderCount, setOrderCount] = React.useState(0);
  const [revenueGenerated, setRevenueGenerated] = React.useState(0);
  const [orderCompletedCount, setOrderCompletedCount] = React.useState(0);
  const [newOrderCount, setNewOrderCount] = React.useState(0);
  const [billingOrderCount, setBillingOrderCount] = React.useState(0);
  const [dispatchOrderCount, setDispatchOrderCount] = React.useState(0);
  const [orderRejectedCount, setOrderRejectedCount] = React.useState(0);
  const [productsCount, setProductsCount] = React.useState(0);
  const [packsCount, setPacksCount] = React.useState(0);
  const [organizationId, setOrganizationId] = React.useState(null);
  const [organizationName, setOrganizationName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [stateList, setStateList] = React.useState(null);
  const [stats, setStates] = React.useState(null)
  const [orgList, setOrgList] = React.useState([])
  const inputRef = React.useRef(null);
  const [reportFilterRange, setReportFilterRange] = React.useState({
    startDate: moment().startOf("day").subtract(7, "days").format(),
    endDate: moment().startOf("day").format(),
  });

  const [
    GET_ORGANIZATION_DATA_BY_ID_QUERY,
    { data: orgData, loading: orgLoading },
  ] = useLazyQuery(GET_ORGANIZATIONS_DATA_BY_ID, {
    fetchPolicy: "cache-and-network",
  });
  const [
    GET_TOTAL_ORDER_LIST_QUERY,
    { data: orderData, loading: orderDataLoading },
  ] = useLazyQuery(GET_TOTAL_ORDER_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const { data: organizationData } = useQuery(GET_ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
  });
  const { data: stateData } = useQuery(GET_ORGADMIN_STATE, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if(stateData?.organization_user?.length){
      console.log(stateData)
      let uNames = new Map(stateData?.organization_user.map(s => [s.user.state.trim().toLowerCase(), s]));
      console.log([...uNames.values()]); 
      setStateList([...uNames.values()])
    }
  }, [stateData])

  const getOrgList = (state) => {
    const org_list = [];
    setStates(state)
    stateData?.organization_user.map((itm) => {
      if(itm.user?.state?.trim().toLowerCase() === state?.trim().toLowerCase()){
        org_list.push(itm?.organization.id)
      }
    })
    if(!state){
      setOrganizationId(null)
    }
    setOrgList(org_list)
  }

  React.useEffect(() => {

  }, [stats])

  React.useEffect(() => {
    GET_TOTAL_ORDER_LIST_QUERY({
      variables: {
        org_id: orgList?.length ? orgList : organizationId ?? undefined,
        startDate: reportFilterRange.startDate ?? undefined,
        endDate: reportFilterRange.endDate ?? undefined,
      },
    });
    setIsLoading(true);
    if (orderData) {
      const statusWiseOrderCounts = orderData?.order.reduce(
        (accumulator, currentOrder) => {
          let latestStatus = currentOrder?.order_statuses?.reduce(
            (prev, curr) => {
              return moment(prev.created_at).isAfter(moment(curr.created_at))
                ? prev
                : curr;
            }
          );
          if (latestStatus.status === "completed") {
            accumulator.totalRevenue +=
              currentOrder?.order_total_discounted_amount || 0;
          }
          if (!accumulator[latestStatus.status]) {
            accumulator[latestStatus.status] = 1;
          } else {
            accumulator[latestStatus.status] =
              accumulator[latestStatus.status] + 1;
          }
          return accumulator;
        },
        { totalRevenue: 0 }
      );
      setRevenueGenerated(Math.ceil(statusWiseOrderCounts.totalRevenue));
    }
    setIsLoading(false);
  }, [organizationId, reportFilterRange, orderData, orgList]);

  React.useEffect(() => {
    GET_ORGANIZATION_DATA_BY_ID_QUERY({
      variables: {
        org_id: orgList?.length ? orgList : organizationId ?? undefined,
        startDate: reportFilterRange.startDate ?? undefined,
        endDate: reportFilterRange.endDate ?? undefined,
      },
    });
    setIsLoading(true);
    if (orgData) {
      setSupplierCount(orgData?.organization_aggregate?.aggregate?.count);
      setUserCount(orgData?.organization_user_aggregate?.aggregate?.count);
      setProductsCount(orgData?.product_aggregate?.aggregate?.count);
      setPacksCount(orgData?.pack_aggregate?.aggregate?.count);
      setOrderCount(orgData.order.length);
      const newOrder = orgData.order.filter(
        (orders) => orders.order_statuses[0].status === "new"
      );
      const billingOrder = orgData.order.filter(
        (orders) => orders.order_statuses[0].status === "in_billing"
      );
      const dispatchOrder = orgData.order.filter(
        (orders) => orders.order_statuses[0].status === "dispatched"
      );
      const completedOrder = orgData.order.filter(
        (orders) => orders.order_statuses[0].status === "completed"
      );
      const rejectOrder = orgData.order.filter(
        (orders) => orders.order_statuses[0].status === "rejected"
      );
      setNewOrderCount(newOrder.length);
      setBillingOrderCount(billingOrder.length);
      setDispatchOrderCount(dispatchOrder.length);
      setOrderCompletedCount(completedOrder.length);
      setOrderRejectedCount(rejectOrder.length);
      if (organizationId) {
        setOrganizationName(orgData.organization[0].name);
      } else {
        setOrganizationName("");
      }
    }
    setIsLoading(false);
  }, [organizationId, reportFilterRange, orgData, orgList]);

  React.useEffect(() => {}, [reportFilterRange]);
  const handlePrint = () => {
    inputRef.current.className = `${inputRef.current.className} no-box-shadow`;
    const quality = 1
    html2canvas(inputRef.current,  { scale: quality }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF('l', 'mm', [297, 210]);
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 5, 280, 0);
      pdf.save("download.pdf");
    })
  }
  if (isLoading) {
    <div className="loader_blk loader_blk_max">
      <img src={loader} alt="" />
    </div>;
  }

  return (
    <div class="dashboard" ref={inputRef}>
      <div class="dashboard-flex" style={{justifyContent: "space-between"}}>
        <h3>
          {stats ? stats + ` Dashboard` : (organizationName
            ? organizationName + ` Dashboard`
            : `All Dashboards`)}
        </h3>
        <div class="dashboardDropdown">
        <Button
         style={{ marginRight: "20px"}}
          variant="contained"
          color="secondary"
          size="small"
          onClick={handlePrint}
        >
          Print
        </Button>
        <select style={{ marginRight: "20px"}}
            defaultValue={stats}
            onChange={(e) => {
              getOrgList(
                !!e.target.value.trim() ? e.target.value.trim() : null
              );
            }}
          >
            <option>All</option>
            {stateList?.map((org, index) => (
              <option>
                {org?.user?.state}
              </option>
            ))}
          </select>
        {organizationData && (
          <select
            onChange={(e) => {
              setStates("")
              setOrgList([])
              setOrganizationId(
                !!e.target.value.trim() ? e.target.value.trim() : null
              );
            }}
          >
            <option>All</option>
            {organizationData?.organization?.map((org, index) => (
              <option key={index} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        )}
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item md={3} sm={6} xs={12}>
          <Paper>
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <CustIcon type="retailers" />
                </div>
                <div class="text-3xl">{supplierCount || 0}</div>
                <div class="text-base">Active Suppliers</div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper>
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <CustIcon type="retailers" />
                </div>
                <div class="text-3xl">{userCount || 0}</div>
                <div class="text-base">Active Retailers</div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper>
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <CustIcon type="products" />
                </div>
                <div class="text-3xl">{productsCount}</div>
                <div class="text-base">Total Products</div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper>
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <CustIcon type="packs" />
                </div>
                <div class="text-3xl">{packsCount}</div>
                <div class="text-base">Total Packs</div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid
            justify="flex-end"
            item
            md={12}
            sm={12}
            xs={12}
            className="dashboard-flex"
          >
            <select
              onChange={(e) => {
                setReportFilterRange(JSON.parse(e.target.value.trim()));
              }}
              value={JSON.stringify(reportFilterRange)}
            >
              <option
                value={JSON.stringify({
                  startDate: moment()
                    .startOf("day")
                    .subtract(7, "days")
                    .format(),
                  endDate: moment().startOf("minute").format(),
                })}
              >
                Last 7 Days
              </option>
              <option
                value={JSON.stringify({
                  startDate: moment()
                    .startOf("day")
                    .subtract(15, "days")
                    .format(),
                  endDate: moment().startOf("minute").format(),
                })}
              >
                Last 15 Days
              </option>
              <option
                value={JSON.stringify({
                  startDate: moment()
                    .startOf("day")
                    .subtract(1, "months")
                    .format(),
                  endDate: moment().startOf("minute").format(),
                })}
              >
                Last 1 Month
              </option>
              <option
                value={JSON.stringify({
                  startDate: moment()
                    .startOf("day")
                    .subtract(3, "months")
                    .format(),
                  endDate: moment().startOf("minute").format(),
                })}
              >
                Last 3 Months
              </option>
              <option
                value={JSON.stringify({
                  startDate: null,
                  endDate: null,
                })}
              >
                All
              </option>
            </select>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="order" />
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div class="text-3xl">{orderCount}</div>
                  )}
                  <div class="text-base">Total Orders</div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="rupee" />
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div class="text-3xl">
                      <CustIcon type="rupee" />
                      {new Intl.NumberFormat("en-IN").format(revenueGenerated)}
                    </div>
                  )}
                  <div class="text-base">Total Revenue</div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="completed" />
                  </div>
                  {isLoading ? (
                    <Loader full />
                  ) : (
                    <div class="text-3xl">{orderCompletedCount}</div>
                  )}
                  <div class="text-base">Completed Orders</div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="new-order" />
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div class="text-3xl">{newOrderCount}</div>
                  )}
                  <div class="text-base">New Orders</div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="billing" />
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div class="text-3xl">{billingOrderCount}</div>
                  )}
                  <div class="text-base">Billing Orders</div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="dispatch" />
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div class="text-3xl">{dispatchOrderCount}</div>
                  )}
                  <div class="text-base">Dispatch Order</div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper>
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CustIcon type="rejected" />
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div class="text-3xl">{orderRejectedCount}</div>
                  )}
                  <div class="text-base">Rejected Orders</div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <div className="chartContainer">
        <Grid container spacing={3}>
          <Grid item md={6} sm={12} xs={12}>
            <Card>
              <h3>Top Supplier By Order Quantities.</h3>
              <div id="container" />
              <QuantityDashboardChart
                org_list={orgList}
                org_id={organizationId}
                startDate={reportFilterRange?.startDate} // moment().subtract(1, "w").format()}
                endDate={reportFilterRange?.endDate} //moment().format()}
              />
            </Card>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Card>
              <h3>Top Supplier By Order Revenues.</h3>
              <RevenueDashboardChart
                org_list={orgList}
                org_id={organizationId}
                startDate={reportFilterRange?.startDate} // moment().subtract(1, "w").format()}
                endDate={reportFilterRange?.endDate} //moment().format()}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Dashboard;

const Loader = (full) => {
  return (
    <div className={full === true ? "loader_blk" : ""}>
      <img style={{ height: full !== true && "60px" }} src={loader} alt="" />
    </div>
  );
};
