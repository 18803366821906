import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PaymentIcon from "@material-ui/icons/Payment";
import { Box, Grid, Modal, Paper, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import * as React from "react";
import { useDataProvider, useRedirect } from "react-admin";
import {
  CANCEL_PLAN,
  CAPTURE_PAYMENT,
  CAPTURE_UPGRADE_PAYMENT,
  CREATE_ORDER,
  CREATE_UPGRADE_ORDER,
  UPDATE_ORG_TYPE,
  VERIFY_PAYMENT,
} from "../GraphQL/mutation";
import {
  GET_PAYMENT_DUE,
  GET_RETAILER_SUBSCRIPTION,
  GET_ORIGINAL_PAYMENT_PERIOD,
} from "../GraphQL/queries";
import { UpgradePlan } from "../UpgradePlan";

const Subscription = () => {
  const userRole = localStorage.getItem("ssp_admin_role");
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [planType, setPlanType] = React.useState("");
  const [plantypeId, setPlantypeId] = React.useState("");
  const [cancelPlanModal, setCancelPlanModal] = React.useState(false);
  const [plan, setPlanData] = React.useState(null);
  const [retailerLimit, setRetailerLimit] = React.useState(0);
  const [dueDate, setDueDate] = React.useState(0);
  const [planPrice, setPlanPrice] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [pending, setPending] = React.useState(null);
  const [orgid, setOrgId] = React.useState(null);
  const [subData, setSubData] = React.useState(null);
  const [INSERT_ORDER] = useMutation(CREATE_ORDER);
  const [INSERT_UPGRADE_ORDER] = useMutation(CREATE_UPGRADE_ORDER);
  const [CHECK_PAYMENT_STATUS] = useMutation(VERIFY_PAYMENT);
  const [PAYMENT_CAPTURE] = useMutation(CAPTURE_PAYMENT);
  const [PAYMENT_UPGRADE_CAPTURE] = useMutation(CAPTURE_UPGRADE_PAYMENT);
  const [DOWNGRADE_PLAN] = useMutation(UPDATE_ORG_TYPE);
  const [PLAN_REMOVE] = useMutation(CANCEL_PLAN);
  const [showPay, setIsShowPay] = React.useState(false);
  const [issixmonth, setsixmonth] = React.useState(false);
  const [usersLimit, setUsersLimit] = React.useState(null);
  const [organizationId, setOrganizationId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [currentPeriod, setCurrentPeriodDays] = React.useState(0);
  const [userDetail, setUserDetail] = React.useState({});
  const [paymentDueData, setPaymentDue] = React.useState([]);
  const [paymentTransaction, setPaymentTransaction] = React.useState([]);
  const [subscriptionsData, setSubscriptionData] = React.useState([]);
  const [reportFilterRange, setReportFilterRange] = React.useState({
    startDate: moment().startOf("day").subtract(1, "month").format(),
    endDate: moment().startOf("day").format(),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleCancelPlan = () => {
    const isSuccess = PLAN_REMOVE({
      variables: {
        org_id: subData?.organization[0]?.id,
      },
    });
    if (!isSuccess.errors) {
      alert("Your subscription is cancelled");
      localStorage.removeItem("ssp_admin_access_token");
      localStorage.removeItem("ssp_admin_role");
      localStorage.clear();
      redirect("/category");
    }
  };

  const { data: subscriptionData, loading: subscriptionDataLoading } = useQuery(
    GET_RETAILER_SUBSCRIPTION,
    {
      variables: {
        org_id: organizationId,
      },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: originalPlan, loading: originalPlanLoad } = useQuery(
    GET_ORIGINAL_PAYMENT_PERIOD,
    {
      variables: {
        org_id: organizationId,
      },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: dataPaymentDue, loading: paymentLoading } = useQuery(
    GET_PAYMENT_DUE,
    {
      variables: {
        org_id: organizationId,
        gt:
          userRole === "admin" && reportFilterRange.startDate
            ? reportFilterRange.startDate
            : undefined,
        lt:
          userRole === "admin" && reportFilterRange.endDate
            ? reportFilterRange.endDate
            : undefined,
      },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  const totalUserLimit = React.useCallback(async () => {
    const { data: userLimit } = await dataProvider.getList("organization", {});
    const { data: allUsers } = await dataProvider.getList("user", {});
    const adminUser = allUsers.filter((user) => user.role === "orgadmin");
    const activeUserCount = allUsers.filter(
      (user) => user.is_deleted === false
    );
    setUserDetail(adminUser[0]);
    if (userRole === "orgadmin") {
      setOrganizationId(userLimit[0].id);
    }
    setUsersLimit(activeUserCount?.length);
  }, [dataProvider]);

  React.useEffect(() => {
    if (!organizationId) {
      setOrgId(organizationId);
      totalUserLimit();
    }
  }, [organizationId, userDetail.name]);

  React.useEffect(() => {
    if (
      subscriptionData?.organization.length &&
      dataPaymentDue?.payment_due &&
      originalPlan?.organization.length
    ) {
      let isPending = -1;
      if (originalPlan?.organization[0]?.payment_transactions?.length) {
        var startdate =
          originalPlan?.organization[0]?.payment_transactions?.[0].created_at;
        var endDate =
          originalPlan?.organization[0]?.payment_transactions?.[0]
            .next_invoice_due;
        const diffMonth = new Date(
          new Date(endDate) - new Date(startdate)
        ).getMonth();
        diffMonth === 6 ? setsixmonth(true) : setsixmonth(false);
      }
      if (dataPaymentDue.payment_due.length > 0) {
        isPending = dataPaymentDue.payment_due.findIndex(
          (v) => v.status === "Pending"
        );
        setPaymentTransaction(dataPaymentDue.payment_transaction);
        setPaymentDue(dataPaymentDue.payment_due);
        if(dataPaymentDue.payment_due.length === 1){
          if(dataPaymentDue.payment_due[0]?.invoice_num?.is_trial){
            setPaymentDue([])
          }
        }
      }
      setSubData(subscriptionData);
      setSubscriptionData(
        subscriptionData?.organization[0]?.payment_transactions
      );
      setPlanType(subscriptionData.organization[0]?.plan_type?.name);
      setPlantypeId(subscriptionData.organization[0]?.plan_type?.plan_id);
      setPlanData(subscriptionData.organization[0]?.plan_type);
      setRetailerLimit(
        subscriptionData.organization[0]?.organization_user_aggregate?.aggregate
          .count || 0
      );
      setPending(isPending);
      if (isPending === -1) {
        setDueDate(
          subscriptionData.organization[0]?.payment_transactions[0]
            ?.next_invoice_due
        );
      } else {
        setDueDate(subscriptionData.organization[0]?.payment_dues[0]?.due_date);
      }
      setPlanPrice(subscriptionData.organization[0]?.plan_type?.price);
      // setNextInvoiceDue(subscriptionData.payment_transaction[0].n)
    }
  }, [subscriptionData, dataPaymentDue, originalPlan]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const downloadInvoice = async (payId) => {
    redirect(`/payment-details/${window.btoa(payId)}`);
  };

  const displayRazorpay = async (due_id, planAmount) => {
    const currentPeriodDate =
      subscriptionData?.organization[0]?.payment_transactions[0]
        ?.next_invoice_due;
    const endDate = moment(new Date(currentPeriodDate), "DD-MM-YYYY").add(
      1,
      "month"
    );
    var subEndDate = moment(endDate).format("MM/DD/YYYY");
    if (subscriptionData?.organization[0]?.plan_type) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        setLoading(false);
        return;
      }
      // creating a new subscription
      const result = await INSERT_ORDER({
        variables: {
          amount: planAmount.toString(),
        },
      });

      if (!result) {
        alert("Server error. Are you online?");
        setLoading(false);
        return;
      }

      setLoading(false);
      // Getting the order details back
      if (!result.errors) {
        let options = {};
        const { id, amount, currency } = result.data.create_order.returning;
        options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: currency,
          order_id: id,
          name: subscriptionData?.organization[0]?.name,
          description:
            subscriptionData?.organization[0]?.plan_type?.plan_description,
          // customer_id: "cust_IPjGXtzFyUYkLD",
          prefill: {
            name: userDetail.name,
            email: userDetail.email,
            contact: userDetail.phone,
          },
          notes: {
            org_name: subscriptionData?.organization[0]?.name,
            plan: subscriptionData?.organization[0]?.plan_type?.plan_id,
            email: subscriptionData?.organization[0]?.email,
          },
          save: 1,
          color: "#FF3834",
          handler: async function (response) {
            setLoading(true);
            const isSuccess = await CHECK_PAYMENT_STATUS({
              variables: {
                verify_id: id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            });
            if (
              !isSuccess.errors &&
              isSuccess.data.verify_payment.status === "OK"
            ) {
              const currentPeriodDate =
                subscriptionData?.organization[0]?.payment_dues[0]?.due_date;
              const endDate = moment(
                new Date(currentPeriodDate),
                "DD-MM-YYYY"
              ).add(0, "day");
              var subEndDate = moment(endDate).format("MM/DD/YYYY");
              const isInsertSuccess = await PAYMENT_CAPTURE({
                variables: {
                  payment_id: response.razorpay_payment_id,
                  org_id: subscriptionData?.organization[0]?.id,
                  due_id: due_id,
                  payment_end: subEndDate,
                },
              });
              if (
                !isInsertSuccess.errors &&
                isInsertSuccess.data.capturePreviousPayment.status === "OK"
              ) {
                setLoading(false);
                // setShowSuccessModal(true);
                setOpen(true);
              } else {
                setLoading(false);
                alert(
                  "Some issue occured while adding capturing payment, Please contact administrator!"
                );
              }
            } else {
              setLoading(false);
              alert("Payment verification failed!");
            }
          },
        };
        // }
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          alert("Payment Failed!!");
        });
        paymentObject.open();
      } else {
        alert(
          "Some issue occured while creating order for the organization, Please contact support!"
        );
      }
    } else {
      alert("User is not subscribed with supply spring!");
    }
  };

  if (subscriptionDataLoading && paymentLoading) {
    <span>Loading...</span>;
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "0",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };
  return (
    <div class="dashboard">
      <Grid container spacing={3}>
        <Grid item md={3} sm={6} xs={12}>
          <Paper className="subscriptionCards">
            <h1>Plan Type</h1>
            <span style={{ paddingBottom: "20px" }}>{planType || ""}</span>
            <UpgradePlan
              plantypeId={plantypeId}
              planType={planType}
              planDetail={plan}
              orgid={orgid}
              pending={pending}
              subData={subData}
              userDetail={userDetail}
              usersLimit={usersLimit}
              issixmonth={issixmonth}
            />
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper className="subscriptionCards">
            <h1>Package Price</h1>
            <span>
              ₹{" "}
              {planPrice ? `${planPrice.toLocaleString()} (+ 18% Extra )` : ""}
            </span>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper className="subscriptionCards">
            <h1>Retailers</h1>
            <span>{retailerLimit || "0"}</span>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper className="subscriptionCards">
            <h1>Next Invoice date</h1>
            <span>
              {dueDate
                ? moment(dueDate).add(1, "day").format("DD - MMMM - YYYY")
                : ""}
            </span>
          </Paper>
        </Grid>
      </Grid>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {paymentDueData?.length ? (
          <table
            style={{ marginTop: "25px" }}
            width="70%"
            border="0"
            cellSpacing="0"
            className="MuiPaper-root"
          >
            <thead
              bgcolor="#4a5568"
              color="white"
              align="center"
              valign="bottom"
              char="M"
            >
              <tr>
                <th
                  color="white"
                  className="MuiTableCell-sizeSmall MuiTableCell-head"
                  style={{ color: "white", fontWeight: 400 }}
                >
                  Invoice Number
                </th>
                <th
                  color="white"
                  className="MuiTableCell-sizeSmall MuiTableCell-head"
                  style={{ color: "white", fontWeight: 400 }}
                >
                  Invoice Date
                </th>
                <th
                  color="white"
                  className="MuiTableCell-sizeSmall MuiTableCell-head"
                  style={{ color: "white", fontWeight: 400 }}
                >
                  Period
                </th>
                <th
                  color="white"
                  className="MuiTableCell-sizeSmall MuiTableCell-head"
                  style={{ color: "white", fontWeight: 400 }}
                >
                  Amount
                </th>
                <th
                  color="white"
                  className="MuiTableCell-sizeSmall MuiTableCell-head"
                  style={{ color: "white", fontWeight: 400 }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody border="1" align="center">
              {/* {!loading && */}
              {paymentDueData?.map((data, index) => {
                return (
                // (data?.invoice_num ?
                  <tr>
                    <td>
                      {data.status === "Active" && data?.invoice_num
                        ? data?.invoice_num?.invoice_num
                        : "-"}
                    </td>
                    <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                      {data.status === "Active" && data?.updated_at
                        ? moment(data?.updated_at).format("DD-MMM-YYYY")
                        : "-"}
                    </td>
                    <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                      {moment(data.start_date).format("DD-MMM")} -{" "}
                      {moment(data.due_date).format("DD-MMM")}
                    </td>

                    <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                      ₹{" "}
                      {data?.amount
                        ? `${(data.amount / 100).toLocaleString()}`
                        : "0"}
                    </td>
                    <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                      {data.status === "Active" && data.amount > 0? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<CloudDownloadIcon />}
                          onClick={() => downloadInvoice(data.pay_id)}
                        >
                          Download
                        </Button>
                      ) :  ( data.status !== "Active" && data.amount > 0 ? (
                        <Button
                          disabled={userRole === "admin"}
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<PaymentIcon />}
                          onClick={() =>
                            displayRazorpay(data.due_id, data.amount)
                          }
                        >
                          Pay Now
                        </Button> ) : (<Button  variant="contained"
                          color="primary"
                          size="large">Trial</Button>)
                      )}
                    </td>
                  </tr> 
                  // : <></> )
                );
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      {userRole === "orgadmin" && (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Button
            sx={{ mr: "2" }}
            color="secondary"
            variant="outlined"
            onClick={() => setCancelPlanModal(true)}
          >
            Cancel Membership
          </Button>
        </div>
      )}

      <Modal
        open={cancelPlanModal}
        onClose={() => setCancelPlanModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="closePopup">
            <CancelIcon
              className=""
              onClick={() => setCancelPlanModal(false)}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to cancel the account?
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Button
              onClick={handleCancelPlan}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Payment Successful"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-center">
              <b>Thank you</b>
            </p>
            your payment was successful. Check your email for your receipt.
            <br></br>
            <br></br>
            <b style={{ paddingRight: "25px" }}>Time</b>
            {moment().format("MMM DD, YYYY hh:mm A")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Subscription;
