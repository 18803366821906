import * as React from "react";
import { Route } from "react-router-dom";
import RetailerReport from "./retailerReport";
import Subscription from "./Subscriptions/Subscription";
import ChangePassword from "./ChangePassword";
import ChangeOrderStatus from "./ChangeOrderStatus";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import PaymentDetails from "./PaymentDetails";
import SubscriptionAdmin from "./Subscriptions/SubscriptionAdmin";
// import { CoAdminEdit } from "./User/CoAdmin/CoAdminEdit";

const userRole = localStorage.getItem("ssp_admin_role");
const CustomRoutes = [
  <Route exact path="/reports/retailer" component={RetailerReport} />,
  <Route
    exact
    path="/subscription-details"
    component={userRole === "admin" ? SubscriptionAdmin : Subscription}
  />,
  <Route exact path="/change-password" component={ChangePassword} />,
  <Route exact path="/payment-details/:id" component={PaymentDetails} />,
  <Route exact path="/order-status/:id" component={ChangeOrderStatus} />,
  // <Route exact path="/co-user/:id" component={CoAdminEdit} />,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
  <Route exact path="/reset-password" component={ResetPassword} noLayout />,
];

export default CustomRoutes;
