import AddBox from "@material-ui/icons/AddBox";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, Button, Input, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDataProvider } from "react-admin";
import { INSERT_PRODUCT } from "../../GraphQL/mutation";
import { ExcelRenderer } from "react-excel-renderer";
import {
  GET_AGREEGATE,
  GET_PRODUCTS_LIST,
  GET_CATEGORY_LIST,
  GET_BRAND_LIST,
} from "../../GraphQL/queries";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 5,
  p: 4,
};

export const ImportFileProduct = ({ isFile, isFileImported, type }) => {
  const [open, setOpen] = React.useState(false);
  const [fileData, setFileData] = React.useState(null);
  const [filePath, setFilePath] = React.useState(null);
  const handleClose = () => {
    setInsertProduct(null);
    setErrorArr(null);
    setFileData(null);
    setOpen(false);
  };
  const dataProvider = useDataProvider();
  const [organizationId, setOrganizationId] = React.useState(null);
  const [orgCode, setOrgCode] = React.useState(null);
  const [insertProductData, setInsertProduct] = React.useState(null);
  const [errorArr, setErrorArr] = React.useState(null);

  const totalUserLimit = React.useCallback(async () => {
    const { data: userLimit } = await dataProvider.getList("organization", {});
    setOrganizationId(userLimit[0].id);
    setOrgCode(userLimit[0].org_code);
  }, [dataProvider]);
  const { data: category_list } = useQuery(
    GET_CATEGORY_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: product_list } = useQuery(
    GET_PRODUCTS_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: brand_list } = useQuery(
    GET_BRAND_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: total } = useQuery(GET_AGREEGATE, {
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {}, [filePath, insertProductData, errorArr, fileData]);

  React.useEffect(() => {
    if (!organizationId) {
      totalUserLimit();
    }
  }, [organizationId]);

  React.useEffect(() => {
    const { REACT_APP_NODE_ENV, REACT_APP_ENDPOINT } = process.env;
    if (organizationId && brand_list && category_list) {
      const REACT_API_URL = REACT_APP_ENDPOINT;
      axios({
        url: `${REACT_API_URL}/rest/downloadFileProduct`, //your url
        withCredentials: false,
        data: {
          org_id: organizationId,
          brands: brand_list.brand,
          category: category_list.organization_category,
        },
        method: "POST",
        responseType: "blob",
      })
        .then((response) => {
          var data = [response.data];
          var blob = new Blob(data, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          setFilePath(url);
          isFileImported(true);
        })
        .catch((response) => {
          isFileImported(true);
          // setReload(true)
          // const link = doc
        });
    }
  }, [organizationId, category_list, brand_list, product_list]);

  const [INSERT_PRODUCT_MUTATION, { data: productUpdate }] =
    useMutation(INSERT_PRODUCT);

  const handleCapture = ({ target }) => {
    setInsertProduct(null);
    setErrorArr(null);
    setFileData(target.files[0]);
    uploadPackData(target.files[0]);
  };

  const downloadTemplate = (url) => {
    const link = document.createElement("a");
    link.href = filePath;
    const fileName = `Product_${new Date().getTime()}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const validateImportFile = (fields) => {
    if (
      fields.length &&
      fields[0].toString().trim().toLowerCase() === "product name" &&
      fields[1].trim().toString().toLowerCase() === "description" &&
      fields[2].toString().trim().toLowerCase() === "brand" &&
      fields[3].toString().trim().toLowerCase() === "category"
    ) {
      return true;
    }
    return false;
  };

  const uploadFile = async () => {
    try {
      const isInsertSuccessful = await INSERT_PRODUCT_MUTATION({
        variables: {
          objects: insertProductData,
        },
      });
      if (!isInsertSuccessful.errors) {
        alert("Products Data Saved");
        window.location.reload();
      } else {
        alert(
          "We couldn't save the product details. Please verify the data. If issue persists, contact support!"
        );
      }
    } catch (error) {
      alert(
        "We couldn't save the product details. Please verify the data. If issue persists, contact support!"
      );
    }
  };

  const uploadPackData = async (fileData) => {
    if (!fileData) {
      alert("Please select file to upload!");
      return;
    }
    const file = fileData;
    let insertProducts = [];
    let errorArr = [];
    const brand = brand_list.brand;
    const category = category_list.organization_category;
    let skuLatest = total?.product[0]?.product_code;
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const isValid = await validateImportFile(resp?.rows[1] || []);
        if (!isValid) {
          alert("Invalid file selected to import pack data!");
          return "";
        }
      }
      resp.rows.map((data, index) => {
        if (index > 3 && data.length) {
          const obj = {};
          const brandIndex = brand.findIndex((itm) => itm.name === data[2]);
          const categoryIndex = category.findIndex(
            (itm) => itm.category.name === data[3]
          );
          if (brandIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Brand!";
            errorObj.index = index + 1;
            errorObj.field = "Brand";
            errorArr.push(errorObj);
          }
          if (categoryIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Category!";
            errorObj.index = index + 1;
            errorObj.field = "Category";
            errorArr.push(errorObj);
          }
          if (!data[0]) {
            const errorObj = {};
            errorObj.description = "Product Name is manadtory!";
            errorObj.index = index + 1;
            errorObj.field = "Product Name";
            errorArr.push(errorObj);
          }

          obj.brand_id = brand[brandIndex]?.id || null;
          obj.name = data[0]?.toString();
          obj.description = data[1]?.toString();
          obj.type = "first";
          obj.logo = "";
          obj.product_categories = {
            data: [{ category_id: category[categoryIndex]?.category?.id }],
          };
          if (!skuLatest) {
            obj.product_code = orgCode + "-PC0001";
          } else
            obj.product_code =
              orgCode +
              "-PC" +
              (Number(String(skuLatest).substr(10)) + 1)
                .toString()
                .padStart(4, "0");
          skuLatest = obj.product_code;
          const prodIndex = insertProducts.findIndex(
            (itm) =>
              data[0] &&
              itm.name &&
              itm.name?.toLowerCase() === data[0]?.toLowerCase()
          );
          const prodIndexExist = product_list?.product?.findIndex(
            (itm) =>
              obj.name &&
              itm.name &&
              itm.name?.toLowerCase() === obj.name?.toLowerCase()
          );
          if (prodIndex >= 0 || prodIndexExist >= 0) {
            const errorObj = {};
            errorObj.description = "Product already exist!";
            errorObj.index = index + 1;
            errorObj.field = "Duplicate Record";
            errorArr.push(errorObj);
          }
          insertProducts.push(obj);
        }
      });
      try {
        if (errorArr.length > 0) {
          setErrorArr(errorArr);
          return "";
        }
        if (errorArr.length === 0 && insertProducts.length === 0) {
          alert(
            "No valid data exist in the file, Please enter data and try again!"
          );
          return "";
        }
        setInsertProduct(insertProducts);
      } catch (error) {
        alert(
          "We couldn't save the product details. Please verify the data. If issue persists, contact support!"
        );
      }
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const userRole = localStorage.getItem("ssp_admin_role");

  return (
    <div>
      {userRole === "admin" ? (
        <></>
      ) : (
        <div>
          {!isFile ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<AddBox />}
              variant="outlined"
            >
              Import
            </LoadingButton>
          ) : (
            <Button
              startIcon={<AddBox />}
              onClick={() => setOpen(true)}
              variant="text"
              color="primary"
              className="importText"
            >
              Import
            </Button>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="closePopup">
                <CancelIcon className="" onClick={handleClose} />
              </div>
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                className="importbody"
              >
                <div className="steplist">
                  <h3>STEP 1</h3>
                  <div className="stepnotes">
                    Download this excelsheet and add your back data in that
                    sheet.
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    onClick={downloadTemplate}
                    className="btnwidth"
                  >
                    Download Template
                  </Button>
                </div>
                <label
                  htmlFor="contained-button-file"
                  onChange={handleCapture}
                  className="mt-0 w-100"
                >
                  <Input
                    accept=".xls, .xlsx"
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                  />
                  <div className="steplist">
                    <h3>STEP 2</h3>
                    <div className="stepnotes">
                      Once you have updated sample sheet with your data, choose
                      that file to upload
                    </div>
                    <Button
                      color="success"
                      variant="contained"
                      component="span"
                      className="btnwidth"
                    >
                      Select File
                    </Button>
                  </div>
                  <div className="filename">{fileData?.name}</div>
                </label>
                {errorArr?.length ? (
                  <div>
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Row #</TableCell>
                              <TableCell align="left">Field</TableCell>
                              <TableCell align="left">Description</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {errorArr
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => (
                                <TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.index}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.field}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.description}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 25, 100]}
                        component="div"
                        count={errorArr.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </div>
                ) : (
                  <></>
                )}
                <div className="steplist">
                  <h3>STEP 3</h3>
                  <div className="stepnotes">
                    Once you have selected apprioriate file, if there is no
                    error in the sheet, this button will be enabled and you will
                    be able to upload your data.
                  </div>
                  <Button
                    className="btnwidth"
                    color="secondary"
                    variant="contained"
                    component="span"
                    onClick={uploadFile}
                    disabled={insertProductData ? false : true}
                  >
                    Submit
                  </Button>
                </div>
              </Stack>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
};
