import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetList,
  useRedirect,
  TopToolbar,
  useNotify,
  downloadCSV,
  CreateButton,
  ExportButton,
  number,
  minValue,
  required,
  minLength,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
} from "react-admin";
import { useFormState } from "react-final-form";
import uuid from "uuid";
import { EditableImage } from "./EditableImage";
import {
  GET_AGREEGATE,
  GET_ORG_ID,
  GET_PRODUCT_DETAILS_BY_ID,
  PRODUCT_IMPORT,
} from "./GraphQL/queries";
import Empty from "./Assets/Images/empty.png";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { getSignedURL } from "./rest/api";
import axios from "axios";
import {
  UPDATE_PRODUCT_BY_ID,
  UPDATE_PRODUCT_CATEGORY,
} from "./GraphQL/mutation";
import { ImportFileProduct } from "./Components/Imports/ImportFileProduct";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { returnError } from "./enum/error";

const ProductTitle = ({ record }) => {
  return <span>Product {record ? `"${record.name}"` : ""}</span>;
};

const validatNumber = [number(), minValue(0)];
const validateText = [required(), minLength(1)];
const ProductFilter = (props) => {
  const userRole = localStorage.getItem("ssp_admin_role");

  return (
    <Filter {...props}>
      <SearchInput
        placeholder={`Search by name & code`}
        source="name@_ilike,product_code@_ilike"
        alwaysOn
      />
      <ReferenceInput
        perPage={500}
        source="brand_id"
        reference="brand"
        label="Select Brand"
      >
        <AutocompleteInput source="brand_id" />
      </ReferenceInput>
      {userRole === "admin" && (
        <ReferenceInput
          source="org_id"
          reference="organization"
          label="Organizations"
        >
          <AutocompleteInput source="org_id" />
        </ReferenceInput>
      )}
    </Filter>
  );
};
const ListActions = ({ props, basePath }) => {
  const { data } = useQuery(PRODUCT_IMPORT, {
    fetchPolicy: "network-only",
  });
  const [isFile, setIsFile] = React.useState(false);
  const ExportUsers = () => {
    if (data && data?.product?.length > 0) {
      const productsForExport = data?.product?.map((prod, index) => {
        // add a field from an embedded resource
        let products = {};
        products.logo = prod?.logo;
        products.product_code = prod?.product_code;
        products.name = prod?.name;
        products.brand = prod?.brand?.name;
        products.category = prod?.product_categories[0]?.category.name;
        return products;
      });

      const csv = convertToCSV({
        data: productsForExport,
        // select and order fields in the export
        fields: ["logo", "product_code", "name", "brand", "category"],
      });
      downloadCSV(csv, "Products"); // download as 'posts.csv` file
    }
  };
  const userRole = localStorage.getItem("ssp_admin_role");
  return (
    <TopToolbar {...props}>
      <ProductFilter context="button" />
      {userRole === "admin" ? <></> : <CreateButton basePath={basePath} />}
      <ImportFileProduct
        isFile={isFile}
        isFileImported={setIsFile}
        type={basePath}
      />
      {data && <ExportButton exporter={() => ExportUsers()} />}
    </TopToolbar>
  );
};

export const ProductList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Product List</h3>
    <List
      bulkActionButtons={false}
      filters={<ProductFilter />}
      actions={<ListActions />}
      {...props}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid>
        <ImageField
          source="logo"
          {...props}
          emptyText={<img src={Empty} alt="logo" />}
          sortable={false}
        />
        <TextField source="product_code" />
        <TextField source="name" label="Product Name" />
        <ReferenceField
          link={false}
          source="brand_id"
          reference="brand"
          label="Brand"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="updated_at" label=" Last Modified Date " />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle="Confirm"
          onSuccess={() => {
            notify("Product deleted successfully!");
            window.location.reload();
          }}
          onFailure={(error) => {
            return notify(returnError(error.message), "warning");
          }}
        />
        {/* <DeleteButton undoable={false} /> */}
      </Datagrid>
    </List>
    </div>
  );
};

const EditProduct = ({
  name,
  logo,
  brand_id,
  description,
  category_id,
  id,
  part_code,
}) => {
  const [UPDATE_PRODUCT, { data: productUpdate }] =
    useMutation(UPDATE_PRODUCT_BY_ID);
  const [UPDATE_CATEGORY, { data: categoryUpdate }] = useMutation(
    UPDATE_PRODUCT_CATEGORY
  );
  const notify = useNotify();
  const redirect = useRedirect();
  // React.useEffect(() => {
  //   if (productUpdate && categoryUpdate) {
  //     notify("Element updated successfully");
  //     // redirect("/product");
  //   }
  // }, [productUpdate]);
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<SaveIcon />}
        onClick={async () => {
          if (!name || !brand_id) {
            notify("Please enter manadtory fields!");
            return;
          }
          try {
            let filename;
            let ext;

            if (logo && typeof logo === "object") {
              filename = uuid();
              ext = logo.type.split("/").pop();
              const signedURL = await getSignedURL(ext, filename);
              var options = {
                headers: {
                  "Content-Type": logo.type,
                },
              };
              await axios.put(signedURL.data, logo, options).catch((e) => {
                console.log("error", e);
              });
            }

            UPDATE_PRODUCT({
              variables: {
                name: name,
                logo: logo
                  ? typeof logo === "object"
                    ? `https://assets.supplyspring.in/${filename}.${ext}`
                    : logo
                  : null,
                id,
                brand_id,
                description,
                part_code,
              },
            })
              .then((r) => {
                UPDATE_CATEGORY({
                  variables: {
                    category_id,
                    product_id: id,
                  },
                })
                  .then((res) => {
                    notify("Product details saved successfully!");
                    redirect("/product");
                  })
                  .catch((e) => {
                    notify(returnError(e.message), "warning");
                  });
              })
              .catch((errors) => {
                notify(returnError(errors.message), "warning");
              });
          } catch (error) {
            console.log(`error`, error);
          }
        }}
      >
        Save
      </Button>
    </TopToolbar>
  );
};

export const ProductEdit = (props) => {
  const [UPDATE_PRODUCT, { data: productUpdate }] =
    useMutation(UPDATE_PRODUCT_BY_ID);
  const [UPDATE_CATEGORY, { data: categoryUpdate }] = useMutation(
    UPDATE_PRODUCT_CATEGORY
  );
  const notify = useNotify();
  const redirect = useRedirect();
  const { data: productData, loading: productLoading } = useQuery(
    GET_PRODUCT_DETAILS_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );
  const [name, setName] = React.useState(null);
  const [brand_id, setbrand_id] = React.useState(null);
  const [description, setdescription] = React.useState(null);
  const [logo, setlogo] = React.useState(null);
  const [part_code, setpart_code] = React.useState(null);
  const [category_id, setcategory_id] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (productData && !productLoading) {
      setName(productData.product_by_pk?.name);
      setbrand_id(productData.product_by_pk?.brand_id);
      setdescription(productData.product_by_pk?.description);
      setlogo(productData.product_by_pk?.logo);
      setpart_code(productData.product_by_pk?.part_code);
      setcategory_id(
        productData.product_by_pk.product_categories[0]?.category.id
      );
      setIsLoading(false);
    }
  }, [productData, productLoading]);

  if (productLoading || isLoading) {
    return <span>Loading...</span>;
  }

  const handleProductEdit = async () => {
    if (!name || !brand_id) {
      notify("Please enter manadtory fields!");
      return;
    }
    try {
      let filename;
      let ext;

      if (logo && typeof logo === "object") {
        filename = uuid();
        ext = logo.type.split("/").pop();
        const signedURL = await getSignedURL(ext, filename);
        var options = {
          headers: {
            "Content-Type": logo.type,
          },
        };
        await axios.put(signedURL.data, logo, options).catch((e) => {
          console.log("error", e);
        });
      }

      UPDATE_PRODUCT({
        variables: {
          name: name,
          logo: logo
            ? typeof logo === "object"
              ? `https://assets.supplyspring.in/${filename}.${ext}`
              : logo
            : null,
          id: props.id,
          brand_id,
          description,
          part_code,
        },
      })
        .then((r) => {
          UPDATE_CATEGORY({
            variables: {
              category_id,
              product_id: props.id,
            },
          })
            .then((res) => {
              notify("Product details saved successfully!");
              redirect("/product");
            })
            .catch((e) => {
              notify(returnError(e.message), "warning");
            });
        })
        .catch((errors) => {
          notify(returnError(errors.message), "warning");
        });
    } catch (error) {
      console.log(`error`, error);
    }
  };

  return (
    <Edit title={<ProductTitle />} {...props} undoable={false}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleProductEdit}
              disabled={!name}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          onChange={(e) => setName(e.target.value.trim())}
          source="name"
          validate={validateText}
        />
        <ReferenceInput
          perPage={500}
          onChange={(e) => setbrand_id(e.target.value.trim())}
          source="brand_id"
          reference="brand"
          label="Select Brand"
          validate={validateText}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          onChange={(e) => setdescription(e.target.value.trim())}
          source="description"
          label="Product Description"
          multiline={true}
        />
        <ReferenceInput
          source="org_id"
          reference="organization"
          label="Select Organization"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={500}
          onChange={(e) => setcategory_id(e.target.value.trim())}
          source="product_categories.data[0].category_id"
          reference="category"
          defaultValue={category_id}
          label="Primary Category"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)
        </small>
        <input
          type="file"
          source="logo"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setlogo(e.target.files[0]);
          }}
        />
        {typeof logo === "string" && (
          <img className="logoImg" src={logo} alt="logo" />
        )}

        <TextInput source="part_code" style={{ display: "none" }} />
      </SimpleForm>
    </Edit>
  );
};

export const ProductCreate = (props) => {
  const { data } = useGetList(
    "category",
    {},
    { field: "id", order: "DESC" },
    {}
  );
  const OrgCategoryInput = (props) => {
    const { values } = useFormState();
    if (values.org_id) {
      const cat_options = Object.keys(data)
        .map((choice) => {
          if (
            data[choice].organization_categories.findIndex(
              (org_cat) => org_cat.org_id === values.org_id
            ) > -1 &&
            data[choice].type.toLowerCase() === props.type
          ) {
            return {
              id: data[choice].id,
              name: data[choice].name,
            };
          } else {
            return null;
          }
        })
        .filter((item) => item && item !== null);

      return (
        <SelectInput
          key={values.org_id}
          {...props}
          optionText="name"
          choices={cat_options}
        />
      );
    } else {
      return null;
    }
  };

  const transform = (data) => {
    console.log('in transofrm ==>', data);
    delete data.org_id;
    delete data.id;
    return {
      ...data,
      name: !data.name.trim() ? null : data.name.trim(),
    };
  };

  const { data: total } = useQuery(GET_AGREEGATE, {
    fetchPolicy: "network-only",
  });
  let { data: orgCode } = useQuery(GET_ORG_ID, { fetchPolicy: "network-only" });

  let product_code;
  if (total && orgCode) {
    if (
      total?.product[0]?.product_code === null ||
      total?.product[0]?.product_code === undefined ||
      total.product[0].product_code === NaN
    ) {
      product_code = orgCode.organization[0].org_code + "-PC0001";
    } else
      product_code =
        orgCode.organization[0].org_code +
        "-PC" +
        (Number(String(total?.product[0]?.product_code).substr(10)) + 1)
          .toString()
          .padStart(4, "0");
  }
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Create
      {...props}
      transform={transform}
      onSuccess={() => {
        notify("Product details added successfully!");
        redirect("/product");
      }}
      onFailure={(error) => notify(returnError(error.message), "warning")}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton/>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput source="name" label="Product Name" validate={validateText} />
        <TextInput
          source="description"
          label="Product Description"
          multiline={true}
        />
        <ReferenceInput
          perPage={500}
          source="brand_id"
          reference="brand"
          label="Select Brand"
          validate={validateText}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          source="org_id"
          reference="organization"
          label="Select Organization"
          defaultValue={orgCode?.organization[0]?.id}
        >
          <SelectInput required optionText="name" select="0" />
        </ReferenceInput>

        {/* <OrgCategoryInput
          source="product_categories.data[0].category_id"
          type="first"
          label="Primary Category"
        /> */}
        <ReferenceInput
          // onChange={(e) => setcategory_id(e.trim())}
          source="product_categories.data[0].category_id"
          reference="category"
          label="Primary Category"
          perPage={500}
          sort={{ field: "name", order: "ASC" }}
           filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput required optionText="name" />
        </ReferenceInput>
        <EditableImage
          source="logo"
          {...props}
          label="Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)"
        />
        <TextInput
          source="product_code"
          defaultValue={product_code}
          style={{ display: "none" }}
        ></TextInput>
        <TextInput
          source="id"
          defaultValue={React.useMemo(() => uuid(), [])}
          disabled
          style={{ display: "none" }}
        />
        <TextInput required source="part_code" style={{ display: "none" }} />
        <SelectInput
          source="type"
          label="Category Type"
          required
          choices={[
            { id: "first", name: "Primary" },
            { id: "second", name: "Secondary" },
          ]}
          defaultValue="first"
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Create>
  );
};
