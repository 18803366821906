// in src/MyLayout.js
import TreeMenu from "@bb-tech/ra-treemenu";
import * as React from "react";
import { Layout, useDataProvider,    AppBar,
  Menu,
  Notification,
  Sidebar,
  setSidebarVisibility,
  ComponentPropType, } from "react-admin";
import MyAppBar from "./MyAppBar";
import {
  AccountBalance,
  CardTravel,
  Category,
  Class,
  Favorite,
  Flare,
  GridOn,
  Group,
  GroupWork,
  Inbox,
  LocalOffer,
  PermIdentity,
  Person,
  QrCode,
  Settings,
  SupervisedUserCircle,
  Assessment,
  Loyalty,
  Label,
  Archive,
  Dashboard,
  ShoppingBasket
} from "@material-ui/icons";
import { Link, NavLink } from 'react-router-dom'
import MetisMenu from '@metismenu/react';
import 'metismenujs/dist/metismenujs.css';
import {
  GET_PAYMENT_DUE,
} from "./GraphQL/queries";
import { useMutation, useQuery } from "@apollo/client";


const MyLayout = (props) => {
  return <Layout {...props} appBar={MyAppBar} menu={CustomSideMenu} />;
};

const CustomSideMenu = (props) => {
console.log(window.location.pathname)
  const userRole = localStorage.getItem("ssp_admin_role");
  const dataProvider = useDataProvider();
  const [activeOrg, setActiveOrg] = React.useState(true);
  const [organizationId, setOrganizationId] = React.useState(null);
  const [pending, setPending] = React.useState(-1);

  React.useEffect(() => {
    dataProvider
      .getList("organization", {})
      .then(({ data }) => {
        setActiveOrg(data[0]?.is_active);
        if (userRole === "orgadmin") {
          setOrganizationId(data[0].id);
        }
      })
      .catch((error) => {
        console.log(`error`, error);
      });
  }, [dataProvider]);
  const { data: dataPaymentDue, loading: paymentLoading } = useQuery(
    GET_PAYMENT_DUE,
    {
      variables: {
        org_id: organizationId,
        gt: undefined,
        lt: undefined,
      },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
  }, [pending])

  React.useEffect(() => {
    let isPending = -1, filteredPending = [];
    if (dataPaymentDue?.payment_due?.length > 0 && userRole !== 'admin') {
      filteredPending = dataPaymentDue.payment_due.filter(
        (v) => v.status === "Pending"
      );
      console.log(isPending)
      if (filteredPending?.length) {
        let objMax = filteredPending.reduce((max, curren) => (max.due_date < curren.due_date) ? max : curren);
        console.log('obj Max', objMax)
        const date1 = new Date(objMax.due_date);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        console.log(diffDays)
        if (diffDays > 15 ){
          setPending(filteredPending?.length);
        }
      } else {
        setPending(-1)
      }
    }
  }, [dataPaymentDue])
  return (
    <React.Fragment>

    <MetisMenu toggle={true}>
       {(userRole === "admin" || userRole === "orgadmin") &&  <li><NavLink to="/" exact><div className="menu-side"> <Dashboard name="inbox" /><span>Dashboard</span></div></NavLink></li>}
           {(userRole === 'orgadmin' && (pending < 0)) ? <li>
              <Link to="#" className="has-arrow"> <div className="menu-side"> <Group name="inbox" /><span>User Management</span></div></Link>
              <ul>
                <li><NavLink to="/organization"><div className="menu-side"> <SupervisedUserCircle name="inbox" /><span>My Organization</span></div></NavLink></li>
                <li><NavLink to="/user"><div className="menu-side"> <Person name="inbox" /><span>Retailer</span></div></NavLink></li>
              </ul>
            </li> : <></>}
            {(userRole === 'admin' && pending < 0) ? <li>
              <NavLink to="/organization" > <div className="menu-side"> <Group name="inbox" /><span>User Management</span></div></NavLink>
            </li> : <></>}
            {userRole === "orgadmin" && pending < 0 && 
             <li>
              <Link to="#" className="has-arrow"> <div className="menu-side"> <ShoppingBasket name="inbox" /><span>Product Management</span></div></Link>
              <ul>
                <li><NavLink to="/brand"><div className="menu-side"> <LocalOffer name="inbox" /><span>Brands</span></div></NavLink></li>
                <li><NavLink to="/category"><div className="menu-side"> <Category name="inbox" /><span>Categories</span></div></NavLink></li>
                <li><NavLink to="/section"> <div className="menu-side"> <GroupWork name="inbox" /><span>Section</span></div></NavLink></li>
                <li><NavLink to="/product"><div className="menu-side"> <CardTravel name="inbox" /><span>Product</span></div></NavLink></li>
                <li><NavLink to="/pack"><div className="menu-side"> <Class name="inbox" /><span>Packs</span></div></NavLink></li>
              </ul>
            </li>}
            <li className={(userRole === "billing" && pending < 0) && "mm-active"}>
             {pending < 0 ?  <Link to="#" className="has-arrow"><div className="menu-side"> <Archive name="inbox" /><span>Order Management</span></div></Link> : <></>}
              <ul className={userRole === "billing" && "mm-show"}>
                <li><NavLink to="/order"><div className="menu-side"> <Inbox name="inbox" /><span>Orders</span></div></NavLink></li>
                </ul>
            </li>
            {((userRole === "admin" || userRole === "orgadmin") && pending < 0) && 
            <li>
              <Link to="#" className="has-arrow"><div className="menu-side"> <Settings name="inbox" /><span>Settings</span></div></Link>
              <ul>
                <li><NavLink to="/class"><div className="menu-side"> <LocalOffer name="inbox" /><span>Discount Type</span></div></NavLink></li>
                <li><NavLink to="/payment_type"><div className="menu-side"> <AccountBalance name="inbox" /><span>Payments</span></div></NavLink></li>
                <li><NavLink to="/measurement"><div className="menu-side"> <GridOn name="inbox" /><span>Measurements</span></div></NavLink></li>
                <li><NavLink to="/banners"><div className="menu-side"> <Flare name="inbox" /><span>Schemes</span></div></NavLink></li>
              </ul>
            </li>}
            {(userRole === "orgadmin" || userRole === 'admin') && pending < 0 && 
            <li><NavLink to="/reports/retailer" exact><div className="menu-side"> <Assessment name="inbox" /><span>Retailer Report</span></div></NavLink></li>}
          {(userRole === "admin" || userRole === "orgadmin") &&   <li><NavLink to="/subscription-details" exact><div className="menu-side"> <Loyalty name="inbox" /><span>Subscriptions</span></div></NavLink></li>}
        </MetisMenu>
    </React.Fragment>
  );
};

export default MyLayout;
