import { Button } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React from "react";
import { useRedirect } from "react-admin";

const MyMenu = (props) => {
  const redirect = useRedirect();
  return (
    <Button
      onClick={() => {
        redirect("/change-password");
      }}
      startIcon={<Person />}
    >
      Change Password
    </Button>
  );
};

export default MyMenu;
