import AddBox from "@material-ui/icons/AddBox";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, Button, Input, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDataProvider } from "react-admin";
import {
  IMPORT_RETAILER,
  IMPORT_RETAILER_ORG,
  UPSERT_PACK,
  UPSERT_PACK_DISCOUNT,
} from "../../GraphQL/mutation";
import LoadingButton from "@mui/lab/LoadingButton";
import { ExcelRenderer } from "react-excel-renderer";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  GET_LATEST_PRODUCT_CODE,
  GET_PRODUCTS_LIST,
  GET_MEASUREMENT_LIST,
  GET_SECTION_LIST,
  GET_CLASS_LIST,
  GET_PACK_LIST,
  PACK_IMPORT,
  GET_IS_CUSTOM_SKU
} from "../../GraphQL/queries";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import bcrypt from "bcryptjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 5,
  p: 4,
};

export const ImportFileRetailer = ({ isFile, isFileRetailer, type }) => {
  const [open, setOpen] = React.useState(false);
  const [fileData, setFileData] = React.useState(null);
  const [filePath, setFilePath] = React.useState(null);
  const [fileDataUpdate, setFileDataUpdate] = React.useState(null);
  const [filePathUpdate, setFilePathUpdate] = React.useState(null);
  const [loaderUpdate, setLoaderUpdate] = React.useState(false);
  const handleClose = () => {
    setInsertRetailerData(null);
    setErrorArr(null);
    setFileData(null);
    setFileDataUpdate(null);
    setErrorArrUpdate(null);
    setInsertPackUpdate(null);
    setInsertPackDiscountUpdate(null);
    setOpen(false);
  };

  const dataProvider = useDataProvider();
  const [organizationId, setOrganizationId] = React.useState(null);
  const [orgCode, setOrgCode] = React.useState(null);
  const [orgName, setOrgName] = React.useState(null);
  const [productCode, setProductCode] = React.useState(null)
  const [insertRetailerData, setInsertRetailerData] = React.useState(null);
  const [insertPackDiscount, setInsertPackDiscountUpdate] =
    React.useState(null);
  const [errorArr, setErrorArr] = React.useState(null);
  const [insertPackDataUpdate, setInsertPackUpdate] = React.useState(null);
  const [errorArrUpdate, setErrorArrUpdate] = React.useState(null);
  const [packData, setPackData] = React.useState(null);
  const totalUserLimit = React.useCallback(async () => {
    const { data: userLimit } = await dataProvider.getList("organization", {});
    setOrganizationId(userLimit[0].id);
    setOrgCode(userLimit[0].org_code);
    setOrgName(userLimit[0].name)
  }, [dataProvider]);


  const { data: prodCode } = useQuery(GET_LATEST_PRODUCT_CODE,  
    { variables: { product_code: '%' + orgCode + '%' } },
    {fetchPolicy: "network-only"});

    const { data: class_list } = useQuery(
      GET_CLASS_LIST,
      {
        variables: { org_id: organizationId },
      },
      { fetchPolicy: "network-only" }
    );
  

  React.useEffect(() => {}, [
    filePath,
    filePathUpdate,
    insertRetailerData,
    errorArr,
    fileData,
    packData,
    insertPackDiscount,
    insertPackDataUpdate,
  ]);

  React.useEffect(() => {
    if (!organizationId && !orgCode && !orgName && !productCode) {
      totalUserLimit();
    }
  }, [organizationId, orgCode, orgName, productCode]);

  React.useEffect(() => {
    if(prodCode?.user?.length){
      setProductCode(prodCode?.user[0]?.product_code)
    } else {
      setProductCode(null)
    }
  }, [prodCode])


  React.useEffect(() => {
    const { REACT_APP_NODE_ENV, REACT_APP_ENDPOINT } = process.env;
    if (organizationId) {
      const REACT_API_URL = REACT_APP_ENDPOINT;
      axios({
        url: `${REACT_API_URL}/rest/downloadFileRetailer`, //your url
        withCredentials: false,
        data: {},
        method: "POST",
        responseType: "blob",
      })
        .then((response) => {
          var data = [response.data];
          var blob = new Blob(data, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          setFilePath(url);
          isFileRetailer(true);
        })
        .catch((response) => {
          isFileRetailer(true);
          // setReload(true)
          // const link = doc
        });
    }
  }, [organizationId, class_list]);


  const [IMPORT_RETAILER_MUTATION, { data: retailerData }] =
    useMutation(IMPORT_RETAILER);
  const [IMPORT_RETAILER_ORG_MUTATION, { data: retailerOrgData }] =
    useMutation(IMPORT_RETAILER_ORG);

  const handleCapture = ({ target }) => {
    setInsertRetailerData(null);
    setErrorArr(null);
    setFileData(target.files[0]);
      uploadPackData(target.files[0]);
  };

  const downloadTemplate = (url) => {
    const link = document.createElement("a");
    link.href = filePath;
    const fileName = `Retailer_${new Date().getTime()}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const validateImportFile = (fields) => {
    let isCheck = true, count = 1;
    if (
      fields.length &&
      fields[0].toString().trim().toLowerCase() === "name" &&
      fields[count].trim().toString().toLowerCase() === "phone" &&
      fields[count + 1].toString().trim().toLowerCase() === "state" &&
      fields[count + 2].toString().trim().toLowerCase() === "city" &&
      fields[count + 3].toString().trim().toLowerCase() === "pincode" &&
      fields[count + 4].toString().trim().toLowerCase() === "address" &&
      isCheck
    ) {
      return true;
    }
    return false;
  };


  const uploadFile = async () => {
    console.log('IMPORT DATA ==>', insertRetailerData)
    try {
      const isInsertSuccessful = await IMPORT_RETAILER_MUTATION({
        variables: {
          objects: insertRetailerData,
        },
      });
      if (!isInsertSuccessful.errors) {
        alert("Retailer Data Saved");
        window.location.reload();
      } else {
        alert(
          "We couldn't save the retailer details. Please verify the data. If issue persists, contact support!"
        );
      }
    } catch (error) {
      alert(
        "We couldn't save the retailer details. Please verify the data. If issue persists, contact support!"
      );
    }
  };

  const NumberWithLeadingZeroes = (n) => {
    if (n < 10) {
      return "0000" + n.toString();
    } else if (n < 100) {
      return "000" + n.toString();
    } else if (n < 1000) {
      return "00" + n.toString();
    } else if (n < 10000) {
      return "0" + n.toString();
    } else {
      return n;
    }
  };

   /*Import New pack without sku*/
   const uploadPackData = async (fileData) => {
    if (!fileData) {
      alert("Please select file to upload!");
      return;
    }
    const file = fileData;
    let insertPacks = [];
    let errorArr = [];
    const org_id = organizationId;
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const isValid = await validateImportFile(resp?.rows[1] || []);
        if (!isValid) {
          alert("Invalid file selected to import retailer data!");
          return "";
        }
      }
      const classes = class_list;
      resp.rows.map((data, index) => {
        if (index > 3 && data.length) {
          const obj = {};
          if (!data[0]) {
            const errorObj = {};
            errorObj.description = "Invalid Name!";
            errorObj.index = index + 1;
            errorObj.field = "Name";
            errorArr.push(errorObj);
          }
          if (!data[1]) {
            const errorObj = {};
            errorObj.description = "Invalid phone number!";
            errorObj.index = index + 1;
            errorObj.field = "Phone Number";
            errorArr.push(errorObj);
          }
          if (!data[2]) {
            const errorObj = {};
            errorObj.description = "Invalid State!";
            errorObj.index = index + 1;
            errorObj.field = "State";
            errorArr.push(errorObj);
          }
          if (!data[3]) {
            const errorObj = {};
            errorObj.description = "Invalid City!";
            errorObj.index = index + 1;
            errorObj.field = "City";
            errorArr.push(errorObj);
          }
          if (!data[4]) {
            const errorObj = {};
            errorObj.description = "Invalid Pincode!";
            errorObj.index = index + 1;
            errorObj.field = "Pincode";
            errorArr.push(errorObj);
          }
          if (!data[5]) {
            const errorObj = {};
            errorObj.description = "Invalid Address!";
            errorObj.index = index + 1;
            errorObj.field = "Address";
            errorArr.push(errorObj);
          }
          // console.log('password ==>', obj.password)
          // obj.org_id = org_id;
          obj.name = data[0]?.toString();
          obj.phone = data[1]?.toString();
          obj.state = data[2]?.toString();
          obj.city = data[3]?.toString();
          obj.pincode = data[4]?.toString();
          // obj.password = bcrypt.hashSync(data[5]?.toString(), 10);
          // obj.password = data[5]?.toString();
          let codeVal = 1;
          if (productCode) {
            codeVal = parseInt(
              productCode
                .slice(productCode.length - 4)
            );
          }
          obj.product_code =
          orgCode +
            "-UC" +
            NumberWithLeadingZeroes(codeVal + 1);
          obj.role = "user";
          obj.organization_users = {
            data: [{ org_id: obj.org_id }],
          };
          obj.org_user_phones = {
            data: [{ phone: obj.phone, org_id: org_id, name:  orgName, isactive: true}],
          };
          obj.shipping_addresses = {
            data: [{ phone: obj.phone, address: data[5]?.toString(), name:  obj.name, 
              city: obj.city, state: obj.state, pincode: obj.pincode, is_default: true, country: 'India'}],
          };
          obj.class_id = classes?.class[0]?.id
          insertPacks.push(obj);
        }
      });
      console.log('insert Packs ==>', insertPacks)
      if (errorArr.length > 0) {
        // alert("Error present in sheet!");
        setErrorArr(errorArr);
        // setImportError(errorArr);
        // setShowSuccessModal(true)
        return "";
      }
      if (errorArr.length === 0 && insertPacks.length === 0) {
        console.log('Error length =>', errorArr, insertPacks)
        alert(
          "No valid data exist in the file, Please enter data and try again!"
        );
        return "";
      }
      setInsertRetailerData(insertPacks);
      // uploadFile();
    });
  };


  const [pageUpdate, setPageUpdate] = React.useState(0);
  const [rowsPerPageUpdate, setRowsPerPageUpdate] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePageUpdate = (event, newPage) => {
    setPageUpdate(newPage);
  };

  const handleChangeRowsPerPageUpdate = (event) => {
    setRowsPerPageUpdate(+event.target.value);
    setPageUpdate(0);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const userRole = localStorage.getItem("ssp_admin_role");
  return (
    <div>
{userRole === 'admin' ? <></> : 
      <div>
        {!isFile || !isFileRetailer ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<AddBox />}
            variant="outlined"
          >
            Import
          </LoadingButton>
        ) : (
          <Button
            startIcon={<AddBox />}
            onClick={() => setOpen(true)}
            variant="text"
            color="primary"
            className="importText"
          >
            Import
          </Button>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="closePopup">
              <CancelIcon className="" onClick={handleClose} />
            </div>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              className="importbody"
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Import New Retailer" />
                    {/* <Tab label="Update Pack" /> */}
                  </Tabs>
                </Box>
                <TabPanel className="import-new-body" value={value} index={0}>
                  <div className="steplist">
                    <h3>STEP 1</h3>
                    <div className="stepnotes">
                      Download this excelsheet and add your back data in that
                      sheet.
                    </div>
                    <Button
                      variant="contained"
                      component="span"
                      onClick={downloadTemplate}
                      className="btnwidth"
                    >
                      Download Template
                    </Button>
                  </div>
                  <label
                    htmlFor="contained-button-file"
                    onChange={handleCapture}
                    className="mt-0 w-100"
                  >
                    <Input
                      accept=".xls, .xlsx"
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                    />
                    <div className="steplist">
                      <h3>STEP 2</h3>
                      <div className="stepnotes">
                        Once you have updated sample sheet with your data,
                        choose that file to upload
                      </div>
                      <Button
                        color="success"
                        variant="contained"
                        component="span"
                        className="btnwidth"
                      >
                        Select File
                      </Button>
                    </div>
                    <div className="filename">{fileData?.name}</div>
                  </label>
                  {errorArr?.length ? (
                    <div>
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Row #</TableCell>
                                <TableCell align="left">Field</TableCell>
                                <TableCell align="left">Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {errorArr
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.index}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.field}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.description}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 15, 25, 100]}
                          component="div"
                          count={errorArr.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="steplist">
                    <h3>STEP 3</h3>
                    <div className="stepnotes">
                      Once you have selected apprioriate file, if there is no
                      error in the sheet, this button will be enabled and you
                      will be able to upload your data.
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      className="btnwidth"
                      component="span"
                      onClick={uploadFile}
                      disabled={insertRetailerData ? false : true}
                    >
                      Submit
                    </Button>
                  </div>
                </TabPanel>
              
              </Box>
            </Stack>
          </Box>
        </Modal>
      </div>
  }
    </div>
  );
};
