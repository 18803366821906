import { useQuery } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import * as React from "react";
import { Show, SimpleShowLayout, TopToolbar, useRedirect } from "react-admin";
import { GET_PAYMENT_DETAILS } from "./GraphQL/queries";
import moment from "moment";
import { numToWords } from "num-to-words";
import Logo from "./Assets/Images/logo_black.svg";
import { Button } from "@material-ui/core";
import jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

const PaymentDetails = ({ match }) => {
  const { id } = match.params;
  let payId = window.atob(id);
  const inputRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const [orgDetails, setOrgDetails] = React.useState({});
  const [paymentsDetails, setPaymentsDetails] = React.useState([]);
  const [invoiceDetails, setInvoiceDetails] = React.useState({});
  const [amount, SetAmount] = React.useState(0);
  const [remainingDays, setRemainingDays] = React.useState(0);
  const redirect = useRedirect();

  const { data: paymentData, loading: paymentDataLoading } = useQuery(
    GET_PAYMENT_DETAILS,
    {
      variables: { pay_id: payId },
    },
    { fetchPolicy: "network-only" }
  );
  React.useEffect(() => {
    if (paymentData) {
      setOrgDetails({
        name: paymentData?.payment_due[0]?.organization?.name,
        email: paymentData?.payment_due[0]?.organization?.email,
        gst: paymentData?.payment_due[0]?.organization?.GST,
        address: paymentData?.payment_due[0]?.organization?.address,
        city: paymentData?.payment_due[0]?.organization?.city,
        adminName:
          paymentData?.payment_due[0]?.organization?.organization_user[0].user
            .name,
        pincode:
          paymentData?.payment_due[0]?.organization?.organization_user[0].user
            .pincode,
        state:
          paymentData?.payment_due[0]?.organization?.organization_user[0].user
            .state,
      });
      setInvoiceDetails({
        invoice_id:
          paymentData?.payment_due[0]?.organization?.payment_transactions[0]
            .invoice_num,
        is_credited:
          paymentData?.payment_due[0]?.organization?.payment_transactions[0]
            .is_credited,
        date: paymentData?.payment_due[0]?.start_date,
        updated_at: paymentData?.payment_due[0]?.updated_at,
      });
      setPaymentsDetails(paymentData?.payment_due[0]?.planTypeByPlanType);
      SetAmount(paymentData?.payment_due[0]?.amount / 100 || 0);
    }
    const date = new Date(paymentData?.payment_due_by_pk?.start_date);
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    setRemainingDays(days + " days");
  }, [paymentData]);

  const handlePrint = () => {
    tableRef.current.className = `${tableRef.current.className} no-box-shadow`;
    inputRef.current.className = `${inputRef.current.className} no-box-shadow`;
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = jsPDF("p", "px", "a4");

      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
      tableRef.current.className = tableRef.current.className.replace(
        " no-box-shadow",
        ""
      );
      inputRef.current.className = inputRef.current.className.replace(
        " no-box-shadow",
        ""
      );
    });
  };

  if (paymentDataLoading) {
    return <div>Loading...</div>;
  }
  const backAction = () => {
    redirect("/subscription-details");
  };

  return (
    <div className="showOrderContainer editOrderContainer invoiceOrderContainer">
      <div className="invoiceButton">
        <Button variant="outlined" color="primary" onClick={backAction}>
          Back
        </Button>
        <Button
          className="print-button"
          variant="contained"
          color="secondary"
          onClick={handlePrint}
        >
          Print
        </Button>
      </div>
      <div className="invoice" ref={inputRef}>
        <div className="orderCard">
          <Grid container spacing={0}>
            <Grid item md={8}>
              <div className="orderAddress">
                <h5>SUPPLYSPRING TECHNOLOGIES PVT.LTD.</h5>
                <p>
                  305, KRISHNA CON-ARCH 2,
                  <br />
                  TAGORE ROAD,
                  <br />
                  RAJKOT, GUJARAT-360002
                </p>

                <p>
                  <small>support@supplyspring.in</small>
                </p>
                <p>
                  <small>www.supplyspring.in</small>{" "}
                </p>
                <p>
                  <strong>GSTIN No.:</strong> 24ABBCS6761P1ZS
                </p>
                <p>
                  <strong>CIN :</strong> U74999GJ2019PTC106846
                </p>
                <p>
                  <strong>HSN / SAC : 998313</strong>
                </p>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="text-right invoiceLogo">
                <img src={Logo} alt="" />
              </div>
            </Grid>
          </Grid>
          <div className="invoicetitle">TAX INVOICE</div>
          <Grid container spacing={0}>
            <Grid item md={8}>
              <div className="orderAddress">
                <p>
                  <strong>INVOICE TO :</strong>
                </p>
                <p>
                  <strong>{orgDetails.name}</strong>
                </p>
                <p>
                  <address>{orgDetails.address}</address>
                </p>
                <strong>
                  {orgDetails.city}, {orgDetails.pincode}
                </strong>
                <p>
                  <strong>{orgDetails.state}</strong>
                </p>
                <p>{orgDetails.email}</p>
                <p>
                  <strong>GST No. :</strong> {orgDetails.gst}
                </p>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="orderAddress">
                <p>
                  <strong>Invoice No. :</strong> {invoiceDetails.invoice_id}
                </p>
                {invoiceDetails?.updated_at ? (
                  <p>
                    <strong>Invoice Date : </strong>
                    {moment(invoiceDetails.updated_at).format("DD/MM/YYYY")}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </Grid>

          <table
            width="100%"
            border="0"
            cellSpacing="0"
            className="MuiPaper-root invoiceTable"
            ref={tableRef}
          >
            <thead align="justify" valign="bottom" char="M">
              <tr>
                <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                  Sr. No.
                </th>
                <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                  Description
                </th>
                <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                  Tax
                </th>
                <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                  Amount
                </th>
              </tr>
            </thead>
            {paymentsDetails?.total_price === amount &&
            !invoiceDetails?.is_credited ? (
              <tbody border="1">
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    1.
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    Supply Spring - {paymentsDetails?.name}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    18% GST
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {paymentsDetails?.price}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root" />
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {paymentsDetails?.description}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong>Sub Total</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {paymentsDetails?.price}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {" "}
                    Period:{" "}
                    {moment(paymentData?.payment_due[0]?.start_date).format(
                      "DD-MMM-YYYY"
                    )}{" "}
                    to{" "}
                    {moment(paymentData?.payment_due[0]?.due_date).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    CGST @ 9% ON
                    <br />
                    <strong> {paymentsDetails?.price}</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {(paymentsDetails?.price / 100) * 9}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    SGST @ 9% ON
                    <br />
                    <strong>{paymentsDetails?.price}</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {(paymentsDetails?.price / 100) * 9}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong> TOTAL</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong>₹ {amount}</strong>
                  </td>
                </tr>
              </tbody>
            ) : !invoiceDetails?.is_credited ? (
              <tbody border="1">
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    1.
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    Supply Spring - {paymentsDetails?.name}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    18% GST
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount / 118) * 100).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root" />
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {` Amount ${((amount / 118) * 100).toFixed(2)} for remaining
                    ${remainingDays} `}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong>Sub Total</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount / 118) * 100).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {" "}
                    Period:{" "}
                    {moment(paymentData?.payment_due[0]?.start_date).format(
                      "DD-MMM-YYYY"
                    )}{" "}
                    to{" "}
                    {moment(paymentData?.payment_due[0]?.due_date).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    CGST @ 9% ON
                    <br />
                    <strong>{((amount / 118) * 100).toFixed(2)}</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount - (amount / 118) * 100) / 2).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    SGST @ 9% ON
                    <br />
                    <strong> {((amount / 118) * 100).toFixed(2)}</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount - (amount / 118) * 100) / 2).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong> TOTAL</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong>₹ {amount}</strong>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody border="1">
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    1.
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    Supply Spring - {paymentsDetails?.name}
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    18% GST
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount / 118) * 100).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root" />
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    Special monthly plan.
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong>Sub Total</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount / 118) * 100).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    CGST @ 9% ON
                    <br />
                    <strong>{((amount / 118) * 100).toFixed(2)}</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount - (amount / 118) * 100) / 2).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    SGST @ 9% ON
                    <br />
                    <strong> {((amount / 118) * 100).toFixed(2)}</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    {((amount - (amount / 118) * 100) / 2).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root"></td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong> TOTAL</strong>
                  </td>
                  <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                    <strong>₹ {amount}</strong>
                  </td>
                </tr>
              </tbody>
            )}

            <tfoot>
              <tr>
                <th colSpan="4">
                  Amount In Word : {`${numToWords(amount)} only`}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
