/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-anonymous-default-export */
import { gql, GraphQLClient } from "graphql-request";

export default {
  // called when the user attempts to log in
  login: ({ phoneN, password, app_id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const graphQLClient = new GraphQLClient(
          process.env.REACT_APP_API_ENDPOINT
        );

        const login = gql`
          mutation loginWeb(
            $phone: Float!
            $password: String!
            $app_id: String!
          ) {
            loginWeb(phone: $phone, password: $password, app_id: $app_id) {
              id
              org_id
              access_token
              role
              is_active
              is_account_cancel
            }
          }
        `;

        const variables = {
          phone: parseInt(phoneN),
          password,
          app_id,
        };

        const response = await graphQLClient.request(login, variables);
        if (response.loginWeb.is_account_cancel) {
          alert("Your account is cancelled, please contact support!");
        } else {
          if (
            response.loginWeb.role !== "admin" &&
            response.loginWeb.role !== "orgadmin" &&
            response.loginWeb.role !== "billing"
          ) {
            return reject("Not Permitted");
          }
          localStorage.setItem(
            "ssp_admin_access_token",
            response.loginWeb.access_token
          );
          localStorage.setItem("ssp_admin_role", response.loginWeb.role);
          localStorage.setItem("ssp_user_id", response.loginWeb.id);
          {
            response?.loginWeb?.is_active
              ? (window.location.href = "/#/")
              : (window.location.href = "/#/subscription-details");
          }
          window.location.reload();
          return resolve();
        }
      } catch (err) {
        if (err) {
          alert("Invalid Phone number or password");
        }
      }
    });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("ssp_admin_access_token");
    localStorage.removeItem("ssp_admin_role");
    localStorage.clear();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ error }) => {
    if (error === 401 || error === 403) {
      localStorage.removeItem("ssp_admin_access_token");
      localStorage.removeItem("ssp_admin_role");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("ssp_admin_access_token") ||
      window.location.hash.indexOf("forgot-password") > 0 ||
      window.location.hash.indexOf("reset-password") > 0
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for id
  getIdentity: () => {
    const userId = localStorage.getItem("ssp_user_id");
    return userId ? Promise.resolve(userId) : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem("ssp_admin_role")
      ? localStorage.getItem("ssp_admin_role")
      : window.location.hash.indexOf("forgot-password") > 0 ||
        window.location.hash.indexOf("reset-password") > 0
      ? "orgadmin"
      : "";
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
