import React, { useState } from "react";
import { useNotify, useRedirect } from "react-admin";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { RESET_PASSWORD } from "./GraphQL/mutation";
import { useMutation } from "@apollo/client";

const ResetPassword = ({ theme, match }) => {
  const [newPassword, setNewPassword] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();

  const activation_link = new URLSearchParams(location.search).get(
    "activationKey"
  );

  const [RESET_PASSWORD_MUTAION] = useMutation(RESET_PASSWORD);

  const notify = useNotify();
  const redirect = useRedirect();

  const submit = async (event) => {
    event.preventDefault();
    if (password === newPassword) {
      const res = await RESET_PASSWORD_MUTAION({
        variables: {
          password,
          activation_link,
        },
      });
      if (!res.errors) {
        notify(
          res?.resetPassword?.message
            ? res?.resetPassword?.message
            : "Password Reset Successfully!"
        );
        redirect("/login");
      } else {
        console.log(res.error);
      }
    } else {
      alert("The two passwords that you entered do not match!");
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="loginSection">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="changepasswordContainer">
              <span className="changepassword">Reset Password</span>
              <form id="resetpasswordform" onSubmit={submit}>
                <div>
                  <input
                    name="newPassword"
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    className="form-control"
                    onChange={(e) => setNewPassword(e.target.value.trim())}
                  />
                  <input
                    name="password"
                    type="password"
                    value={password}
                    placeholder="Confirm password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value.trim())}
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Change
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default ResetPassword;
