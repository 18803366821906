import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  Filter,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  useRedirect,
  NumberInput,
  EditButton,
  BooleanField,
  BooleanInput,
  Toolbar,
  useNotify,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  minLength,
  required,
} from "react-admin";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { UPDATE_PAYMENT_TYPE } from "./GraphQL/mutation";
import { PAYMENTTYPE_BY_ID } from "./GraphQL/queries";
import { returnError } from "./enum/error";

const PaymentTypeTitle = ({ record }) => {
  return <span>PaymentType {record ? `"${record.type}"` : ""}</span>;
};
const userRole = localStorage.getItem("ssp_admin_role");
const PaymentTypeFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      source="type@_ilike,title@_ilike,description@_ilike"
      alwaysOn
    />
    {userRole === "admin" && (
      <ReferenceInput
        perPage={1500}
        resettable
        source="org_id"
        reference="organization"
        label="Organization"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export const PaymentTypeList = (props) => {
  return (
    <div><h3>Payment Type List</h3>
    <List
      bulkActionButtons={false}
      exporter={false}
      filters={<PaymentTypeFilter />}
      {...props}
    >
      <Datagrid>
        <TextField inputMode="text" required source="type" />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField inputMode="text" required source="title" />
        <TextField inputMode="text" required source="description" />
        <TextField inputMode="text" required source="percentage" />
        <BooleanField required source="is_default" />
        {userRole === "orgadmin" && <EditButton />}
      </Datagrid>
    </List>
    </div>
  );
};

export const PaymentTypeEdit = (props) => {
  const [title, setTitle] = useState(null);
  const [type, setType] = useState("first");
  const [is_default, setIsDefault] = useState(null);
  const [description, setDescription] = useState(null);
  const [percentage, setPercentage] = useState(null);

  const { data: paymentTypeData } = useQuery(
    PAYMENTTYPE_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );
  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    if (paymentTypeData) {
      setType(paymentTypeData.payment_type_by_pk.type);
      setTitle(paymentTypeData.payment_type_by_pk.title);
      setDescription(paymentTypeData.payment_type_by_pk.description);
      setIsDefault(paymentTypeData.payment_type_by_pk.is_default);
      setPercentage(paymentTypeData.payment_type_by_pk.percentage);
    }
  }, [paymentTypeData]);

  const [UPDATE_PAYMENT_TYPE_MUTATION, { data: editData }] =
    useMutation(UPDATE_PAYMENT_TYPE);

  useEffect(() => {
    if (editData) {
      notify("Element updated successfully");
      redirect("/payment_type");
    }
  }, [editData]);

  useEffect(() => {}, [title, type, description, percentage, is_default]);

  const handleEdit = async (data) => {
    if (!title || !type || !description || !percentage) {
      notify("Please enter manadtory fields!");
      return;
    }
    if (percentage < 0 || isNaN(percentage)) {
      notify("Enter valid percentage");
      return;
    }
    UPDATE_PAYMENT_TYPE_MUTATION({
      variables: {
        title: title,
        type: type,
        description: description,
        is_default: is_default,
        percentage: percentage,
        id: props.id,
      },
    });
  };

  return (
    <Edit undoable={true} title={<PaymentTypeTitle />} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <Button
              disabled={!title || !type || !description || !percentage}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleEdit}
              {...props}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          onChange={(e) => setType(e.target.value.trim())}
          inputMode="text"
          source="type"
          validate={[required(), minLength(1)]}
        />
        <TextInput
          onChange={(e) => setTitle(e.target.value.trim())}
          inputMode="text"
          source="title"
          validate={[required(), minLength(1)]}
        />
        <TextInput
          onChange={(e) => setDescription(e.target.value.trim())}
          inputMode="text"
          source="description"
          validate={[required(), minLength(1)]}
        />
        <TextInput
          inputMode="text"
          onChange={(e) => setPercentage(e.target.value.trim())}
          source="percentage"
          validate={[required(), minLength(1)]}
        />
        <BooleanInput
          onChange={(e) => setIsDefault(!is_default)}
          source="is_default"
        />
      </SimpleForm>
    </Edit>
  );
};

export const PaymentTypeCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Payment type added successfully!");
        redirect("/payment_type");
      }}
      onFailure={(error) => {
        return notify(returnError(error.message), "warning");
      }}
    >
      <SimpleForm>
        <TextInput inputMode="text" required source="type" />
        <TextInput inputMode="text" required source="title" />
        <TextInput inputMode="text" required source="description" />
        <NumberInput required source="percentage" />
        <BooleanInput required source="is_default" />
      </SimpleForm>
    </Create>
  );
};
