// in src/MyAppBar.js
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { AppBar, useDataProvider, UserMenu } from "react-admin";
import Logo from "./Assets/Images/logo_black.svg";
import PoweredByLogo from "./Assets/Images/im-logo.svg";
import MyMenu from "./MyMenu";
import playStore from "./Assets/Images/play_store.svg"
import appStore from "./Assets/Images/app_store.svg"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [logo, setLogo] = React.useState("");
  const [orgName, setOrgName] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [adminRole, setUserRole] = React.useState("true");

  const totalUserCount = React.useCallback(async () => {
    const { data: allUsers } = await dataProvider.getList("user", {});
    const adminUser = allUsers.filter((user) => user.role === "admin");
    setUserRole(adminUser);
  }, [dataProvider]);

  React.useEffect(() => {
    dataProvider
      .getOne("organization", {})
      .then(({ data: Organization }) => {
        setLogo(Organization.logo);
        setOrgName(Organization.name);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    totalUserCount();
  }, [dataProvider]);
  if (loading) return <loader />;

  return (
    <AppBar userMenu={<CustomUserMenu />} {...props}>
      {adminRole.length > 0 ? (
        <div className="logo logospace">
          <img src={Logo} alt="" />
        </div>
      ) : (
        <div className="logo logospace">
          {logo ? (
            <img src={logo} alt="" />
          ) : (
            <span>{orgName.charAt(0).toUpperCase() + orgName.slice(1)}</span>
          )}
        </div>
      )}
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      {!adminRole.length ? (
        <div className="powerlogo logo">
          <span>Powered By</span>
          <img src={Logo} alt="" />
        </div>
      ) : (
        <div className="powerlogo powerbylogo logo">
          <span>Powered By</span>
          <img src={PoweredByLogo} alt="" />
        </div>
      )}
      <a
        href="https://apps.apple.com/us/app/supplyspring/id1571124480"
        target="_blank"
      >
        <img src={appStore} className="appImage"/>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.supplyspring.retailer"
        target="_blank"
      >
        <img src={playStore} className="appImage"/>
      </a>
    </AppBar>
  );
};

export default MyAppBar;

export const CustomUserMenu = (props) => {
  return (
    <UserMenu {...props}>
      <MyMenu />
    </UserMenu>
  );
};
