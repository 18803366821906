import { Column as ColumnChart } from "@ant-design/charts";
import { useLazyQuery } from "@apollo/client";
import React from "react";
import {
  GET_ORDER_QUANTITIES_FOR_DATE_RANGE,
  GET_ORDER_QUANTITIES_FOR_ORGANIZATION,
} from "./GraphQL/queries";

const QuantityDashboardChart = ({ startDate, endDate, org_id, org_list }) => {
  const userRole = localStorage.getItem("ssp_admin_role");

  const [formattedQuantityReportData, setFormattedQuantityReportData] =
    React.useState([]);

  const [GET_QUANTITY_REPORT, { data: quantityChartData }] = useLazyQuery(
    GET_ORDER_QUANTITIES_FOR_DATE_RANGE,
    {
      fetchPolicy: "network-only",
    }
  );

  const [GET_ORG_QUANTITY_REPORT, { data: orgQuantityChartData }] =
    useLazyQuery(GET_ORDER_QUANTITIES_FOR_ORGANIZATION, {
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    if (userRole === "orgadmin") {
      GET_QUANTITY_REPORT({
        variables: {
          startDate,
          endDate,
          org_id: org_id ?? undefined,
        },
      });
    }
    GET_ORG_QUANTITY_REPORT({
      variables: {
        startDate,
        endDate,
        org_id: org_list?.length ? org_list : org_id ?? undefined,
      },
    });
  }, [startDate, endDate, org_id, org_list]);

  React.useEffect(() => {
    if (quantityChartData) {
      const formattedChartData = quantityChartData.user
        .filter((v) => v.orders.length > 0)
        .flatMap((val) => {
          const totalQty = val.orders.reduce((accumulator, current) => {
            const orderQuantity = current.product_orders.reduce((acc, curr) => {
              if (
                ["litre", "kg"].includes(
                  curr?.pack?.measurement?.name.toLowerCase()
                )
              ) {
                return (
                  acc +
                  parseFloat(curr?.pack?.carton_size) *
                    parseFloat(curr?.quantity) *
                    parseFloat(curr?.pack?.pack_size)
                );
              }
              if (
                ["ml", "gram"].includes(
                  curr.pack?.measurement?.name.toLowerCase()
                )
              ) {
                return (
                  acc +
                  (curr.pack?.carton_size *
                    curr.quantity *
                    parseFloat(curr.pack?.pack_size)) /
                    1000
                );
              }
            }, 0);

            return accumulator + orderQuantity;
          }, 0);
          return [
            {
              name: val.name,
              qty: totalQty,
            },
          ];
        })
        .sort((a, b) => b.qty - a.qty)
        .slice(0, 5);
      setFormattedQuantityReportData(formattedChartData);
    }
  }, [quantityChartData]);

  React.useEffect(() => {
    if (orgQuantityChartData) {
      const formattedChartData = orgQuantityChartData.organization
        ?.flatMap((org) => {
          const totalQty = org.orders.reduce((accumulator, current) => {
            const orderQuantity = current.product_orders?.reduce(
              (acc, curr) => {
                if (
                  ["litre", "kg"].includes(
                    curr.pack.measurement?.name.toLowerCase()
                  )
                ) {
                  return (
                    acc +
                    parseFloat(curr.pack?.carton_size) *
                      parseFloat(curr.quantity) *
                      parseFloat(curr.pack?.pack_size)
                  );
                }
                if (
                  ["ml", "gram"].includes(
                    curr.pack.measurement?.name.toLowerCase()
                  )
                ) {
                  return (
                    acc +
                    (curr.pack?.carton_size *
                      curr.quantity *
                      parseFloat(curr.pack?.pack_size)) /
                      1000
                  );
                }
                return (
                  acc +
                  (curr.pack.carton_size *
                    curr.quantity *
                    parseFloat(curr.pack.pack_size)) /
                    1000
                );
              },
              0
            );
            return accumulator + orderQuantity;
          }, 0);
          return [
            {
              name: org.name,
              qty: totalQty,
            },
          ];
        })
        .sort((a, b) => b.qty - a.qty)
        .slice(0, 5);
      setFormattedQuantityReportData(formattedChartData);
    }
  }, [orgQuantityChartData]);

  if (formattedQuantityReportData.length === 0) {
    return <div className="loader_blk">No Orders</div>;
  }

  return (
    <ColumnChart
      data={formattedQuantityReportData}
      xField="name"
      yField="qty"
      xAxis={{ label: { autoHide: false, autoEllipsis: true } }}
      label={{
        position: "middle",
      }}
    />
  );
};

export default QuantityDashboardChart;
