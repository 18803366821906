import { Button } from "@mui/material";
import * as React from "react";
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRedirect,
  regex,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  DeleteWithConfirmButton,
  useNotify,
  required,
} from "react-admin";
import { returnError } from "./enum/error";

const validateText = [required(), regex(/^[A-Za-z]+$/, "Must be a valid text")];

const userRole = localStorage.getItem("ssp_admin_role");

const MeasurementTitle = ({ record }) => {
  return <span>Measurements {record ? `${record.name}` : ""}</span>;
};

const MeasurementFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_ilike" alwaysOn />
    {userRole === "admin" && (
      <ReferenceInput
        perPage={1500}
        resettable
        source="org_id"
        reference="organization"
        label="Organization"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export const MeasurementList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Measurement List</h3>
    <List
      bulkActionButtons={false}
      exporter={false}
      filters={<MeasurementFilter />}
      {...props}
    >
      <Datagrid>
        <TextField source="name" validate={validateText} />
        {userRole === "admin" && (
          <ReferenceField
            link={false}
            source="org_id"
            reference="organization"
            label="Organization"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {userRole === "orgadmin" && <EditButton />}
        {userRole === "orgadmin" && (
          <DeleteWithConfirmButton
            confirmTitle="Confirm"
            onSuccess={() => {
              notify("Measurement deleted successfully!");
              window.location.reload();
            }}
            onFailure={(error) => {
              return notify(returnError(error.message), "warning");
            }}
          />
        )}
      </Datagrid>
    </List>
    </div>
  );
};

export const MeasurementEdit = (props) => {
  const redirect = useRedirect();
  const transform = (data) => {
    return {
      ...data,
      name: data.name.trim(),
    };
  };
  return (
    <Edit
      transform={transform}
      title={<MeasurementTitle />}
      {...props}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput source="name" validate={validateText} />
      </SimpleForm>
    </Edit>
  );
};

export const MeasurementCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const transform = (data) => {
    data.name = data.name.trim();
    return data;
  };
  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Measurement added successfully!");
        redirect("/measurement");
      }}
      onFailure={(error) => {
        return notify(returnError(error.message), "warning");
      }}
      undoable={false}
      transform={transform}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput source="name" validate={validateText} />
      </SimpleForm>
    </Create>
  );
};
