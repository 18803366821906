import { useQuery } from "@apollo/client";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import * as React from "react";
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  downloadCSV,
  EditButton,
  ExportButton,
  Filter,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  useNotify,
} from "react-admin";
import Empty from "../Assets/Images/empty.png";
import { ImportFilePack } from "../Components/Imports/ImportFilePack";
import { returnError } from "../enum/error";
import { GET_IS_CUSTOM_SKU, PACK_IMPORT } from "../GraphQL/queries";

const PackFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder="Search"
      source="sku@_ilike,stock_status@_ilike,pack_size@_ilike,alias@_ilike"
      alwaysOn
    />
    <ReferenceInput
      perPage={500}
      resettable
      source="product_id"
      reference="product"
      label="Products"
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={(searchText) => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      perPage={500}
      source="measurement_id"
      reference="measurement"
      label="Measurements"
    >
      <AutocompleteInput initialIsOpen source="measurement_id" />
    </ReferenceInput>
    <ReferenceInput
      perPage={500}
      source="section_id"
      reference="section"
      label="Section"
    >
      <AutocompleteInput initialIsOpen source="section_id" />
    </ReferenceInput>
  </Filter>
);

const ListActions = ({ props, basePath }) => {
  const { data } = useQuery(PACK_IMPORT, {
    fetchPolicy: "network-only",
  });
  const [isFile, setIsFile] = React.useState(false);
  const [isFilePack, setIsFilePack] = React.useState(false);
  const ExportUsers = () => {
    if (data && data.pack.length > 0) {
      const packsForExport = data?.pack?.map((prod, index) => {
        const discountAmount = prod.pack_discounts
          .flatMap((d) => [d.amount])
          .join(" / ");
        // add a field from an embedded resource
        let packs = {};
        packs.logo = prod?.logo;
        packs.product_name = prod?.product.name;
        packs.unit_size = prod?.pack_size;
        packs.measurement = prod?.measurement?.name;
        packs.unit_price = prod?.unit_price;
        packs.sku = prod?.sku;
        packs.sections = prod?.section.name;
        packs.discounts = discountAmount;
        packs.stock_status = prod?.stock_status;
        packs.pack_size = prod?.carton_size;
        packs.mrp = prod?.price;
        console.log(prod)
        return packs;
      });
      const csv = convertToCSV({
        data: packsForExport,
        // select and order fields in the export
        fields: [
          "logo",
          "product_name",
          "unit_size",
          "measurement",
          "unit_price",
          "sku",
          "sections",
          "discounts",
          "stock_status",
          "pack_size",
          "mrp"
        ],
      });
      downloadCSV(csv, "Packs"); // download as 'posts.csv` file
    }
  };
  const userRole = localStorage.getItem("ssp_admin_role");
  return (
    <TopToolbar {...props}>
      <PackFilter context="button" />
      {userRole === "admin" ? <></> : <CreateButton basePath={basePath} />}
      <ImportFilePack
        isFile={isFile}
        isFileImported={setIsFile}
        isFilePack={isFilePack}
        isFileImportedPack={setIsFilePack}
        type={basePath}
      />
      {data && <ExportButton exporter={() => ExportUsers()} />}
    </TopToolbar>
  );
};
export const PackList = (props) => {
  const notify = useNotify();
  const { data: isCustomSKU } = useQuery(GET_IS_CUSTOM_SKU);
  return (
    <div><h3>Pack List</h3>
    <List
      bulkActionButtons={false}
      filters={<PackFilter />}
      actions={<ListActions />}
      {...props}
      undoable={false}
    >
      <Datagrid>
        <ImageField
          source="logo"
          {...props}
          emptyText={<img src={Empty} alt="logo" />}
          sortable={false}
        />
        <ReferenceField
          link={false}
          source="product_id"
          reference="product"
          sortBy="product.name"
          sortByOrder="ASC"
        >
          <TextField source="name" label="Product Name" />
        </ReferenceField>
        <TextField label="Unit size" source="pack_size" />
        <ReferenceField
          link={false}
          source="measurement_id"
          reference="measurement"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="unit_price" label="Unit Price" />
        {!isCustomSKU?.organization[0]?.is_custom_sku ? (
          <TextField source="sku" />
        ) : (
          <TextField source="alias" label="Alias" />
        )}
        <ReferenceField
          perPage={500}
          source="section_id"
          reference="section"
          label="Section"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <NumberField
          sortable={false}
          source="pack_discounts[0].amount"
          label="Discount Price A"
        />

        <NumberField
          sortable={false}
          source="pack_discounts[1].amount"
          label="Discount Price B"
        />

        <NumberField
          sortable={false}
          source="pack_discounts[2].amount"
          label="Discount Price C"
        />

        <TextField source="stock_status" />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle="Confirm"
          onSuccess={() => {
            notify("Pack deleted successfully!");
            window.location.reload();
          }}
          onFailure={(error) => {
            return notify(returnError(error.message), "warning");
          }}
        />
      </Datagrid>
    </List>
    </div>
  );
};
