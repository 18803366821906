import React from "react";
import OrgAdminDashboard from "./OrgAdminDashboard";
import AdminDashboard from "./AdminDashboard";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import {
useRedirect,
} from "react-admin";

const Dashboards = () => {
  const redirect = useRedirect();
  const userRole = localStorage.getItem("ssp_admin_role");
  
  React.useEffect(() => {
    if(userRole === 'billing'){
      redirect("/order");
    }
  }, [userRole]);
  return (
    <div>
      {userRole === "orgadmin" ? (
        <OrgAdminDashboard />
      ) : userRole === "admin" ? (
        <AdminDashboard />
      ) : (
        <Grid>
          <Paper align="center"> You are not permitted for dashboard. </Paper>
        </Grid>
      )}
    </div>
  );
};

export default Dashboards;
