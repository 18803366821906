import * as React from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";

const OrganizationCategoryTitle = ({ record }) => {
  return (
    <span>
      OrganizationCategory{" "}
      {record ? `"${record.firstname} ${record.lastname}"` : ""}
    </span>
  );
};

const OrganizationCategoryFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_ilike" alwaysOn />
    <ReferenceInput
      perPage={500}
      source="category_id"
      reference="category"
      label="Select Category"
    >
      <AutocompleteInput source="category_id" />
    </ReferenceInput>
    <ReferenceInput
      source="org_id"
      reference="organization"
      label="Organizations"
    >
      <AutocompleteInput source="org_id" />
    </ReferenceInput>
  </Filter>
);

export const OrganizationCategoryList = (props) => {
  return (
    <List filters={<OrganizationCategoryFilter />} {...props}>
      <Datagrid>
        <ReferenceField
          link={false}
          source="category_id"
          reference="category"
          label="Selected Category"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          link={false}
          source="org_id"
          reference="organization"
          label="Selected Organization"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const OrganizationCategoryEdit = (props) => (
  <Edit title={<OrganizationCategoryTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        source="category_id"
        reference="category"
        label="Select Category"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Select Organization"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const OrganizationCategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <TextInput
        source="id"
        defaultValue={React.useMemo(() => uuid(), [])}
        disabled
      /> */}
      <ReferenceInput
        source="category_id"
        reference="category"
        label="Select Category"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Select Organization"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
