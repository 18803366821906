import { gql } from "@apollo/client";

export const ADD_CATEGORY = gql`
  mutation insert_category_one(
    $logo: String
    $name: String
    $order_by: numeric
    $type: String
    $org_id: uuid
  ) {
    insert_category_one(
      object: {
        logo: $logo
        name: $name
        order_by: $order_by
        type: $type
        org_id: $org_id
        organization_categories: { data: {} }
      }
    ) {
      name
    }
  }
`;
export const EDIT_CATEGORY = gql`
  mutation EditCategory(
    $name: String
    $type: String
    $id: uuid!
    $order_by: numeric
    $logo: String
  ) {
    update_category(
      _set: { name: $name, type: $type, order_by: $order_by, logo: $logo }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const EDIT_BRANDS = gql`
  mutation EditBrand($id: uuid!, $name: String, $logo: String) {
    update_brand(
      where: { id: { _eq: $id } }
      _set: { name: $name, logo: $logo }
    ) {
      affected_rows
    }
  }
`;

export const ADD_ORGANIZATON = gql`
  mutation addOrganization(
    $name: String
    $email: String
    $code: String
    $GST: String
    $type: String
    $name1: String
    $role: String
    $state: String
    $phone: numeric
    $code1: Int
    $city: String
    $email1: String
  ) {
    insert_organization(
      objects: {
        GST: $GST
        code: $code
        email: $email
        name: $name
        type: $type
        organization_users: {
          data: {
            user: {
              data: {
                name: $name1
                role: $role
                state: $state
                phone: $phone
                code: $code1
                city: $city
                email: $email1
              }
            }
          }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_PACK = gql`
  mutation pack(
    $product_id: uuid
    $sku: String
    $pack_size: String
    $measurement_id: uuid
    $unit_price: Int
    $price: numeric
    $carton_size: Int
    $class_id: uuid
    $logo: String
    $section_id: uuid
    $pack_discounts: [pack_discount_insert_input!]!
  ) {
    insert_pack_one(
      object: {
        sku: $sku
        product_id: $product_id
        pack_size: $pack_size
        measurement_id: $measurement_id
        unit_price: $unit_price
        price: $price
        carton_size: $carton_size
        class_id: $class_id
        logo: $logo
        section_id: $section_id
        pack_discounts: { data: $pack_discounts }
      }
    ) {
      id
    }
  }
`;

export const INSERT_USER = gql`
  mutation createUser($user_date: [JSON!]!) {
    createUser(user_date: $user_date) {
      status
      message
    }
  }
`;

export const UPDATE_RETAILER_PHONE = gql`
  mutation update_org_user_phone($phone: numeric, $user_id: uuid) {
    update_org_user_phone(
      where: { user_id: { _eq: $user_id } }
      _set: { phone: $phone }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_RETAILER = gql`
  mutation insertRetailer(
    $name: String
    $role: String
    $phone: numeric
    $state: String
    $city: String
    $product_code: String
    $pincode: String
    $data: [organization_user_insert_input!]! = {}
    $class_id: uuid
    $orgcity: String
  ) {
    insert_user(
      objects: {
        name: $name
        role: $role
        phone: $phone
        state: $state
        city: $city
        product_code: $product_code
        pincode: $pincode
        organization_users: { data: $data }
        class_id: $class_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const INSERT_SHIPPING_ADDRESS = gql`
  mutation insert_shipping_address_one(
    $address: String
    $city: String
    $country: String
    $created_by: uuid
    $name: String
    $phone: bigint
    $pincode: String
    $state: String
  ) {
    insert_shipping_address_one(
      object: {
        address: $address
        city: $city
        country: $country
        created_by: $created_by
        is_default: true
        name: $name
        phone: $phone
        pincode: $pincode
        state: $state
      }
    ) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation MyMutation(
    $name: String
    $phone: numeric
    $id: uuid
    $address: String
    $email: String
    $name1: String
    $GST: String
    $type: uuid
    $org_id: uuid
    $state: String
    $city: String
    $pincode: String
  ) {
    update_user(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        phone: $phone
        state: $state
        city: $city
        pincode: $pincode
      }
    ) {
      affected_rows
    }
    update_organization(
      where: { id: { _eq: $org_id } }
      _set: {
        address: $address
        email: $email
        name: $name1
        GST: $GST
        type: $type
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($user_id: uuid) {
    delete_user(where: { id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_organization_user(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
  }
`;

export const INSERT_PACK_ONE = gql`
  mutation pack($object: pack_insert_input!) {
    insert_pack_one(
      object: $object
      on_conflict: {
        constraint: pack_pkey
        update_columns: [unit_price, price]
      }
    ) {
      id
      price
      unit_price
      pack_discounts {
        amount
      }
    }
  }
`;
export const INSERT_BANNER_ONE = gql`
  mutation insert_banners_one(
    $banner_description: String
    $banner_image_url: String
  ) {
    insert_banners_one(
      object: {
        banner_description: $banner_description
        banner_image_url: $banner_image_url
      }
    ) {
      id
    }
  }
`;
export const UPDATE_BANNER_ONE = gql`
  mutation update_banners_by_pk(
    $banner_description: String
    $banner_image_url: String
    $id: uuid!
  ) {
    update_banners_by_pk(
      pk_columns: { id: $id }
      _set: {
        banner_description: $banner_description
        banner_image_url: $banner_image_url
      }
    ) {
      id
      banner_image_url
      banner_description
    }
  }
`;

export const UPDATE_PACK_ONE = gql`
  mutation update_pack_by_pk(
    $id: uuid!
    $measurement_id: uuid
    $logo: String
    $carton_size: Int
    $pack_size: String!
    $price: float8
    $product_id: uuid
    $section_id: uuid
    $stock_status: String
    $unit_price: float8
    $alias: String
  ) {
    update_pack_by_pk(
      pk_columns: { id: $id }
      _set: {
        measurement_id: $measurement_id
        logo: $logo
        carton_size: $carton_size
        pack_size: $pack_size
        price: $price
        section_id: $section_id
        stock_status: $stock_status
        unit_price: $unit_price
        product_id: $product_id
        alias: $alias
      }
    ) {
      id
    }
  }
`;

export const UPDATE_PACK_DISCOUNT = gql`
  mutation insert_pack_discount_one(
    $amount: float8
    $class_id: uuid
    $pack_id: uuid
  ) {
    insert_pack_discount_one(
      object: { amount: $amount, class_id: $class_id, pack_id: $pack_id }
      on_conflict: {
        constraint: pack_discount_pack_id_class_id_key
        update_columns: amount
      }
    ) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_BY_ID = gql`
  mutation update_product_by_pk(
    $id: uuid!
    $name: String
    $brand_id: uuid
    $description: String
    $logo: String
    $part_code: String
  ) {
    update_product_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        brand_id: $brand_id
        description: $description
        logo: $logo
        part_code: $part_code
      }
    ) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation update_product_category($category_id: uuid, $product_id: uuid) {
    update_product_category(
      where: { product_id: { _eq: $product_id } }
      _set: { category_id: $category_id }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_RETAILER_BY_ID = gql`
  mutation update_user_by_pk(
    $id: uuid!
    $city: String
    $class_id: uuid
    $state: String
    $phone: numeric
    $name: String
    $pincode: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        city: $city
        class_id: $class_id
        state: $state
        phone: $phone
        name: $name
        pincode: $pincode
      }
    ) {
      id
      shipping_addresses {
        id
        phone
        name
      }
      phone
      name
    }
  }
`;

export const UPDATE_SHIPPING_BY_ID = gql`
  mutation update_shipping_address_by_pk(
    $id: uuid!
    $address: String
    $city: String
    $country: String
    $name: String
    $phone: bigint
    $pincode: String
    $state: String
  ) {
    update_shipping_address_by_pk(
      pk_columns: { id: $id }
      _set: {
        address: $address
        city: $city
        country: $country
        name: $name
        phone: $phone
        pincode: $pincode
        state: $state
      }
    ) {
      id
      phone
      name
    }
  }
`;
export const DELETE_USER_BY_ID = gql`
  mutation update_user_by_pk($id: uuid!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { is_deleted: true }) {
      is_deleted
      id
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($amount: String!) {
    create_order(amount: $amount) {
      status
      returning
      message
    }
  }
`;

export const VERIFY_PAYMENT = gql`
  mutation verifyPayment(
    $verify_id: String!
    $payment_id: String!
    $signature: String! = "QR"
  ) {
    verify_payment(
      verify_id: $verify_id
      payment_id: $payment_id
      signature: $signature
    ) {
      status
      returning
      message
    }
  }
`;

export const CAPTURE_PAYMENT = gql`
  mutation capturePayment(
    $payment_id: String!
    $org_id: String!
    $due_id: String!
    $payment_end: String!
  ) {
    capturePreviousPayment(
      payment_id: $payment_id
      org_id: $org_id
      due_id: $due_id
      payment_end: $payment_end
    ) {
      status
      returning
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($user_id: String!, $password: String!) {
    changePassword(user_id: $user_id, password: $password) {
      message
    }
  }
`;

export const UPGARDE_PLAN = gql`
  mutation upgrade_plan(
    $org_id: String!
    $payment_end: String!
    $plan_id: String!
    $amount: float!
    $transaction_id: String!
    $payment_start: String!
  ) {
    upgradePlan(
      org_id: $org_id
      payment_end: $payment_end
      plan_id: $plan_id
      amount: $amount
      transaction_id: $transaction_id
      payment_start: $payment_start
    ) {
      message
    }
  }
`;

export const CREATE_UPGRADE_ORDER = gql`
  mutation createUpgradeOrder($plan_id: String!, $amount: String!) {
    createUpgradeOrder(plan_id: $plan_id, amount: $amount) {
      status
      returning
      message
    }
  }
`;

export const CAPTURE_UPGRADE_PAYMENT = gql`
  mutation capturePayment(
    $payment_id: String!
    $org_id: String!
    $payment_end: String!
    $plan_type: String!
    $user_limit: String!
  ) {
    captureUpgradePayment(
      payment_id: $payment_id
      org_id: $org_id
      payment_end: $payment_end
      plan_type: $plan_type
      user_limit: $user_limit
    ) {
      status
      returning
      message
    }
  }
`;

export const CANCEL_PLAN = gql`
  mutation MyMutation($org_id: uuid!) {
    update_organization(
      where: { id: { _eq: $org_id } }
      _set: { is_account_cancel: true }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_ORG_TYPE = gql`
  mutation MyMutation($org_id: uuid!, $plan_type: uuid!, $limit: numeric) {
    update_organization(
      where: { id: { _eq: $org_id } }
      _set: { type: $plan_type, users_limit: $limit }
    ) {
      affected_rows
    }
  }
`;

export const CHANGE_ORDER_STATUS = gql`
  mutation insert_order_status_one(
    $order_id: uuid
    $status: String
    $reason: String
  ) {
    insert_order_status_one(
      object: { status: $status, order_id: $order_id, reason: $reason }
    ) {
      id
      reason
      order_status_updated_by
      status
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword($activation_link: String!, $password: String!) {
    resetPassword(activation_link: $activation_link, password: $password) {
      status
      message
    }
  }
`;

export const INSERT_PACK = gql`
  mutation pack($objects: [pack_insert_input!]!) {
    insert_pack(objects: $objects) {
      affected_rows
      returning {
        id
        pack_discounts {
          id
        }
      }
    }
  }
`;

export const INSERT_PRODUCT = gql`
  mutation product($objects: [product_insert_input!]!) {
    insert_product(objects: $objects) {
      affected_rows
      returning {
        id
        product_categories {
          category_id
          product_id
        }
      }
    }
  }
`;

export const INSERT_BRAND = gql`
  mutation brand($objects: [brand_insert_input!]!) {
    insert_brand(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_ORG_USER = gql`
  mutation MyMutation($objects: [organization_user_insert_input!]!) {
    insert_organization_user(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_ORG_USER_PHONE = gql`
  mutation MyMutation($object: org_user_phone_insert_input!) {
    insert_org_user_phone_one(object: $object) {
      id
    }
  }
`;

export const DELETE_ORG_USER_PHONE = gql`
  mutation MyMutation($user_id: uuid!) {
    update_org_user_phone(
      where: { user_id: { _eq: $user_id } }
      _set: { isactive: false }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_PACK = gql`
  mutation upsert_pack($objects: [pack_insert_input!]!) {
    insert_pack(
      objects: $objects
      on_conflict: {
        constraint: pack_sku_key
        update_columns: [unit_price, stock_status, price]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_PACK_DISCOUNT = gql`
  mutation upsert_pack_discount($objects: [pack_discount_insert_input!]!) {
    insert_pack_discount(
      objects: $objects
      on_conflict: {
        constraint: pack_discount_pack_id_class_id_key
        update_columns: [amount]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_DEFAULT_ORG_DATA = gql`
  mutation pack_product_brand($objects: [brand_insert_input!]!) {
    insert_brand(
      objects: $objects
      on_conflict: {
        constraint: brand_name_org_id_key
        update_columns: [updated_at]
      }
    ) {
      returning {
        id
        products {
          id
          packs {
            id
            pack_discounts {
              id
            }
          }
        }
      }
    }
  }
`;

export const INSERT_SECTION = gql`
  mutation insert_section($objects: [section_insert_input!]!) {
    insert_section(
      objects: $objects
      on_conflict: {
        constraint: section_name_org_id_key
        update_columns: [updated_at]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_MEASUREMENT = gql`
mutation insert_measurement($objects: [measurement_insert_input!]!) {
  insert_measurement(
    objects: $objects
    on_conflict: {
      constraint: measurement_name_org_id_key
      update_columns: [updated_at]
    }
  ) {
    returning {
      id
    }
  }
}
`;

export const INSERT_CATEGORY = gql`
  mutation insert_category($objects: [category_insert_input!]!) {
    insert_category(objects: $objects) {
      returning {
        id
        organization_categories {
          id
        }
      }
    }
  }
`;

export const UPDATE_PAYMENT_TYPE = gql`
  mutation paymentType(
    $id: uuid!
    $title: String
    $percentage: numeric
    $type: String
    $is_default: Boolean
    $description: String
  ) {
    update_payment_type(
      _set: {
        type: $type
        title: $title
        percentage: $percentage
        is_default: $is_default
        description: $description
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_ORG = gql`
  mutation MyMutation($org_data: [JSON!]!, $payment_id: String!) {
    register(org_data: $org_data, payment_id: $payment_id) {
      status
      returning
      message
    }
  }
`;

export const IMPORT_RETAILER = gql`
mutation MyMutation($objects: [user_insert_input!]!) {
  insert_user(objects: $objects){
    affected_rows
    returning{
      id
      organization_users{
        user_id
        org_id
      }
      org_user_phones{
        phone
        org_id
        name
        isactive
        user_id
      }
      shipping_addresses {
        address
        phone
        name
        city
        state
        country
        pincode
        is_default
      }
    }
  }
}
`;

export const IMPORT_RETAILER_ORG = gql`
  mutation MyMutation($objects: [organization_user_insert_input!]!) {
    insert_organization_user(objects: $objects){
      affected_rows
    }
  }
`;