import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  FunctionField,
  SearchInput,
  DateInput,
  useListContext,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  DeleteWithConfirmButton,
  useNotify
} from "react-admin";
import moment from "moment";
import {GET_PAYMENT_TYPE} from "./GraphQL/queries";
import { returnError } from "./enum/error";

const OrderTitle = ({ record }) => {
  return <span>Order {record ? record.order_id : ""}</span>;
};

const userRole = localStorage.getItem("ssp_admin_role");
const OrderFilter = (props) => {
  const userAdmin = localStorage.getItem("ssp_admin_role");
  const { filterValues, setFilters } = useListContext();
  const { data: paymentType } = useQuery(GET_PAYMENT_TYPE);

  React.useEffect(() => {
    if (filterValues["created_at@_gte"]) {
      // if (!filterValues["created_at@_lt"]) {
        setFilters({
          ...filterValues ,
          "created_at@_lt": moment(filterValues["created_at@_gte"])
            .add(1, "day")
            .format(),
        });
      // }
    } else {
      setFilters({
        ...filterValues,
        "created_at@_lt": undefined,
      });
    }
  }, [filterValues["created_at@_gte"]]);

  return (
    <Filter {...props}>
      <SearchInput
        source="order_id@_eq"
        placeholder="Search Order Id"
        resettable
        alwaysOn
      />
      <DateInput
        parse={(v) => {
          // v is a string of "YYYY-MM-DD" format
          const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
          if (match === null) return;
          return moment(v).format();
        }}
        source="created_at@_gte"
        resettable
        label="Order Date"
      />
      <ReferenceInput
        perPage={1500}
        resettable
        source="ordered_by"
        reference="user"
        label="Retailers"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      {userRole !== 'admin' && 
      <ReferenceInput
        perPage={500}
        source="payment_type_id"
        reference="payment_type"
        label="Payment Type"
        alwaysOn
      >
        <SelectInput optionText="title" />
      </ReferenceInput>}
      {userAdmin === "admin" && (
        <ReferenceInput
          source="org_id"
          reference="organization"
          label="Organizations"
        >
          <AutocompleteInput source="org_id" />
        </ReferenceInput>
      )}
    </Filter>
  );
};
export const OrderList = (props) => {
  const notify = useNotify();
  return (
    <div><h3>Order List</h3>
    <List
    bulkActionButtons={false}
      filters={<OrderFilter />}
      {...props}
      sort={{ field: "order_id", order: "DESC" }}
      exporter={false}
    >
      <Datagrid>
        <TextField source="order_id" label="Order Id" />
        <DateField source="created_at" showTime label="Ordered on" />
        <ReferenceField
          source="ordered_by"
          reference="user"
          label="Retailers"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        
        <ReferenceField
          link={false}
          source="payment_type_id"
          reference="payment_type"
          label="Payment Type"
        >
          <TextField source="title" />
        </ReferenceField> 
        <TextField label="Amount" source="order_total_discounted_amount" 
        sortBy="order_total_amount.amount"
        />
        <TextField label="Transport Name" source="transport_name" />
        {userRole === 'admin' && 
        <ReferenceField
          link={false}
          source="org_id"
          reference="organization"
          label="Organization"
        >
          <TextField source="name" />
        </ReferenceField>}
        <FunctionField
          label="Order Status"
          render={(record) => {
            return record.order_statuses.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )[0].status;
          }}
        />
        <ShowButton />
        {userRole === 'orgadmin' ? <EditButton basePath="/order-status" /> : <></>}
        {/* <DeleteWithConfirmButton confirmTitle="Confirm" onSuccess={() => { notify('Order deleted successfully!'); window.location.reload()}}  onFailure={(error) => {return notify(returnError(error.message),'warning')}}/> */}
      </Datagrid>
    </List>
    </div>
  );
};

export const OrderEdit = (props) => (
  <Edit actions="" title={<OrderTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        disabled
        source="ordered_by"
        reference="user"
        label="Ordered by"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        perPage={500}
        source="order_updated_by"
        reference="user"
        label="Order Updated by"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Select Organization"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="instructions" />
      <NumberInput source="totalamount_obsolate" />
      <ReferenceInput
        perPage={500}
        source="payment_type_id"
        reference="payment_type"
        label="Select Payment Type"
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="transport_name" />
      <TextInput disabled source="order_id" label="Order Id" />
    </SimpleForm>
  </Edit>
);

export const OrderCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="ordered_by" reference="user" label="Ordered by">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="order_updated_by"
        reference="user"
        label="Order Updated by"
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Select Organization"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="instructions" />
      <NumberInput source="totalamount_obsolate" />
      <ReferenceInput
        source="payment_type_id"
        reference="payment_type"
        label="Select Payment Type"
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="transport_name" />
      <TextInput source="transport_vehical_no" />
      <ReferenceInput
        source="shipping_address_id"
        reference="shipping_address"
        label="Select Shipping Address"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="order_id" label="Order Id" />
      <TextInput source="lr_no" />
      <TextInput source="attachment_url" />
    </SimpleForm>
  </Create>
);
