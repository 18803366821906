import { ApolloProvider } from "@apollo/client";
import {
  AccountBalance,
  CardTravel,
  Category,
  Class,
  Favorite,
  Flare,
  GridOn,
  Group,
  GroupWork,
  Inbox,
  LocalOffer,
  PermIdentity,
  Person,
  SupervisedUserCircle,
} from "@material-ui/icons";
import ApolloClient from "apollo-boost";
import * as gqlTypes from "graphql-ast-types-browser";
import buildHasuraProvider from "ra-data-hasura-graphql/src";
import {
  buildApolloArgs,
  buildArgs,
  buildFields,
  buildGqlQuery,
  buildMetaArgs,
} from "ra-data-hasura-graphql/src/buildGqlQuery";
import { buildQueryFactory } from "ra-data-hasura-graphql/src/buildQuery";
import buildVariables from "ra-data-hasura-graphql/src/buildVariables";
import getResponseParser from "ra-data-hasura-graphql/src/getResponseParser";
import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import addUploadFeature from "./addUploadFeature";
import loader from "./Assets/Images/loader.svg";
import authProvider from "./authProvider";
import { BannerCreate, BannerEdit, BannerList } from "./banner";
import { BrandCreate, BrandEdit, BrandList } from "./brand";
import { CategoryCreate, CategoryEdit, CategoryList } from "./category";
import { ClassCreate, ClassEdit, ClassList } from "./class";
import CustomRoutes from "./customRoutes";
import Dashboards from "./Dashboards/Dashboards";
import {
  FavoriteProductCreate,
  FavoriteProductEdit,
  FavoriteProductList,
} from "./favoriteProduct";
import LoginPage from "./Login";
import {
  MeasurementCreate,
  MeasurementEdit,
  MeasurementList,
} from "./measurements";
import MyLayout from "./MyLayout";
import { OrderEdit, OrderList } from "./order";
import {
  OrganizationCategoryCreate,
  OrganizationCategoryEdit,
  OrganizationCategoryList,
} from "./organizationCategory";
import { OrganizationCreate, OrganizationList } from "./organizations";
import { OrganizationEdit } from "./organizationsUpdate";
import {
  OrganizationUserCreate,
  OrganizationUserEdit,
  OrganizationUserList,
} from "./organizationUser";
import { PackList } from "./Packs/PackList";
import { PackCreate } from "./Packs/PackCreate";
import { PackEdit } from "./Packs/PackEdit";
import { PaymentTypeEdit, PaymentTypeList } from "./paymentType";
import { ProductCreate, ProductEdit, ProductList } from "./product";
import { SectionCreate, SectionEdit, SectionList } from "./section";
import { OrderShow } from "./ShowOrder";
import { CoAdminCreate } from "./User/CoAdmin/CoAdminCreate";
import { CreateRetailer } from "./User/Retailer/CreateRetailer";
import { EditRetailer } from "./User/Retailer/EditRetailer";
import { UserList } from "./User/users";

const buildFieldsCustom = (type) => {
  let res = buildFields(type);
  if (type.name === "organization") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    res.push(
      gqlTypes.field(
        gqlTypes.name("organization_user"),
        null,
        null,
        null,
        gqlTypes.selectionSet([
          gqlTypes.field(gqlTypes.name("user_id")),
          gqlTypes.field(
            gqlTypes.name("user"),
            null,
            null,
            null,
            gqlTypes.selectionSet([
              gqlTypes.field(gqlTypes.name("name")),
              gqlTypes.field(gqlTypes.name("phone")),
              gqlTypes.field(gqlTypes.name("city")),
              gqlTypes.field(gqlTypes.name("state")),
              gqlTypes.field(gqlTypes.name("pincode")),
              gqlTypes.field(gqlTypes.name("role")),
              gqlTypes.field(gqlTypes.name("updated_at")),
            ])
          ),
        ])
      )
    );
    // res.push(
    //   gqlTypes.field(
    //     gqlTypes.name("users"),
    //     null,
    //     null,
    //     null,
    //     gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name("id")),
    //     gqlTypes.field(gqlTypes.name("name")),
    //     gqlTypes.field(gqlTypes.name("phone")),
    //     gqlTypes.field(gqlTypes.name("city")),
    //     gqlTypes.field(gqlTypes.name("state")),
    //     gqlTypes.field(gqlTypes.name("pincode"))])
    //   )
    // );
  }
  if (type.name === "user_management") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    res.push(
      gqlTypes.field(
        gqlTypes.name("users"),
        null,
        null,
        null,
        gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name("city"))])
      )
    );
  }
  if (type.name === "category") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    res.push(
      gqlTypes.field(
        gqlTypes.name("organization_categories"),
        null,
        null,
        null,
        gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name("org_id"))])
      )
    );
  }
  if (type.name === "user") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    res.push(
      gqlTypes.field(
        gqlTypes.name("shipping_addresses"),
        null,
        null,
        null,
        gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name("address"))])
      )
    );
  }
  if (type.name === "order") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    res.push(
      gqlTypes.field(
        gqlTypes.name("order_statuses"),
        // gqlTypes.name("order_total_amount"),
        null,
        null,
        null,
        gqlTypes.selectionSet([
          gqlTypes.field(gqlTypes.name("status")),
          gqlTypes.field(gqlTypes.name("created_at")),
          // gqlTypes.field(gqlTypes.name("amount")),
        ])
      )
    );
  }
  if (type.name === "pack") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    res.push(
      gqlTypes.field(
        gqlTypes.name("pack_discounts"),
        null,
        null,
        null,
        gqlTypes.selectionSet([
          gqlTypes.field(gqlTypes.name("amount")),
          gqlTypes.field(gqlTypes.name("class_id")),
          gqlTypes.field(
            gqlTypes.name("class"),
            null,
            null,
            null,
            gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name("name"))])
          ),
        ])
      )
    );
  }
  // if (type.name === "category") {
  //   // here we add additional fields we want to query for apps.
  //   // we are using the graphql-ast-types functions which is ast representation for graphql
  //   res.push(
  //     gqlTypes.field(
  //       gqlTypes.name("organization_categories"),
  //       null,
  //       null,
  //       null,
  //       gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name("org_id"))])
  //     )
  //   );
  // }
  if (type.name === "pack") {
    // here we add additional fields we want to query for apps.
    // we are using the graphql-ast-types functions which is ast representation for graphql
    const removedIndex = res.findIndex(
      (r) => r.name.value === "is_discount_available"
    );
    if (removedIndex >= 0) {
      res.splice(removedIndex, 1);
    }
  }
  // if(type.name === "user")
  return res;
};
const buildGqlQueryCustom = (iR) =>
  buildGqlQuery(
    iR,
    buildFieldsCustom,
    buildMetaArgs,
    buildArgs,
    buildApolloArgs
  );

const buildQuery = buildQueryFactory(
  buildVariables,
  buildGqlQueryCustom,
  getResponseParser
);

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }

  componentDidMount() {
    const client = new ApolloClient({
      uri: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        "x-schema-secret": "jgeMpaHKqZKgf5UYRIhRq",
        Authorization:
          `Bearer ` + localStorage.getItem("ssp_admin_access_token"),
      },
    });

    buildHasuraProvider({
      client,
      buildQuery,
    }).then((dataProvider) => this.setState({ dataProvider }));
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return (
        <div className="loader_blk loader_blk_max">
          <img src={loader} alt="" />
        </div>
      );
    }

    const client = new ApolloClient({
      uri: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        "x-schema-secret": "jgeMpaHKqZKgf5UYRIhRq",
        Authorization:
          `Bearer ` + localStorage.getItem("ssp_admin_access_token"),
      },
    });

    return (
      <ApolloProvider client={client}>
        <Admin
          layout={MyLayout}
          loginPage={LoginPage}
          dataProvider={addUploadFeature(dataProvider)}
          title="Demo"
          dashboard={Dashboards}
          authProvider={authProvider}
          customRoutes={CustomRoutes}
        >
          {(permissions) => [
            permissions === "orgadmin" ? (
              <Resource
                options={{ label: "User Management", isMenuParent: true }}
                icon={Group}
                name="user_management"
              />
            ) : null,
            permissions === "admin" && (
              <Resource
                options={{ label: "User Management" }}
                icon={Person}
                name="organization"
                list={OrganizationList}
                edit={OrganizationEdit}
                create={OrganizationCreate}
              />
            ),
            permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Product Management", isMenuParent: true }}
                name="product_management"
              />
            ) : null,
            permissions === "admin" ||
            permissions === "billing" ||
            permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Order Management", isMenuParent: true }}
                name="order_management"
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Settings", isMenuParent: true }}
                name="settings"
              />
            ) : null,
            permissions === "orgadmin" ? (
              <Resource
                options={{
                  label: "My Organization",
                  menuParent: "user_management",
                }}
                icon={SupervisedUserCircle}
                name="organization"
                list={OrganizationList}
                //edit={UserEdit}
                create={CoAdminCreate}
              />
            ) : null,
            permissions === "orgadmin" || permissions === "billing" ? (
              <Resource
                options={{ label: "Retailer", menuParent: "user_management" }}
                icon={Person}
                name="user"
                list={UserList}
                edit={EditRetailer}
                create={CreateRetailer}
              />
            ) : null,
            permissions === "admin" ? (
              <Resource icon={Person} name="user" />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource icon={Person} name="plan_type" />
            ) : null,
            permissions === "billing" ? (
              <Resource icon={Person} name="organization" />
            ) : null,
            permissions === "billing" ? (
              <Resource icon={Person} name="payment_type" />
            ) : null,
            // permissions === "admin" ? (
            //   <Resource
            //     options={{ label: "Chats", menuParent: "settings" }}
            //     icon={Telegram}
            //     name="chat"
            //     list={ChatList}
            //     edit={ChatEdit}
            //     create={ChatCreate}
            //   />
            // ) : null,
            // permissions === "admin" ? (
            //   // ||
            //   // permissions === "billing" ||
            //   // permissions === "orgadmin"
            //   <Resource
            //     options={{
            //       label: "Order Status",
            //       menuParent: "order_management",
            //     }}
            //     icon={LocalShipping}
            //     name="order_status"
            //     list={OrderStatusList}
            //     edit={OrderStatusEdit}
            //     create={OrderStatusCreate}
            //   />
            // ) : null,
            // permissions === "admin" ? (
            //   // permissions === "billing" ||
            //   // permissions === "orgadmin"
            //   <Resource
            //     options={{
            //       label: "Shipping Address",
            //       menuParent: "order_management",
            //     }}
            //     icon={Place}
            //     name="shipping_address"
            //     list={ShippingAddressList}
            //     edit={ShippingAddressEdit}
            //     create={ShippingAddressCreate}
            //   />
            // ) : null,
            // permissions === "admin" ? (
            //   <Resource
            //     options={{
            //       label: "Product Order",
            //       menuParent: "order_management",
            //     }}
            //     icon={Assignment}
            //     name="product_order"
            //     list={ProductOrderList}
            //     edit={ProductOrderEdit}
            //     create={ProductOrderCreate}
            //   />
            // ) : null,
            // permissions === "admin" ? (
            //   <Resource
            //     options={{
            //       label: "Cart",
            //       menuParent: "order_management",
            //     }}
            //     icon={AddShoppingCart}
            //     name="cart"
            //     list={CartList}
            //     edit={CartEdit}
            //     create={CartCreate}
            //   />
            // ) : null,
            // permissions === "admin" ? (
            //   <Resource
            //     options={{ label: "Notification", menuParent: "settings" }}
            //     icon={NotificationsActive}
            //     name="notifications"
            //     list={NotificationList}
            //     edit={NotificationEdit}
            //     create={NotificationCreate}
            //   />
            // ) : null,
            permissions === "admin" ||
            permissions === "billing" ||
            permissions === "orgadmin" ? (
              <Resource
                options={{
                  label: "Orders",
                  menuParent: "order_management",
                }}
                icon={Inbox}
                name="order"
                list={OrderList}
                edit={OrderEdit}
                show={OrderShow}
                // create={OrderCreate}
              />
            ) : null,
            permissions === "admin" ? (
              <Resource
                options={{
                  label: "Favorite Product",
                  menuParent: "product_management",
                }}
                icon={Favorite}
                name="favorite_product"
                list={FavoriteProductList}
                edit={FavoriteProductEdit}
                create={FavoriteProductCreate}
              />
            ) : null,
            // permissions === "admin" ? (
            //   <Resource
            //     options={{
            //       label: "User Product",
            //       menuParent: "product_management",
            //     }}
            //     icon={Contacts}
            //     name="user_product_class"
            //     list={UserProductClassList}
            //     edit={UserProductClassEdit}
            //     create={UserProductClassCreate}
            //   />
            // ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Brands", menuParent: "product_management" }}
                icon={LocalOffer}
                name="brand"
                list={BrandList}
                edit={BrandEdit}
                create={BrandCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{
                  label: "Categories",
                  menuParent: "product_management",
                }}
                icon={Category}
                name="category"
                list={CategoryList}
                edit={CategoryEdit}
                create={permissions === "admin" ? "" : CategoryCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Section", menuParent: "product_management" }}
                icon={GroupWork}
                name="section"
                list={SectionList}
                edit={SectionEdit}
                create={permissions === "admin" ? "" : SectionCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{
                  label: "Discount Type",
                  menuParent: "settings",
                }}
                icon={Class}
                name="class"
                list={ClassList}
                edit={ClassEdit}
                create={permissions === "orgadmin" && ClassCreate}
              />
            ) : null,
            permissions === "admin" ? (
              <Resource
                options={{
                  label: "Organization User",
                  menuParent: "user_management",
                }}
                icon={Group}
                name="organization_user"
                list={OrganizationUserList}
                edit={OrganizationUserEdit}
                create={OrganizationUserCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Payments", menuParent: "settings" }}
                icon={AccountBalance}
                name="payment_type"
                list={PaymentTypeList}
                edit={PaymentTypeEdit}
                // create={PaymentTypeCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{
                  label: "Product",
                  menuParent: "product_management",
                }}
                icon={CardTravel}
                name="product"
                list={ProductList}
                edit={ProductEdit}
                create={ProductCreate}
              />
            ) : null,
            // permissions === "admin" || permissions === "orgadmin" ? (
            //   <Resource
            //     options={{
            //       label: "Product Category",
            //       menuParent: "product_management",
            //     }}
            //     icon={CardGiftcard}
            //     name="product_category"
            //     list={ProductCategoryList}
            //     edit={ProductCategoryEdit}
            //     create={ProductCategoryCreate}
            //   />
            // ) : null,

            permissions === "admin" ? (
              <Resource
                options={{
                  label: "Organization Category",
                  menuParent: "user_management",
                }}
                icon={PermIdentity}
                name="organization_category"
                list={OrganizationCategoryList}
                edit={OrganizationCategoryEdit}
                create={OrganizationCategoryCreate}
              />
            ) : null,
            // permissions === "admin" || permissions === "orgadmin" ? (
            //   <Resource
            //     options={{
            //       label: "Product Section",
            //       menuParent: "product_management",
            //     }}
            //     icon={TouchApp}
            //     name="product_section"
            //     list={ProductSectionList}
            //     edit={ProductSectionEdit}
            //     create={ProductSectionCreate}
            //   />
            // ) : null,

            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Packs", menuParent: "product_management" }}
                icon={Class}
                name="pack"
                list={PackList}
                edit={PackEdit}
                create={PackCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Measurements", menuParent: "settings" }}
                icon={GridOn}
                name="measurement"
                list={MeasurementList}
                edit={MeasurementEdit}
                create={permissions === "orgadmin" && MeasurementCreate}
              />
            ) : null,
            permissions === "admin" || permissions === "orgadmin" ? (
              <Resource
                options={{ label: "Schemes", menuParent: "settings" }}
                icon={Flare}
                name="banners"
                list={BannerList}
                edit={BannerEdit}
                create={permissions === "admin" ? "" : BannerCreate}
              />
            ) : null,
          ]}
        </Admin>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#ffffff",
            fontSize: "0.8rem",
            padding: "0.5rem 0",
          }}
        >
          Copyright © {new Date().getFullYear()}, Supply Spring | Version 1.0
        </div>
      </ApolloProvider>
    );
  }
}

export default App;
