import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useLazyQuery } from "@apollo/client";
import { GET_PAYMENT_DUE } from "./GraphQL/queries";
import moment from "moment";
import { useRedirect } from "react-admin";
import loader from "./Assets/Images/loader.svg";
import CancelIcon from "@material-ui/icons/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export const PaymentModal = ({ organizationId }) => {
  const modalState = localStorage.getItem("modal_off");
  const redirect = useRedirect();
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [paymentStatus, setPaymentStatus] = React.useState("");
  const [GET_PAYMENT_DUE_QUERY, { data: paymentDue, loading: paymentLoading }] =
    useLazyQuery(GET_PAYMENT_DUE, {
      fetchPolicy: "cache-and-network",
    });

  React.useEffect(() => {
    if (organizationId) {
      GET_PAYMENT_DUE_QUERY({
        variables: {
          org_id: organizationId,
        },
      });
    }
    if (paymentDue) {
      setStartDate(paymentDue?.payment_due[0]?.start_date);
      setPaymentStatus(paymentDue?.payment_due[0]?.status);
    }
  }, [organizationId, paymentDue]);

  React.useEffect(() => {
    if (
      !modalState &&
      moment(startDate).isSameOrBefore(moment()) &&
      paymentStatus === "Pending"
    ) {
      setOpen(true);
    }
  }, [paymentStatus, startDate]);

  if (!paymentDue && paymentLoading) {
    return (
      <div className="loader_blk loader_blk_max">
        <img src={loader} alt="" />
      </div>
    );
  }
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("modal_off", true);
  };
  const handleRedirect = () => {
    redirect("/subscription-details");
    localStorage.setItem("modal_off", true);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="closePopup">
            <CancelIcon className="" onClick={handleClose} />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Your Payment is due on {moment(startDate).format("MMMM Do YYYY")}
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Button variant="contained" onClick={handleRedirect}>
              Click here to pay
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
