// in src/MyLoginPage.js
import React, { useState } from "react";
import { useLogin, useNotify, useRedirect } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import parsePhoneNumber from "libphonenumber-js";
import logo from "./Assets/Images/logo_black.svg";
import { Button } from "@material-ui/core";

const LoginPage = ({ theme }) => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();

  const submit = (e) => {
    e.preventDefault();
    if (!phone || !password || isNaN(phone)) {
      alert("Please enter valid phone and password");
      return;
    }
    // call the login callback returned by the useLogin hook
    const phoneNumber = parsePhoneNumber(`+91${phone}`);
    const phoneN = parseInt(phoneNumber.nationalNumber);
    login({ password, phoneN, app_id: "admin" })
      .then((result) => {
        redirect("/");
      })
      .catch(() => alert("Invalid email or password"));
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="loginSection">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="loginContainer">
              <div className="authLogo">
                <img src={logo} alt="" />
              </div>

              <input
                name="phone"
                type="input"
                value={phone}
                placeholder="Enter mobile number"
                className="form-control"
                maxLength="10"
                onChange={(e) => setPhone(e.target.value.trim())}
              />
              <form onSubmit={submit}>
                <input
                  name="code"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  className="form-control"
                  onChange={(e) => setPassword(e.target.value.trim())}
                />
                <div className="text-center">
                  <Button type="submit" variant="contained" color="primary">
                    Login
                  </Button>
                </div>
              </form>
              <Button type="link" onClick={() => redirect("/forgot-password")}>
                Forgot Password ?
              </Button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default LoginPage;
