import { Column as ColumnChart } from "@ant-design/charts";
import { useLazyQuery } from "@apollo/client";
import React from "react";
import {
  GET_ORDER_REVENUE_FOR_DATE_RANGE,
  GET_ORDER_REVENUE_FOR_ORGANIZATION,
} from "./GraphQL/queries";

const RevenueDashboardChart = ({ startDate, endDate, org_id, org_list }) => {
  const userRole = localStorage.getItem("ssp_admin_role");
  const [formattedRevenueReportData, setFormattedRevenueReportData] =
    React.useState([]);

  const [GET_REVENUE_REPORT, { data: revenueChartData }] = useLazyQuery(
    GET_ORDER_REVENUE_FOR_DATE_RANGE,
    {
      fetchPolicy: "network-only",
    }
  );
  const [
    GET_ORDER_REVENUE_FOR_ORGANIZATION_REPORT,
    { data: revenueOrgChartData },
  ] = useLazyQuery(GET_ORDER_REVENUE_FOR_ORGANIZATION, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (userRole === "orgadmin") {
      GET_REVENUE_REPORT({
        variables: {
          startDate,
          endDate,
          org_id: org_id ?? undefined,
        },
      });
    }
    GET_ORDER_REVENUE_FOR_ORGANIZATION_REPORT({
      variables: {
        startDate,
        endDate,
        org_id: org_list?.length ? org_list : org_id ?? undefined,
      },
    });
  }, [startDate, endDate, org_id, org_list]);

  React.useEffect(() => {
    if (revenueChartData) {
      const formattedChartData = revenueChartData.user
        .filter((v) => v.orders.length > 0)
        .flatMap((val) => {
          const totalRevenue = val.orders.reduce((accumulator, current) => {
            return accumulator + current.order_total_discounted_amount;
          }, 0);
          return [
            {
              name: val.name,
              revenue: parseFloat(totalRevenue.toFixed(2)),
            },
          ];
        })
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5);
      setFormattedRevenueReportData(formattedChartData);
    }
  }, [revenueChartData]);

  React.useEffect(() => {
    if (revenueOrgChartData) {
      const formattedChartData = revenueOrgChartData.organization
        .flatMap((org) => {
          const totalRevenue = org.orders.reduce((accumulator, current) => {
            return accumulator + current.order_total_discounted_amount;
          }, 0);
          return [
            {
              name: org.name,
              revenue: parseFloat(totalRevenue.toFixed(2)),
            },
          ];
        })
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5);
      setFormattedRevenueReportData(formattedChartData);
    }
  }, [revenueOrgChartData]);

  if (formattedRevenueReportData.length === 0) {
    return <div className="loader_blk">No Revenues generated</div>;
  }

  return (
    <ColumnChart
      data={formattedRevenueReportData}
      xField="name"
      yField="revenue"
      xAxis={{ label: { autoHide: false, autoEllipsis: true } }}
      label={{
        position: "middle",
        formatter: ({ revenue }) => {
          return new Intl.NumberFormat("en-IN").format(revenue);
        },
      }}
    />
  );
};

export default RevenueDashboardChart;
