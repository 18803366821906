import React, { useState, useEffect } from "react";
import { useNotify, useRedirect, useGetIdentity } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import { CHANGE_PASSWORD } from "./GraphQL/mutation";
import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";

const ChangePassword = ({ theme }) => {
  const getUserId = useGetIdentity();

  const [newPassword, setNewPassword] = useState("");
  const [password, setPassword] = useState("");

  const [CHANGE_PASSWORD_MUTAION] = useMutation(CHANGE_PASSWORD);

  const notify = useNotify();
  const redirect = useRedirect();

  const submit = async (event) => {
    event.preventDefault();
    if (password === newPassword) {
      const res = await CHANGE_PASSWORD_MUTAION({
        variables: {
          user_id: getUserId.identity,
          password,
        },
      });
      if (!res.errors) {
        notify(
          res?.changePassword?.message
            ? res?.changePassword?.message
            : "Password Changed!"
        );
        redirect("/");
      } else {
        console.log("error", res.error);
      }
    } else {
      notify("The two passwords that you entered does not match!");
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="loginSection">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="changepasswordContainer">
              <span className="changepassword">Change Password</span>
              <form id="changepasswordform" onSubmit={submit}>
                <div>
                  <input
                    name="newPassword"
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    className="form-control"
                    onChange={(e) => setNewPassword(e.target.value.trim())}
                  />
                  <input
                    name="password"
                    type="password"
                    value={password}
                    placeholder="Confirm password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value.trim())}
                  />
                </div>
                <div className="text-center">
                  <Button
                    disabled={!newPassword || !password}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Change Password
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default ChangePassword;
