import React, { useState } from "react";
import { useNotify, useRedirect } from "react-admin";
import { FORGOT_PASSWORD } from "./GraphQL/mutation";
import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const [FORGOT_PASSWORD_MUTAION] = useMutation(FORGOT_PASSWORD);

  const notify = useNotify();
  const redirect = useRedirect();

  const submit = async (event) => {
    event.preventDefault();
    try {
      if (email) {
        const res = await FORGOT_PASSWORD_MUTAION({
          variables: {
            email,
          },
        });
        if (!res.errors) {
          redirect("/login");
          notify("Email has been sent to your entred email address!");
        } else {
          console.log(res?.errors);
        }
      } else {
        notify("Please enter proper email address");
      }
    } catch (e) {
      alert(e);
    }
  };

  return (
    <div className="loginSection">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="changepasswordContainer">
          <span className="changepassword">Forgot Password ?</span>
          <form id="forgotPasswordForm" onSubmit={submit}>
            <div>
              <input
                name="email"
                type="email"
                placeholder="Enter your valid email"
                value={email}
                className="form-control"
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="text-center ">
              <Button
                disabled={!email}
                type="submit"
                color="primary"
                variant="contained"
              >
                Send
              </Button>
              <Button
                className="back-button"
                variant="outlined"
                color="info"
                onClick={() => redirect("/login")}
              >
                Back
              </Button>
            </div>
          </form>
          <div style={{ marginTop: 10 }}>
            Check your email address after sending request.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
