import { useLazyQuery } from "@apollo/client";
import { Card, CardContent, Grid, Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { Title, useRedirect, TopToolbar } from "react-admin";
import { GET_RETAILER_REPORT } from "./GraphQL/queries";
import jsPDF from "jspdf";
require('jspdf-autotable');
const userRole = localStorage.getItem("ssp_admin_role");
const RetailerReport = () => {
  const [reportFilter, setReportFilter] = React.useState({
    startDate: moment().startOf("day").subtract(7, "days").format(),
    endDate: moment().startOf("minutes").format(),
    org_id: "",
    name: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [reportTableData, setReportTableData] = React.useState([]);
  const [sorting, setSorting] = React.useState([
    { field: "totalRevenue", sort: "desc" },
  ]);
  const redirect = useRedirect();

  const [UPDATE_REPORT_DATA, { loading, data }] =
    useLazyQuery(GET_RETAILER_REPORT);

  React.useEffect(() => {
    setIsLoading(true);
    UPDATE_REPORT_DATA({
      variables: {
        startDate: reportFilter.startDate ?? undefined,
        endDate: reportFilter.endDate ?? undefined,
        org_id: reportFilter.org_id ? reportFilter.org_id : undefined,
        name: reportFilter.name ? `%${reportFilter.name}%` : undefined,
      },
    });
  }, [reportFilter]);

  const handleSearch = (value) => {
    setReportFilter({ name: value });
  };
  const handlePrint = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFont("Helvetica");
    doc.setFontSize(11);
    console.log(doc.getFont())
    const title = "My Awesome Report";
    const headers = [["Name", "Total Liters", "Total Orders", "Total Revenue"]];

    const data = reportTableData.map(elt=> [elt.name, 
      `${elt.litreQty}`, `${elt.orderQty}`,elt.totalRevenue.toFixed(2)

    ]);
    data.sort(function(a, b){return b[3] - a[3]});
    console.log(data)
    let content = {
      startY: 60,
      head: headers,
      body: data,
      columnStyles: {
        0: {halign: 'left', cellWidth: 180,},
        1: {halign: 'left', cellWidth: 140,},
        2: {halign: 'right', cellWidth: 80,},
        3: {halign: 'right', cellWidth: 100,}
    },
      headStyles :{lineWidth: 1,fillColor: [224,224,224], textColor: [0, 0, 0]},
    };
  
    let getContent = "<div style='display:flex;justify-content:center;padding: 05px 15px; width:565px;font-family:monospace'>  \
    <h3>Retailer Report</h3>\
    </div>";
    console.log(getContent)
    console.log('Show all font in jsPDF',doc.getFontList());

    doc.html(getContent, {
      // margin: [0, 0, 10, 10],
      callback: function(doc) {
        
        console.log("in callback");
        // doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
      }
    });
    
  
  };

  React.useEffect(() => {
    if (data) {
      const QTY = data.user
        .map((abc) =>
          data.user
            .flatMap((u) =>
              u?.orders?.flatMap((p) =>
                p.product_orders?.map((po) => ({
                  total:
                    po.pack.carton_size *
                    (["ML", "Gram"].includes(po?.pack?.measurement?.name)
                      ? parseFloat(po?.pack?.pack_size) / 1000
                      : parseFloat(po?.pack?.pack_size)) *
                    po.quantity,
                  measurement: po?.pack?.measurement?.name,
                  user: u,
                }))
              )
            )
            .filter((usr) => usr?.user?.id === abc?.id)
        )
        .map((total) => ({
          msg: `${
            total.filter((qty) =>
              ["ml", "litre"].includes(qty?.measurement?.toLowerCase())
            ).length > 0
              ? total
                  .filter((qty) =>
                    ["ml", "litre"].includes(qty?.measurement?.toLowerCase())
                  )
                  .map((t) => t.total)
                  .reduce((tot1, tot2) => tot1 + tot2)
              : 0
          } Litre and ${
            total.filter((qty) =>
              ["kg", "gram"].includes(qty.measurement?.toLowerCase())
            ).length > 0
              ? total
                  .filter((qty) =>
                    ["kg", "gram"].includes(qty.measurement?.toLowerCase())
                  )
                  .map((t) => t.total)
                  .reduce((tot1, tot2) => tot1 + tot2)
              : 0
          } Kgs`,
          user: total.length > 0 ? total[0].user : null,
        }));

      const formattedData = data.user.flatMap((u) => {
        const orderQty = u?.orders.reduce((acc, curr) => {
          let latestStatus = curr.order_statuses.reduce((prev, current) => {
            return moment(prev?.created_at).isAfter(moment(current?.created_at))
              ? prev
              : current;
          });
          if (latestStatus.status !== "rejected") {
            acc += 1;
          }
          return acc;
        }, 0);
        const totalRevenue = u.orders?.reduce((acc, curr) => {
          let latestStatus = curr.order_statuses?.reduce(
            (prev, currentValue) => {
              return moment(prev?.created_at).isAfter(
                moment(currentValue?.created_at)
              )
                ? prev
                : currentValue;
            }
          );
          if (latestStatus.status === "completed") {
            let revenueTotal = curr?.order_total_discounted_amount;
            return acc + revenueTotal;
          }
          return acc;
        }, 0);
        return [
          {
            name: u.name,
            litreQty: QTY.find((get) => get?.user?.id === u.id)?.msg
              ? QTY.find((get) => get?.user?.id === u.id)?.msg
              : "0 Litre and 0 Kg",
            orderQty,
            totalRevenue: parseFloat(totalRevenue.toFixed(2)),
            id: u.id,
            action: "View",
            org_id: u?.organization_users[0]?.organization?.id,
            org_name: u?.organization_users[0]?.organization?.name
          },
        ]
      });
      console.log(formattedData)
      const adminArr = [];
      const adminFilteredArr = [];
      if(userRole === 'admin' && !reportFilter?.org_id){
        formattedData.map((itm) => {
          if(itm?.org_id){
          let org_index = adminArr.map(e => e.id).indexOf(itm.org_id);
          if(org_index > -1){
            const obj = {}
            obj.name = itm.org_name
            obj.id = itm.org_id;
            let arr = itm.litreQty.replace(/and/g,',');
            let val = arr.split(',');
            let lit = adminArr[org_index].lit + parseInt(val[0].replace(/[^0-9]/g,''));
            let kg = adminArr[org_index].kg + parseInt(val[1].replace(/[^0-9]/g,''));
            obj.lit = lit;
            obj.kg = kg;
            obj.orderQty = adminArr[org_index].orderQty + itm.orderQty;
            obj.litreQty = `${lit} Liter and ${kg} Kgs`;
            obj.action = "View";
            obj.totalRevenue = parseFloat(adminArr[org_index].totalRevenue.toFixed(2)) + parseFloat(itm.totalRevenue.toFixed(2))
            adminArr[org_index] = (obj)
          } else {
            const obj = {}
            obj.name = itm.org_name
            obj.id = itm.org_id;
            let arr = itm.litreQty.replace(/and/g,',');
            let val = arr.split(',');
            let lit = parseInt(val[0].replace(/[^0-9]/g,''));
            let kg = parseInt(val[1].replace(/[^0-9]/g,''));
            obj.lit = lit;
            obj.kg = kg;
            obj.action = "View";
            obj.litreQty = `${lit} Liter and ${kg} Kgs`;
            obj.orderQty = itm.orderQty;
            obj.totalRevenue = parseFloat(itm.totalRevenue.toFixed(2))
            adminArr.push(obj)
          }
          }
        })
      }
      console.log('adminArr ==>', adminArr)
      if(userRole === 'admin' && !reportFilter?.org_id) {
        setReportTableData(adminArr)
      } else {
      setReportTableData(formattedData);
      }
      setIsLoading(false);
    }
  }, [data]);

  return (
    <Card>
      <Title title="Route" />
      <CardContent>
        <div className="dashboard-flex">
          <Grid container spacing={3}>
            <Grid item md={8} sm={12} xs={12}>
              <h3>Retailer Report</h3>
            </Grid>
            <Grid
              justify="flex-end"
              item
              md={2}
              sm={12}
              xs={12}
              className="dashboard-flex"
            >
              <input
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value.trim())}
                value={reportFilter.name}
              />
            </Grid>
            <Grid
              justify="flex-end"
              item
              md={2}
              sm={12}
              xs={12}
              className="dashboard-flex"
            >
              <select
                onChange={(e) => {
                  setReportFilter(JSON.parse(e.target.value.trim()));
                }}
                value={JSON.stringify(reportFilter)}
              >
                <option
                  value={JSON.stringify({
                    startDate: moment()
                      .startOf("day")
                      .subtract(7, "days")
                      .format(),
                    endDate: moment().startOf("minutes").format(),
                  })}
                >
                  Last 7 Days
                </option>
                <option
                  value={JSON.stringify({
                    startDate: moment()
                      .startOf("day")
                      .subtract(15, "days")
                      .format(),
                    endDate: moment().startOf("minutes").format(),
                  })}
                >
                  Last 15 Days
                </option>
                <option
                  value={JSON.stringify({
                    startDate: moment()
                      .startOf("day")
                      .subtract(1, "months")
                      .format(),
                    endDate: moment().startOf("minutes").format(),
                  })}
                >
                  Last 1 Month
                </option>
                <option
                  value={JSON.stringify({
                    startDate: moment()
                      .startOf("day")
                      .subtract(3, "months")
                      .format(),
                    endDate: moment().startOf("minutes").format(),
                  })}
                >
                  Last 3 Months
                </option>
                <option
                  value={JSON.stringify({
                    startDate: null,
                    endDate: null,
                  })}
                >
                  All
                </option>
              </select>
            </Grid>
          </Grid>
        </div>
        <TopToolbar>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={handlePrint}
        >
          Print
        </Button>
      </TopToolbar>
        <Grid>
          <DataGrid
            autoHeight
            headerHeight={30}
            rowHeight={52}
            loading={isLoading || loading}
            rows={reportTableData}
            columns={[
              { field: "name", headerName: "Name", width: 700 },
              { field: "litreQty", headerName: "Total Litres", width: 150 },
              { field: "orderQty", headerName: "Total Orders", width: 150 },
              {
                field: "totalRevenue",
                headerName: "Total Revenue",
                width: 150,
              },
              {
                field: "action",
                headerName: "Action",
                width: 150,
                sortable: false,
              },
            ]}
            sortModel={sorting}
            onSortModelChange={setSorting}
            pageSize={10}
            disableSelectionOnClick
            onRowClick={(r) => {
              if(userRole === 'admin' && !reportFilter?.org_id){
                setReportFilter({org_id: r.id,startDate: moment().startOf("day").subtract(7, "days").format(),
                endDate: moment().startOf("minutes").format()})
              } else if (!!reportFilter.startDate && !!reportFilter.endDate) {
                redirect(
                  `/order?filter={"ordered_by"%3A"${
                    r.id
                  }"%2C"created_at%40_gte"%3A"${moment(
                    reportFilter.startDate
                  ).toISOString()}"%2C"created_at%40_lt"%3A"${moment(
                    reportFilter.endDate
                  ).toISOString()}"}&order=ASC&page=1&perPage=10&sort=order_id`
                );
              } else {
                redirect(
                  `/order?filter={"ordered_by"%3A"${r.id}"}&order=ASC&page=1&perPage=10&sort=order_id`
                );
              }
            }}
            className="retailerReportTable"
          ></DataGrid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RetailerReport;
