import * as React from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";

const OrganizationUserTitle = ({ record }) => {
  return (
    <span>
      OrganizationUser
      {/* {" "} */}
      {/* {record ? `"${record.firstname} ${record.lastname}"` : ""} */}
    </span>
  );
};

const OrganizationUserFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name@_eq,id" alwaysOn />
    <ReferenceInput
      perPage={500}
      source="user_id"
      reference="user"
      label="Select User"
    >
      <AutocompleteInput source="user_id" />
    </ReferenceInput>
    <ReferenceInput
      source="org_id"
      reference="organization"
      label="Organizations"
    >
      <AutocompleteInput source="org_id" />
    </ReferenceInput>
  </Filter>
);

export const OrganizationUserList = (props) => {
  return (
    <List exporter={false} filters={<OrganizationUserFilter />} {...props}>
      <Datagrid>
        <ReferenceField source="user_id" reference="user" label="Selected User">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="org_id"
          reference="organization"
          label="Select Organization"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const OrganizationUserEdit = (props) => (
  <Edit title={<OrganizationUserTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        perPage={500}
        source="user_id"
        reference="user"
        label="Select User"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Select Organization"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const OrganizationUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <TextInput
        source="id"
        defaultValue={React.useMemo(() => uuid(), [])}
        disabled
      /> */}
      <ReferenceInput
        perPage={500}
        source="user_id"
        reference="user"
        label="Select User"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Select Organization"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
