import React, { useState } from "react";
import { CHANGE_ORDER_STATUS } from "./GraphQL/mutation";
import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import { useNotify, useRedirect } from "react-admin";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import CancelIcon from "@material-ui/icons/Cancel";

export const StatusButton = ({ status, orderId }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [newStatus, setNewStatus] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [reason, setReason] = useState("");
  const [CHANGE_ORDER_STATUS_MUTAION, { data: changeStatus }] =
    useMutation(CHANGE_ORDER_STATUS);

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    const response = await CHANGE_ORDER_STATUS_MUTAION({
      variables: {
        status: newStatus,
        order_id: orderId,
        reason: reason ? reason : "",
      },
    }).catch((err) => {
      if (err) {
        notify("Network Error");
      }
    });
    if (!response.error) {
      notify("Order Status Updated Successfully");
      redirect("/order");
    }
  };

  const ButtonStatus = () => {
    switch (status) {
      case "new":
        return (
          <Stack spacing={2} direction="row">
            <Button
              sx={{ mr: "2" }}
              color="secondary"
              variant="outlined"
              value="rejected"
              onClick={handleStatusChange}
            >
              Reject
            </Button>
            <Button
              color="primary"
              variant="contained"
              value="in_billing"
              onClick={handleStatusChange}
            >
              Accept
            </Button>
          </Stack>
        );
      case "in_billing":
        return (
          <Stack spacing={2} direction="row">
            <Button
              value="new"
              color="secondary"
              variant="outlined"
              onClick={handleStatusChange}
            >
              Move back to New
            </Button>
            <Button
              color="primary"
              variant="contained"
              value="dispatched"
              onClick={handleStatusChange}
            >
              Dispatch
            </Button>
          </Stack>
        );
      case "dispatched":
        return (
          <Stack spacing={2} direction="row">
            <Button
              color="secondary"
              variant="outlined"
              value="in_billing"
              onClick={handleStatusChange}
            >
              Move back to billing
            </Button>
            <Button
              color="primary"
              variant="contained"
              value="completed"
              onClick={handleStatusChange}
            >
              Complete
            </Button>
          </Stack>
        );
      case "completed":
        return (
          <Button variant="contained" color="success">
            Order completed
          </Button>
        );
      case "rejected":
        return (
          <Button variant="contained" color="success">
            Order rejected
          </Button>
        );

      default:
        return <div>{status ? status : ""}</div>;
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      {ButtonStatus()}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="closePopup">
            <CancelIcon className="" onClick={() => setModalOpen(false)} />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to change the current status?
          </Typography>
          {newStatus === "rejected" && (
            <TextField
              id="standard-basic"
              label="Reason"
              variant="standard"
              onChange={(e) => setReason(e.target.value.trim())}
            />
          )}
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
