import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import * as React from "react";
import { GET_ORDER_DETAILS_BY_ID } from "./GraphQL/queries";
import { StatusButton } from "./StatusButton";
import { useRedirect, TopToolbar } from "react-admin";
import Button from "@material-ui/core/Button";
const ChangeOrderStatus = ({ match }) => {
  const dateDisplayOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const { id } = match.params;
  const [userDetails, setUserDetails] = React.useState({});
  const [shippingAddress, setShippingAddress] = React.useState({});
  const [orderId, setOrderId] = React.useState("");
  const [paymnetType, setPaymnetType] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState("");
  const [orderStatus, setOrderStatus] = React.useState("");
  const [orderDetails, setOrderDetails] = React.useState([]);
  const { data: orderData, loading: orderLoading } = useQuery(
    GET_ORDER_DETAILS_BY_ID,
    {
      variables: { id },
    },
    { fetchPolicy: "network-only" }
  );

  const getOrderData = (orders) => {
    if (!orders) {
      return "";
    }
    const liquidCalculation = orders
      .flatMap((p) => {
        if (
          ["litre", "ml"].includes(p?.pack?.measurement?.name.toLowerCase())
        ) {
          const packSizeInLitre =
            p.pack.measurement.name.toLowerCase() === "ml"
              ? parseFloat(p.pack.pack_size) / 1000
              : parseFloat(p.pack.pack_size);
          const totalSize =
            parseFloat(p.quantity) *
            parseFloat(p.pack.carton_size) *
            packSizeInLitre;
          return [totalSize];
        }
      })
      .filter((v) => !!v);
    const weightCalculation = orders
      .flatMap((p) => {
        if (["gram", "kg"].includes(p.pack.measurement?.name?.toLowerCase())) {
          const packSizeInLitre =
            p.pack.measurement.name.toLowerCase() === "gram"
              ? parseFloat(p.pack.pack_size) / 1000
              : parseFloat(p.pack.pack_size);
          const totalSize =
            parseFloat(p.quantity) *
            parseFloat(p.pack.carton_size) *
            packSizeInLitre;
          return [totalSize];
        }
      })
      .filter((v) => !!v);
    let orderItemsInKgs = 0;
    let orderItemsInLitres = 0;
    if (weightCalculation.length > 0) {
      orderItemsInKgs = weightCalculation.reduce((a, c) => a + c);
    }
    if (liquidCalculation.length > 0) {
      orderItemsInLitres = liquidCalculation.reduce((a, c) => a + c);
    }
    let message;
    if (orderItemsInLitres && orderItemsInKgs) {
      message = `${orderItemsInLitres} Litres and ${orderItemsInKgs} Kgs`;
    } else if (orderItemsInLitres) {
      message = `${orderItemsInLitres} Litres`;
    } else if (orderItemsInKgs) {
      message = `${orderItemsInKgs} Kgs`;
    }
    return message;
  };

  React.useEffect(() => {
    if (orderData && !orderLoading) {
      setOrderId(orderData?.order[0].order_id);
      setUserDetails({
        name: orderData?.order[0]?.user?.name,
        city: orderData?.order[0]?.user?.city,
        phone: orderData?.order[0]?.user?.phone,
        created_at: orderData?.order[0]?.created_at,
      });
      setShippingAddress({
        name: orderData?.order[0]?.shipping_address?.name,
        address: orderData?.order[0]?.shipping_address?.address,
        city: orderData?.order[0]?.shipping_address?.city,
        state: orderData?.order[0]?.shipping_address?.state,
        country: orderData?.order[0]?.shipping_address?.country,
        pincode: orderData?.order[0]?.shipping_address?.pincode,
      });
      setOrderDetails(orderData?.order[0]?.product_orders);
      setPaymnetType(orderData?.order[0]?.payment_type?.title);
      setTotalAmount(orderData?.order[0]?.order_total_discounted_amount);
      setOrderStatus(orderData?.order[0]?.order_statuses[0]?.status);
    }
  }, [orderData]);

  if (orderLoading) {
    <span>Loading...</span>;
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    width: 800,
  };

  const getHeadingText = () => {
    switch (orderStatus) {
      case "new":
        return "NEW";
      case "in_billing":
        return "IN BILLING";
      case "dispatched":
        return "DISPATCHED";
      case "completed":
        return "COMPLETED";
      case "rejected":
        return "REJECTED";
      default:
        return <strong> {orderStatus ? orderStatus : ""}</strong>;
    }
  };
  const redirect = useRedirect();
  const backAction = () => {
    redirect("/order");
  };
  return (
    <div className="showOrderContainer editOrderContainer">
      <TopToolbar>
        <Button variant="outlined" color="primary" onClick={backAction}>
          Back
        </Button>
      </TopToolbar>
      <Box sx={style}>
        <div className="invoice">
          <div className="orderCard">
            <div className="editOrderId">
              <h1>
                Order-Id <small className="orderCode">({orderId})</small>
              </h1>
              <h2>Current Status - {getHeadingText()}</h2>
            </div>
            <div className="orderTitle">
              <div className="invoiceTitle">
                <strong>Ordered on-</strong>
                <div className="createDate">
                  {new Date(userDetails.created_at).toLocaleDateString(
                    "en-US",
                    dateDisplayOptions
                  )}
                </div>
              </div>
              <div className="statusDropdown">
                <StatusButton status={orderStatus} orderId={id} />
              </div>
            </div>
            <div className="allOrderAddress">
              <div className="billTo">
                <div className="userDetail">{userDetails.name}</div>
                <div className="infoflex">
                  <div className="userLocation">
                    {userDetails.city}
                    <div className="createDate">{userDetails.phone}</div>
                  </div>
                </div>
              </div>
              <div className="billFrom">
                <div>
                  <h4>Delivery Address</h4>
                  <p>{shippingAddress.name}</p>
                  <address>
                    {shippingAddress.address}, {shippingAddress.city},{" "}
                    {shippingAddress.state}, {shippingAddress.country},
                    {shippingAddress.pincode}
                  </address>
                </div>
              </div>
            </div>
            <table
              width="100%"
              border="0"
              cellSpacing="0"
              className="MuiPaper-root"
            >
              <thead align="justify" valign="bottom" char="M">
                <tr>
                  <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                    Product Name
                  </th>
                  <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                    Unit / Pack
                  </th>
                  <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                    Qty
                  </th>
                  <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                    Order Size
                  </th>
                  <th className="MuiTableCell-sizeSmall MuiTableCell-head">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody border="1">
                {orderDetails.map((product_order, index) => {
                  return (
                    <tr>
                      <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                        {product_order.pack.product.name}
                      </td>
                      {product_order.pack.measurement ? (
                        <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                          {`${product_order.pack.pack_size} ${product_order.pack.measurement.name} / ${product_order.pack.carton_size}`}
                        </td>
                      ) : (
                        <></>
                      )}
                      <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                        {product_order.quantity}
                      </td>
                      <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                        {getOrderData([product_order])}
                      </td>
                      <td className="MuiTableCell-sizeSmall MuiTableCell-root">
                        {product_order.total_discounted_amount.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <tfoot>
                <tr>
                  <th>
                    <div className="paymentInfo">
                      <strong>Notes</strong>
                      <span>Payment Type - {paymnetType}</span>
                    </div>
                  </th>
                  <th>
                    <strong>Total items</strong>
                    <span>{orderDetails.length}</span>
                  </th>
                  <th>
                    <strong>Total Quantity</strong>
                    <span>
                      {orderDetails?.reduce((a, c) => {
                        return a + c.quantity;
                      }, 0)}
                    </span>
                  </th>
                  <th>
                    <strong>Total Order Size</strong>
                    <span>{getOrderData(orderDetails)}</span>
                  </th>
                  <th>
                    <strong>Total Amount</strong>
                    <span>{totalAmount}</span>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ChangeOrderStatus;
