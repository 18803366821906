import axios from "axios";
import { getSignedURL } from "./rest/api";
import uuid from "uuid";

// const convertFileToBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file.rawFile);

//     reader.onload = () => resolve(reader.result);
//     reader.onerror = reject;
//   });

const addUploadFeature = (requestHandler) => async (type, resource, params) => {
  if (
    (type === "CREATE" || type === "UPDATE") &&
    (resource === "brand" || resource === "organization" || resource === "product" ||  resource === "pack" )
  ) {
    if (params.data.logo) {
      const newPictures = params.data.logo;
      const filename = uuid();
      const ext = newPictures.type.split("/").pop();
      const signedURL = await getSignedURL(ext, filename);

      var options = {
        headers: {
          "Content-Type": newPictures.type,
        },
      };

      axios.put(signedURL.data, newPictures, options);

      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          logo: `https://assets.supplyspring.in/${filename}.${ext}`,
        },
      });
      //   return Promise.all(newPictures.map(convertFileToBase64))
      //     .then((base64Pictures) =>
      //       base64Pictures.map((picture64, index) => ({
      //         src: picture64,
      //         title: `${newPictures[index].title}`,
      //       }))
      //     )
      //     .then((transformedNewPictures) =>
      // requestHandler(type, resource, {
      //   ...params,
      //   data: {
      //     ...params.data,
      //     pictures: [...transformedNewPictures],
      //   },
      // })
      //     );
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadFeature;
