import { gql } from "@apollo/client";

export const USER_COUNT = gql`
  query user_aggregate {
    user_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const CATEGORY_BY_ID = gql`
  query category_by_pk($id: uuid!) {
    category_by_pk(id: $id) {
      logo
      id
      name
      order_by
      type
    }
  }
`;

export const ORGANIZATION_CODE = gql`
  query organization {
    organization {
      code
    }
  }
`;

export const ORDER_STATUS = gql`
  query order {
    order {
      id
      order_statuses(order_by: { created_at: desc }, limit: 1) {
        status
      }
    }
  }
`;

export const GET_AGREEGATE = gql`
  query product {
    product(order_by: { created_at: desc, product_code: desc }, limit: 1) {
      product_code
    }
  }
`;
export const GET_ORG_ID = gql`
  query organization {
    organization {
      org_code
      id
    }
  }
`;
export const GET_SKU_ID = gql`
query pack($org_code: String!) {
  pack(order_by: { created_at: desc, sku: desc }, limit: 1, where: {sku: {_ilike: $org_code}}) {
    sku
  }
}
`;
export const GET_SKU_ID_FROM_ORG = gql`
  query pack($org_code: String!) {
    pack(order_by: { created_at: desc, sku: desc }, limit: 1, where: {sku: {_ilike: $org_code}}) {
      sku
    }
  }
`;
export const GET_SECTION = gql`
  query section {
    section {
      name
      id
    }
  }
`;
export const GET_CLASS = gql`
  query class {
    class(order_by: { name: asc }) {
      name
      id
      discount
    }
  }
`;
export const GET_IS_CUSTOM_SKU = gql`
  query skulist {
    organization(order_by: { name: asc }) {
      name
      id
      is_custom_sku
    }
  }
`;
export const GET_PRODUCT_CODE = gql`
  query product($id: uuid) {
    product(where: { id: { _eq: $id } }) {
      product_code
    }
  }
`;
export const GET_PRODUCT_DISCOUNT = gql`
  query class($pack_id: uuid) {
    class(order_by: { name: asc }) {
      id
      name
      pack_discounts(where: { pack_id: { _eq: $pack_id } }) {
        amount
        id
      }
    }
  }
`;

export const GET_ALL_PRODUCT_DISCOUNT = gql`
  query class {
    class {
      id
      name
      pack_discounts {
        id
        amount
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query pack_by_pk($id: uuid!) {
    pack_by_pk(id: $id) {
      carton_size
      logo
      measurement_id
      pack_size
      price
      product_id
      section_id
      sku
      stock_status
      unit_price
      alias
    }
  }
`;

export const GET_ORG_ADMIN_USER = gql`
  query user($org_id: uuid) {
    user(
      where: {
        organization_users: { org_id: { _eq: $org_id } }
        role: { _eq: "orgadmin" }
      }
    ) {
      city
      name
      email
      id
      role
      phone
      pincode
      state
    }

    organization(where: { id: { _eq: $org_id } }) {
      GST
      address
      logo
      id
      name
      email
      type
    }
  }
`;

export const GET_BRAND_BY_ID = gql`
  query brand_by_pk($id: uuid!) {
    brand_by_pk(id: $id) {
      id
      logo
      name
    }
  }
`;
export const GET_PRODUCT_DETAILS_BY_ID = gql`
  query product_by_pk($id: uuid!) {
    product_by_pk(id: $id) {
      name
      brand_id
      description
      logo
      org_id
      part_code
      product_categories {
        category {
          id
          name
        }
      }
    }
  }
`;
export const GET_RETAILER_DETAILS_BY_ID = gql`
  query user_by_pk($id: uuid!) {
    user_by_pk(id: $id) {
      name
      role
      phone
      city
      state
      pincode
      class_id
      shipping_addresses {
        address
        country
      }
    }
  }
`;
export const GET_ORGANIZATION_DATA = gql`
  query organization {
    organization {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_DATA_ACTIVE = gql`
query organization {
    organization (where: {is_account_cancel: {_eq: false}}) {
      id
      name
      city
    }
  }
`;

export const GET_ORGANIZATION_DATA_BY_ID = gql`
  query organization_details(
    $org_id: uuid
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    organization_user_aggregate(
      where: {
        org_id: { _eq: $org_id }
        user: { role: { _eq: "user" }, is_deleted: { _eq: false } }
      }
    ) {
      aggregate {
        count
      }
    }
    product_aggregate(where: { org_id: { _eq: $org_id } }) {
      aggregate {
        count
      }
    }
    pack_aggregate(where: { product: { org_id: { _eq: $org_id } } }) {
      aggregate {
        count
      }
    }
    order_aggregate(
      where: {
        org_id: { _eq: $org_id }
        created_at: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      aggregate {
        count
      }
    }
    order(
      where: {
        org_id: { _eq: $org_id }
        created_at: { _gt: $startDate, _lt: $endDate }
      }
    ) {
      id
      order_statuses(order_by: { created_at: desc }, limit: 1) {
        status
      }
    }
    organization_aggregate(where: { is_active: { _eq: true } }) {
      aggregate {
        count
      }
    }
    organization(where: { id: { _eq: $org_id } }) {
      name
      users_limit
    }
  }
`;
export const GET_ORDER_DETAILS_BY_ID = gql`
  query Orders($id: uuid) {
    order(order_by: [{ updated_at: desc }], where: { id: { _eq: $id } }) {
      id
      order_id
      total_order_amount
      attachment_url
      lr_no
      is_revised
      revised_note
      transport_vehical_no
      transport_name
      instructions
      updated_at
      created_at
      order_statuses(limit: 1, order_by: [{ updated_at: desc }]) {
        id
        order_id
        reason
        status
        created_at
        updated_at
        updated_by {
          id
          name
        }
      }
      user {
        role
        phone
        name
        id
        code
        class_id
        profile_image
        email
        city
      }
      payment_type {
        type
        percentage
        title
      }
      cash_discount
      total_order_amount
      order_total_discounted_amount
      total_order_items
      transport_name
      transport_vehical_no
      updated_at
      product_orders {
        estimated_time
        id
        order_id
        product_price
        product_pack_id
        quantity
        total_amount
        total_discounted_amount
        discounted_price
        class_discount
        order {
          cash_discount
        }
        pack {
          id
          price
          pack_size
          sku
          measurement {
            id
            name
          }
          product {
            id
            created_at
            logo
            name
            org_id
            packs(order_by: [{ price: asc }]) {
              id
              pack_size
              price
              carton_size
              logo
              unit_price
              measurement {
                name
                id
              }
            }
            part_code
            brand {
              name
              logo
              id
              created_at
            }
            product_categories {
              category {
                id
                name
                type
                created_at
              }
            }
            favorite_products {
              user_id
            }
            meta_data
          }
          created_at
          updated_at
          id
          pack_size
          price
          carton_size
          logo
          unit_price
        }
      }
      shipping_address {
        address
        city
        code
        country
        id
        name
        phone
        pincode
        state
        updated_at
      }
      organization {
        logo
        name
      }
    }
  }
`;

export const GET_ORDER_QUANTITIES_FOR_DATE_RANGE = gql`
  query getOrderQuantitySummaryForDateRangeAndOrganization(
    $startDate: timestamptz
    $endDate: timestamptz
    $org_id: uuid
  ) {
    user(
      where: {
        role: { _ilike: "%user%" }
        organization_users: { org_id: { _eq: $org_id } }
      }
    ) {
      name
      orders(
        where: {
          created_at: { _gte: $startDate, _lt: $endDate }
          order_statuses: { status: { _eq: "completed" } }
          org_id: { _eq: $org_id }
        }
      ) {
        product_orders {
          pack {
            measurement {
              name
            }
            pack_size
            carton_size
          }
          quantity
        }
      }
    }
  }
`;
export const GET_ORDER_QUANTITIES_FOR_ORGANIZATION = gql`
  query getOrderQuantitySummaryForOrganization(
    $startDate: timestamptz
    $endDate: timestamptz
    $org_id: [uuid!]
  ) {
    organization(where: { id: { _in: $org_id } }) {
      name
      orders(
        where: {
          created_at: { _gt: $startDate, _lt: $endDate }
          order_statuses: { status: { _eq: "completed" } }
          org_id: { _in: $org_id }
        }
      ) {
        product_orders {
          pack {
            measurement {
              name
            }
            pack_size
            carton_size
          }
          quantity
        }
      }
    }
  }
`;

export const GET_ORDER_REVENUE_FOR_DATE_RANGE = gql`
  query getOrderRevenueSummaryForDateRangeAndOrganization(
    $startDate: timestamptz
    $endDate: timestamptz
    $org_id: uuid
  ) {
    user(
      where: {
        role: { _ilike: "%user%" }
        organization_users: { org_id: { _eq: $org_id } }
      }
    ) {
      name
      orders(
        where: {
          created_at: { _gte: $startDate, _lt: $endDate }
          order_statuses: { status: { _eq: "completed" } }
        }
      ) {
        order_total_discounted_amount
      }
    }
  }
`;

export const GET_ORDER_REVENUE_FOR_ORGANIZATION = gql`
  query getRevenueForOrganizations(
    $startDate: timestamptz
    $endDate: timestamptz
    $org_id: [uuid!]
  ) {
    organization(where: { id: { _in: $org_id } }) {
      name
      orders(
        where: {
          created_at: { _gt: $startDate, _lt: $endDate }
          order_statuses: { status: { _eq: "completed" } }
        }
      ) {
        order_total_discounted_amount
      }
    }
  }
`;

export const GET_RETAILER_REPORT = gql`
  query getRetailerWiseReport(
    $startDate: timestamptz
    $endDate: timestamptz
    $org_id: uuid
    $name: String = "%%"
  ) {
    user(
      where: {
        role: { _ilike: "%user%" }
        organization_users: { org_id: { _eq: $org_id } }
        name: { _ilike: $name }
      }
    ) {
      id
      name
      orders(where: { created_at: { _gte: $startDate, _lt: $endDate } }) {
        order_id
        order_statuses {
          status
          updated_at
        }
        order_total_discounted_amount
        product_orders {
          pack {
            pack_size
            carton_size
            measurement {
              name
            }
          }
          quantity
        }
        organization {
          id
          name
        }
      }
      organization_users {
        organization {
          name
          id
        }
      }
    }
  }
`;
export const GET_RETAILER_SUBSCRIPTION = gql`
  query GetSubscriptionDetails($org_id: uuid) {
    organization(where: { id: { _eq: $org_id } }) {
      name
      users_limit
      id
      email
      plan_type {
        plan_id
        name
        price
        description
        total_price
        limit
      }
      organization_user_aggregate(
        where: { user: { role: { _eq: "user" }, is_deleted: { _eq: false } } }
      ) {
        aggregate {
          count
        }
      }
      payment_transactions(order_by: { created_at: desc }, limit: 1) {
        id
        next_invoice_due
        created_at
        org_id
      }
      payment_dues(order_by: { due_date: desc }, limit: 1) {
        due_date
        updated_at
      }
    }
  }
`;

export const GET_PAYMENT_DUE = gql`
  query GetPaymentDueDetails($org_id: uuid, $gt: timestamp, $lt: timestamp) {
    payment_due(
      order_by: { due_date: desc, start_date: desc }
      where: { org_id: { _eq: $org_id }, created_at: { _gt: $gt, _lt: $lt } }
    ) {
      due_date
      status
      amount
      start_date
      due_id
      updated_at
      pay_id
      organization {
        name
        city
      }
      invoice_num {
        invoice_num
        is_trial
      }
    }
  }
`;

export const GET_PLANS = gql`
  query MyQuery {
    plan_type {
      id
      limit
      name
      plan_id
      price
      total_price
      category
      description
    }
  }
`;

export const GET_PAYMENT_DETAILS = gql`
  query PaymentInoviceDetails($pay_id: String) {
    payment_due(where: { pay_id: { _eq: $pay_id } }) {
      amount
      start_date
      due_date
      updated_at
      organization {
        name
        address
        email
        GST
        organization_user(where: { user: { role: { _eq: "orgadmin" } } }) {
          user {
            name
            city
            pincode
            state
          }
        }
        payment_transactions(where: { payment_id: { _eq: $pay_id } }) {
          invoice_num
          is_credited
        }
      }
      planTypeByPlanType {
        name
        description
        limit
        total_price
        price
        plan_id
      }
    }
  }
`;
export const GET_PLAN_TYPES = gql`
  query plan_type {
    plan_type(order_by: { price: asc }) {
      name
      limit
      id
      plan_id
      price
      total_price
    }
  }
`;

export const GET_PRODUCTS_LIST = gql`
  query products($org_id: uuid!) {
    product(where: { org_id: { _eq: $org_id } }, order_by: { name: asc }) {
      id
      logo
      meta_data
      type
      brand_id
      description
      name
      part_code
      product_code
    }
  }
`;

export const PRODUCT_IMPORT = gql`
  query productImportDetails {
    product {
      brand {
        name
      }
      description
      name
      logo
      part_code
      product_code
      type
      product_categories {
        category {
          name
          type
        }
      }
    }
  }
`;

export const GET_MEASUREMENT_LIST = gql`
  query measurement($org_id: uuid!) {
    measurement(where: { org_id: { _eq: $org_id } }, order_by: { name: asc }) {
      id
      name
      org_id
    }
  }
`;

export const GET_SECTION_LIST = gql`
  query section($org_id: uuid!) {
    section(where: { org_id: { _eq: $org_id } }, order_by: { name: asc }) {
      id
      name
      org_id
      order
    }
  }
`;

export const GET_CLASS_LIST = gql`
  query class($org_id: uuid!) {
    class(where: { org_id: { _eq: $org_id } }, order_by: { name: asc }) {
      id
      name
      org_id
    }
  }
`;

export const GET_CATEGORY_LIST = gql`
  query organization_category($org_id: uuid!) {
    organization_category(
      where: { org_id: { _eq: $org_id } }
      order_by: { category: { name: asc } }
    ) {
      id
      category {
        id
        name
      }
    }
  }
`;

export const GET_BRAND_LIST = gql`
  query brand($org_id: uuid!) {
    brand(where: { org_id: { _eq: $org_id } }, order_by: { name: asc }) {
      id
      name
      org_id
    }
  }
`;

export const GET_RETAILER_DETAILS_BY_PHONE = gql`
  query user($phone: numeric!) {
    user(where: { phone: { _eq: $phone } }) {
      id
    }
  }
`;
export const PACK_IMPORT = gql`
query packImportDetails {
  pack(order_by: {sku: asc}) {
    id
    logo
    product {
      name
    }
    pack_size
    carton_size
    unit_price
    sku
    alias
    section {
      name
    }
    pack_discounts(order_by: {class: {name: asc}}) {
      amount
      class {
        discount
        name
      }
    }
    stock_status
    measurement_id
    price
    measurement {
      name
    }
  }
}
`;

export const CHECK_PACK_EXIST = gql`
  query pack_aggregate(
    $product_id: uuid!
    $pack_size: String!
    $measurement_id: uuid!
  ) {
    pack_aggregate(
      where: {
        measurement_id: { _eq: $measurement_id }
        pack_size: { _eq: $pack_size }
        product_id: { _eq: $product_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CHECK_ALIAS_EXIST = gql`
query pack_aggregate($alias: String!) {
  pack_aggregate(where: {alias: {_eq: $alias}}) {
    aggregate {
      count
    }
  }
}
`;

export const GET_PACK_LIST = gql`
  query pack {
    pack {
      id
      measurement_id
      pack_size
      product_id
      sku
      alias
    }
  }
`;
export const GET_TOTAL_ORDER_LIST = gql`
  query totalOrder(
    $org_id: [uuid!]
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    order(
      where: {
        org_id: { _in: $org_id }
        created_at: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      attachment_url
      cash_discount
      created_at
      updated_at
      id
      instructions
      is_revised
      lr_no
      order_id
      order_statuses {
        created_at
        status
      }
      order_total_discounted_amount
      order_updated_by
      ordered_by
      org_id
      payment_type_id
      revised_note
      shipping_address_id
      shipping_date
      total_order_amount
      total_order_items
      totalamount_obsolate
      transport_name
      transport_vehical_no
    }
  }
`;

export const GET_PRIMARY_ORG_DATA = gql`
query MyQuery {
  brand(where: {organization: {is_primary_import: {_eq: true}}}) {
    logo
    name
    products {
      description
      logo
      name
      part_code
      product_code
      type
      packs {
        carton_size
        logo
        price
        pack_size
        sku
        stock_status
        unit_price
        measurement {
          name
        }
        section {
          name
        }
        pack_discounts {
          class_id
          amount
          class {
            name
            discount
          }
        }
      }
    }
  }
}
`;

export const GET_PRIMARY_ORG_SECTION_DATA = gql`
query MyQuery {
  section(where: {organization: {is_primary_import: {_eq: true}}}) {
    name
    id
    is_display
    order
  }
}
`;

export const GET_PRIMARY_MEASUREMENT_DATA = gql`
query MyQuery {
  measurement(where: {organization: {is_primary_import: {_eq: true}}}) {
    name
    id
  }
}
`;

export const GET_PRIMARY_ORG_CATEGORY_DATA = gql`
query MyQuery {
  organization_category(where: {organization: {is_primary_import: {_eq: true}}}) {
    category {
      name
      logo
      order_by
      type
      id
    }
  }
}
`;

export const GET_ORG_MEASUREMENT_DATA = gql`
query MyQuery($org_id: uuid!) {
  measurement(where: {org_id: {_eq: $org_id}}) {
    name
    id
  }
}

`;

export const GET_ORG_SECTION_DATA = gql`
query MyQuery($org_id: uuid!) {
  section(where: {org_id: {_eq: $org_id}}) {
    name
    id
    is_display
    order
  }
}
`;

export const GET_PACK_COUNT= gql`
query MyQuery($org_id: uuid!) {
  pack_aggregate(where: {product: {org_id: {_eq: $org_id}}}) {
    aggregate {
      count
    }
  }
}
`;

export const GET_PRODUCT_COUNT = gql`
query MyQuery($org_id: uuid!) {
  product_aggregate(where: {org_id: {_eq: $org_id}}) {
    aggregate {
      count
    }
  }
}
`;

export const GET_ORG_DISCOUNT_DATA = gql`
query MyQuery($org_id: uuid!) {
  class(where: {org_id: {_eq: $org_id}}) {
    name
    id
  }
}
`;

export const GET_ORIGINAL_PAYMENT_PERIOD = gql`
query MyQuery($org_id: uuid!) {
  organization(where: {is_account_cancel: {_eq: false}, id: {_eq: $org_id}}) {
    name
    users_limit
    id
    email
    payment_transactions(order_by: {created_at: desc}, limit: 1) {
      next_invoice_due
      created_at
      org_id
      amount
      method
    }
  }
}`;

export const PAYMENTTYPE_BY_ID = gql`
query PAYMENTTYPE_BY_ID($id: uuid!) {
  payment_type_by_pk(id: $id) {
    description
    is_default
    percentage
    title
    type
  }
}
`;

export const GET_PAYMENT_TYPE = gql`
query MyQuery {
  payment_type(distinct_on: title) {
    description
    discount
    id
    is_default
    title
    type
  }
}
`;

export const GET_PRODUCT_ORDER = gql`
query MyQuery {
  product_order {
    order_id
    order {
      order_id
    }
    pack {
      sku
    }
    class_discount
    discounted_price
    estimated_time
    id
    product_pack_id
    product_price
    quantity
    total_amount
    total_discounted_amount
  }
}

`;

export const IS_CATEGORY_EXIST = gql`
query MyQuery($name: String, $order_by: numeric) {
  category(where: {name: {_eq: $name}, order_by: {_eq: $order_by}}) {
    id
  }
}
`;

export const GET_STATE_LIST = gql`
  query get_state {
    State {
      id
      name
    }
  }
`;

export const GET_ORGADMIN_STATE = gql`
query getStateList {
  organization_user(where: {user: {role: {_eq: "orgadmin"}}}) {
    user {
      state
      id
      name
    }
    organization {
      id
      name
    }
  }
}

`;

export const GET_ORGANIZATIONS_DATA_BY_ID = gql`
  query organization_details(
    $org_id: [uuid!]
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    organization_user_aggregate(
      where: {
        org_id: { _in: $org_id }
        user: { role: { _eq: "user" }, is_deleted: { _eq: false } }
      }
    ) {
      aggregate {
        count
      }
    }
    product_aggregate(where: { org_id: { _in: $org_id } }) {
      aggregate {
        count
      }
    }
    pack_aggregate(where: { product: { org_id: { _in: $org_id } } }) {
      aggregate {
        count
      }
    }
    order_aggregate(
      where: {
        org_id: { _in: $org_id }
        created_at: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      aggregate {
        count
      }
    }
    order(
      where: {
        org_id: { _in: $org_id }
        created_at: { _gt: $startDate, _lt: $endDate }
      }
    ) {
      id
      order_statuses(order_by: { created_at: desc }, limit: 1) {
        status
      }
    }
    organization_aggregate(where: { is_active: { _eq: true }, id: {_in: $org_id} }) {
      aggregate {
        count
      }
    }
    organization(where: { id: { _in: $org_id } }) {
      name
      users_limit
    }
  }
`;

export const GET_LATEST_PRODUCT_CODE = gql`
query pack($product_code: String!) {
  user(order_by: {created_at: desc, product_code: desc}, limit: 1, where: {product_code: {_ilike: $product_code}, role: {_eq: "user"}}) {
    product_code
  }
}
`;