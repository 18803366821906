import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Box,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import React, { useState } from "react";
import { useNotify, useRedirect } from "react-admin";
import {
  CANCEL_PLAN,
  CAPTURE_UPGRADE_PAYMENT,
  CREATE_UPGRADE_ORDER,
  UPDATE_ORG_TYPE,
  VERIFY_PAYMENT,
} from "./GraphQL/mutation";
import { GET_PLAN_TYPES } from "./GraphQL/queries";

export const UpgradePlan = ({
  plantypeId,
  planType,
  planDetail,
  orgid,
  pending,
  subData,
  userDetail,
  usersLimit,
  issixmonth,
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [INSERT_UPGRADE_ORDER] = useMutation(CREATE_UPGRADE_ORDER);
  const [newPlan, setNewPlan] = useState(plantypeId);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [cancelPlanModal, setCancelPlanModal] = useState(false);
  const [PAYMENT_UPGRADE_CAPTURE] = useMutation(CAPTURE_UPGRADE_PAYMENT);
  const [DOWNGRADE_PLAN] = useMutation(UPDATE_ORG_TYPE);
  const [CHECK_PAYMENT_STATUS] = useMutation(VERIFY_PAYMENT);
  const [PLAN_REMOVE] = useMutation(CANCEL_PLAN);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [remaingAmount, setRemainAmount] = React.useState(null);
  const [remainingDays, setRemainDays] = React.useState(null);
  const [otherText, setOtherText] = React.useState(null);
  const [isDownGrade, setDownGrade] = React.useState(false);
  const { data: planData, loading: planDataLoading } = useQuery(
    GET_PLAN_TYPES,
    { fetchPolicy: "network-only" }
  );
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handlePlanChange = async () => {
    setChangePlanModal(false);
    const plans = planData.plan_type.findIndex((v) => v.plan_id === newPlan);
    const plan = planData.plan_type[plans];
    if (pending >= 0) {
      alert("Please complete existing payment transaction!");
      return;
    }
    const date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    const endDate = issixmonth
      ? new Date(
          subData?.organization[0]?.payment_transactions[0]?.next_invoice_due
        )
      : new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const newPlanId = planDetail?.plan_id;
    const oldAmount = parseInt(planDetail?.total_price, 0);
    const newAmount = plan.total_price;
    const remainingAmount = issixmonth
      ? (newAmount - oldAmount) * 100
      : ((newAmount - oldAmount) / 30) * days * 100;

    var subEndDate = moment(endDate).format("MM/DD/YYYY");
    // alert(remainingAmount);
    if (plan.limit < planDetail?.limit) {
      // const isSuccess = await DOWNGRADE_PLAN({
      //   variables: {
      //     org_id: subData?.organization[0]?.id,
      //     plan_type: plan.id,
      //     limit: plan.limit,
      //   },
      // });
      // if (!isSuccess.errors) {
      //   alert(
      //     "Your Plan has been changed and will be effective next month onwards!"
      //   );
      //   window.location.reload();
      // }
    } else if (plan.limit === planDetail?.limit) {
      alert("No Plan has changed!");
    } else {
      displayRazorpayForUpgrade(
        plan.plan_id,
        remainingAmount,
        subEndDate,
        plan.limit,
        plan.id
      );
    }
  };

  React.useEffect(() => {}, [otherText, remaingAmount, remainingDays]);

  const planValueChange = async (e) => {
    setNewPlan(e.target.value.trim());
    setOtherText(null);
    setRemainAmount(null);
    setRemainDays(null);
    const plans = planData.plan_type.findIndex(
      (v) => v.plan_id === e.target.value.trim()
    );
    const plan = planData.plan_type[plans];
    if (pending >= 0) {
      setOtherText("Please complete existing payment transaction!");
      return;
    }

    if (plan.limit < planDetail?.limit) {
      setDownGrade(true);
      return;
    }
    const date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    setRemainDays(days);
    const endDate = issixmonth
      ? new Date(
          subData?.organization[0]?.payment_transactions[0]?.next_invoice_due
        )
      : new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var subEndDate = moment(endDate).format("MM/DD/YYYY");
    const newPlanId = planDetail?.plan_id;
    const oldAmount = parseInt(planDetail?.total_price, 0);
    const newAmount = plan.total_price;
    const remainingAmount = issixmonth
      ? (newAmount - oldAmount) * 100
      : ((newAmount - oldAmount) / 30) * days * 100;
    setRemainAmount(remainingAmount);
    setOtherText("* Applicable immediately");
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorpayForUpgrade = async (
    planid,
    amount,
    endDate,
    limit,
    typeid
  ) => {
    if (subData?.organization[0]?.plan_type) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        setLoading(false);
        return;
      }

      // creating a new subscription
      const result = await INSERT_UPGRADE_ORDER({
        variables: {
          plan_id: planid,
          amount: amount.toString(),
        },
      });

      if (!result) {
        alert("Server error. Are you online?");
        setLoading(false);
        return;
      }

      setLoading(false);
      // Getting the order details back
      if (!result.errors) {
        let options = {};
        const { id } = result.data.createUpgradeOrder.returning;
        options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          order_id: id,
          name: subData?.organization[0]?.name,
          description: subData?.organization[0]?.plan_type?.plan_description,
          // customer_id: "cust_IPjGXtzFyUYkLD",
          prefill: {
            name: userDetail.name,
            email: userDetail.email,
            contact: userDetail.phone,
          },
          notes: {
            org_name: subData?.organization[0]?.name,
            plan: planid,
            email: subData?.organization[0]?.email,
          },
          save: 1,
          color: "#FF3834",
          handler: async function (response) {
            setLoading(true);
            const isSuccess = await CHECK_PAYMENT_STATUS({
              variables: {
                verify_id: id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            });
            if (
              !isSuccess.errors &&
              isSuccess.data.verify_payment.status === "OK"
            ) {
              const isInsertSuccess = await PAYMENT_UPGRADE_CAPTURE({
                variables: {
                  payment_id: response.razorpay_payment_id,
                  org_id: subData?.organization[0]?.id,
                  payment_end: endDate,
                  plan_type: typeid,
                  user_limit: limit.toString(),
                },
              });
              if (
                !isInsertSuccess.errors &&
                isInsertSuccess.data.captureUpgradePayment.status === "OK"
              ) {
                setLoading(false);
                // setShowSuccessModal(true);
                setOpen(true);
              } else {
                setLoading(false);
                alert(
                  "Some issue occured while adding capturing payment, Please contact administrator!"
                );
              }
            } else {
              setLoading(false);
              alert("Payment verification failed!");
            }
          },
        };
        // }
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          alert("Payment Failed!!");
        });
        paymentObject.open();
      } else {
        alert(
          "Some issue occured while creating order for the organization, Please contact support!"
        );
      }
    } else {
      alert("User is not subscribed with supply spring!");
    }
  };

  const handleCancelPlan = () => {
    const isSuccess = PLAN_REMOVE({
      variables: {
        org_id: subData?.organization[0]?.id,
      },
    });
    if (!isSuccess.errors) {
      alert("Your subscription is cancelled");
      localStorage.removeItem("ssp_admin_access_token");
      localStorage.removeItem("ssp_admin_role");
      localStorage.clear();
      redirect("/category");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    border: "0",
    borderRadius: 0,
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Stack spacing={2} direction="row">
        <Button
          onClick={() => setChangePlanModal(true)}
          variant="contained"
          color="primary"
          disabled
        >
          Upgrade Plan
        </Button>
      </Stack>
      <Modal
        open={changePlanModal}
        onClose={() => setChangePlanModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="upgradeModal"
      >
        <Box sx={style}>
          <div className="maintitle">Upgrade Plan</div>
          <div className="closePopup">
            <CancelIcon
              className=""
              onClick={() => setChangePlanModal(false)}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="popupBody">
              <Grid container spacing={2}>
                {!planDataLoading &&
                  planData &&
                  planData.plan_type.map((plan, index) => (
                    <Grid key={index} item md={3}>
                      <div className="pricingbox">
                        <div className="priceTitle">{plan?.name}</div>
                        <div className="priceBody">
                          <div className="pricetxt">
                            INR {plan?.price}/month
                          </div>
                          <ul>
                            <li>Less than {plan?.limit} retailers.</li>
                            <li>Unlimited Products & Packs.</li>
                            <li>
                              Let your customers connect with you through the
                              App & Website both.
                            </li>
                            <li>
                              Complimentary App Maintenance and OTP charges.
                            </li>
                          </ul>
                          <div className="priceButton">
                            <RadioGroup
                              row
                              aria-label="gender"
                              name="row-radio-buttons-group"
                              onChange={(e) => planValueChange(e)}
                              defaultValue={plantypeId}
                              value={newPlan}
                            >
                              <FormControlLabel
                                key={index}
                                value={plan.plan_id}
                                control={<Radio />}
                                label={
                                  plantypeId === plan.plan_id
                                    ? "Current Plan"
                                    : plan.limit <= usersLimit
                                    ? `Unavailable (You have more than ${plan.limit} retailes)`
                                    : planDetail?.total_price >=
                                      plan?.total_price
                                    ? "Not Available"
                                    : "Select plan"
                                }
                                disabled={
                                  plantypeId === plan.plan_id ||
                                  plan.limit <= usersLimit ||
                                  planDetail?.total_price >= plan?.total_price
                                }
                              />
                            </RadioGroup>
                          </div>
                          {plan.plan_id === newPlan ? (
                            <div className="payinfo">
                              {remaingAmount ? (
                                <div>
                                  <span>
                                    You will need to pay ₹{" "}
                                    {(remaingAmount / 100).toLocaleString()}
                                    {issixmonth
                                      ? ""
                                      : ` for ${" "} ${remainingDays} days`}
                                  </span>
                                  <br></br>
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* {remainingDays ?  <span>{remainingDays}</span> : <></>} */}
                              {otherText ? <span>{otherText}</span> : <></>}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mb: 2 }}
          >
            <Button
              onClick={handlePlanChange}
              color="primary"
              variant="contained"
              disabled={!newPlan}
            >
              Change Plan
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Payment Successful"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-center">
              <b>Thank you</b>
            </p>
            your payment was successful. Check your email for your receipt.
            <br></br>
            <br></br>
            <b style={{ paddingRight: "25px" }}>Time</b>
            {moment().format("MMM DD, YYYY hh:mm A")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
